
import { Component, Vue } from "vue-property-decorator";

import signsRoutes from "@/api/routes/signs";
import skillRoutes from "@/api/routes/skills";
import pillarRoutes from "@/api/routes/pillars";
import blockRoutes from "@/api/routes/blocks";

import Preloader from "@/components/Preloader.vue";
import Search from "@/components/Search.vue";

import SkillSign, { ISignSkill } from "@/models/sign/sign";
import SignSign from "@/models/sign/sign";
import SkillCategory from "@/models/skill/category";
import PillarPillar from "@/models/pillar/pillar";
import BlockBlock from "@/models/block/block";
import {
  compulsoryTabs,
  blocksTabs,
  pillarsTabs,
  ICompulsoryTabs
} from "@/models/tabs";
import CInfiniteScroll from "../components/InfiniteScroll.vue";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
}

interface IDataSigns extends IData {
  readonly signs: SignSign[];
}

interface IDataBlock {
  readonly blocks: BlockBlock[];
}

@Component({
  components: {
    Preloader,
    Search,
    CInfiniteScroll
  },
  data: () => {
    return {
      compulsoryTabs,
      blocksTabs,
      pillarsTabs
    };
  }
})
export default class SkillSigns extends Vue {
  private preload: boolean = false;
  private showFilter: boolean = false;

  private currentUser: IJWTUser | null = this.$store.state.currentUser;

  private categoriesFilter: SkillCategory[] = [{ id: 0, value: "Все" }];
  private currentCategoryFilter?: SkillCategory = this.categoriesFilter[0];

  private pillarsFilter = pillarsTabs;
  private currentPillarFilter?: PillarPillar = this.pillarsFilter[0];

  private blocksFilter = blocksTabs;
  private currentBlockFilter?: BlockBlock = this.blocksFilter[0];

  private compulsoryFilter = compulsoryTabs;
  private currentCompulsoryFilter?: ICompulsoryTabs = this.compulsoryFilter[0];

  private filters: Map<string, boolean> = new Map();

  private skills: ISignSkill[] = [];

  private signs: SignSign[] = [];
  private currentSign: SkillSign = new SkillSign();
  private currentSignIndex?: number;

  private per_page: number = 100;
  private current_page: number = 0;
  private next_page: boolean = false;

  private skills_per_page: number = 100;
  private skills_current_page: number = 0;
  private skills_next_page: boolean = false;
  private skills_infinite_preload: boolean = false;

  private signError: string = "";

  private search: string = this.$store.state.search;

  private watchers: Function[] = [];

  private created() {
    this.preload = true;

    Promise.all([
      this.loadSigns(),
      this.loadSkills(),
      this.loadSkillCategories(),
      this.loadPillars(),
      this.loadBlocks()
    ]).then(() => {
      this.preload = false;
    });

    this.watchers.push(
      this.$store.watch(
        state => state.search,
        search => {
          this.search = search;
          this.clearSkills();
        }
      )
    );
  }

  private loadSkills(page: number = 0) {
    this.skills_infinite_preload = true;
    this.$api
      .get(signsRoutes.skills, {
        params: {
          category_id: this.currentCategoryFilter?.id,
          pillar_id: this.currentPillarFilter?.id,
          block_id: this.currentBlockFilter?.id,
          search: this.search,
          is_compulsory_education: this.currentCompulsoryFilter?.value,
          page,
          per_page: this.skills_per_page,
          paginate: true
        }
      })
      .then(
        ({
          data: res
        }: {
          data: {
            current_page: number;
            next_page: boolean;
            skills: ISignSkill[];
          };
        }) => {
          this.skills.push(...res.skills);
          this.skills_current_page = res.current_page;
          this.skills_next_page = res.next_page;
        }
      )
      .finally(() => {
        this.skills_infinite_preload = false;
      });
  }

  private loadSkillCategories() {
    this.$api
      .get(skillRoutes.categories)
      .then(({ data: res }: { data: SkillCategory[] }) => {
        this.categoriesFilter.push(...res);
      });
  }

  private loadPillars() {
    this.$api
      .get(pillarRoutes.pillars)
      .then(({ data: res }: { data: PillarPillar[] }) => {
        this.pillarsFilter.push(...res);
      });
  }

  private loadBlocks() {
    this.$api
      .get(blockRoutes.blocks)
      .then(({ data: res }: { data: IDataBlock }) => {
        this.blocksFilter.push(...res.blocks);
      });
  }

  private loadSigns(page: number = 0) {
    this.$api
      .get(signsRoutes.signs, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true
        }
      })
      .then(({ data: res }: { data: IDataSigns }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;
        this.signs.push(...res.signs);
      });
  }

  private clearSkills() {
    this.skills = [];
    this.loadSkills();
  }

  private clearErrors() {
    this.signError = "";
  }

  private clearSigns() {
    this.signs = [];
    this.loadSigns();
  }

  private clearFilters() {
    this.currentBlockFilter = this.blocksFilter[0];
    this.currentPillarFilter = this.pillarsFilter[0];
    this.currentCategoryFilter = this.categoriesFilter[0];
    this.currentCompulsoryFilter = this.compulsoryFilter[0];
    this.filters.clear();
    this.clearSkills();
  }

  private infiniteHandler() {
    if (this.next_page) {
      this.loadSigns(this.current_page + 1);
    }
  }

  private skillsInfiniteHandler() {
    if (this.skills_next_page && !this.skills_infinite_preload) {
      this.loadSkills(this.skills_current_page + 1);
    }
  }

  private changeSearch() {
    this.clearSkills();
  }

  private changeCategory() {
    if (this.categoriesFilter[0] !== this.currentCategoryFilter) {
      this.filters.set("category", true);
    } else {
      this.filters.delete("category");
    }

    this.clearSkills();
  }

  private changePillar() {
    if (this.pillarsFilter[0] !== this.currentPillarFilter) {
      this.filters.set("pillar", true);
    } else {
      this.filters.delete("pillar");
    }

    this.clearSkills();
  }

  private changeBlock() {
    if (this.blocksFilter[0] !== this.currentBlockFilter) {
      this.filters.set("block", true);
    } else {
      this.filters.delete("block");
    }

    this.clearSkills();
  }

  private changeCompulsory() {
    if (this.compulsoryFilter[0] !== this.currentCompulsoryFilter) {
      this.filters.set("compulsory", true);
    } else {
      this.filters.delete("compulsory");
    }

    this.clearSkills();
  }

  private showEditSign(index: number = -1) {
    const sign = this.signs[index];
    if (sign?.is_local || index === -1) {
      this.currentSignIndex = index;

      if (sign) {
        this.currentSign = sign;
      } else {
        this.currentSign = new SignSign();
      }

      this.$modal.show("editSign");
    }
  }

  private showSignSkills(index: number) {
    const sign = this.signs[index];
    this.$set(sign, "show_skills", !sign.show_skills);
  }

  private hideEditSign() {
    this.currentSign = new SignSign();
    this.$modal.hide("editSign");
  }

  private createSign() {
    this.currentSign.full_name = this.currentSign.full_name?.trim();
    this.currentSign.short_name = this.currentSign.full_name?.trim();

    if (!this.currentSign.full_name?.length) {
      this.signError = "Заполните имя";
      return;
    }

    if (!this.currentSign.short_name?.length) {
      this.signError = "Добавьте сокращение";
      return;
    }

    this.$api
      .post(signsRoutes.signs, {
        sign: this.currentSign,
        skills: this.currentSign.skills
      })
      .then(({ data: res }: { data: SignSign }) => {
        this.hideEditSign();
        this.clearSigns();
      })
      .catch(({ response: res }) => {
        this.signError = res.data.error;
      });
  }

  private updateSign() {
    this.currentSign.full_name = this.currentSign.full_name?.trim();
    this.currentSign.short_name = this.currentSign.full_name?.trim();

    if (!this.currentSign.full_name?.length) {
      this.signError = "Заполните имя";
      return;
    }

    if (!this.currentSign.short_name?.length) {
      this.signError = "Добавьте сокращение";
      return;
    }

    this.$api
      .put(signsRoutes.sign(this.currentSign.id), this.currentSign)
      .then(({ data: res }: { data: SignSign }) => {
        this.hideEditSign();
        this.$set(this.signs, this.currentSignIndex!, res);
      })
      .catch(({ response: res }) => {
        this.signError = res.data.error;
      });
  }

  private deleteSign(index: number) {
    const sign = this.signs[index];
    this.$api
      .destroy(signsRoutes.sign(sign.id))
      .then(() => {
        this.$delete(this.signs, index);
        this.$modal.hide("deleteSignModal");
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
    this.$modal.hide("editSign");
  }

  private beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }
}
