
import { Component, Vue } from "vue-property-decorator";

import Preloader from "@/components/Preloader.vue";
import Search from "@/components/Search.vue";

import CTestingsFilters, { IFilters } from "./components/Filters.vue";
import CTestingsTree from "./components/TestingsTree.vue";
import CTestingsList from "./components/TestingsList.vue";
import CTestingsFolderCreate from "./components/FolderCreate.vue";
import CTestingsFolderUpdate from "./components/FolderUpdate.vue";
import CTestingsFolderDelete from "./components/FolderDelete.vue";
import CTestingsChange from "./components/TestingChange.vue";
import CTestingsMovingFolder from "./components/MovingFolder.vue";
import CTestingsMovingTesting from "./components/MovingTesting.vue";
import CTestingsChangeArchiveTesting from "./components/ChangeArchiveTesting.vue";

@Component({
  name: "VTestings",
  components: {
    Preloader,
    Search,
    CTestingsFilters,
    CTestingsTree,
    CTestingsList,
    CTestingsFolderCreate,
    CTestingsFolderUpdate,
    CTestingsFolderDelete,
    CTestingsChange,
    CTestingsMovingFolder,
    CTestingsMovingTesting,
    CTestingsChangeArchiveTesting
  }
})
export default class VTestings extends Vue {
  public preload: boolean = false;
  public list_view: boolean = false;

  public filters: IFilters = {
    status: undefined,
    repeat: undefined,
    is_archived: false
  };

  private search: string = this.$store.state.search;
  public selected_testing_ids_size: number = this.$store.state
    .selectedTestingIdsSize;

  private watchers: Function[] = [];

  protected beforeDestroy() {
    this.$store.commit("clearSelectedTestingIds");

    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected created() {
    this.watchers.push(
      this.$store.watch(
        state => state.search,
        search => {
          this.search = search;

          if (this.search?.length) {
            this.list_view = true;
          } else {
            this.list_view = this.checkFilters();
          }
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.selectedTestingIdsSize,
        (selectedTestingIdsSize: number) => {
          this.selected_testing_ids_size = selectedTestingIdsSize;
        }
      )
    );
  }

  public applyFilter(filters: IFilters) {
    this.filters = filters;

    this.list_view = this.checkFilters() || !!this.search?.length;
  }

  private checkFilters() {
    let exist_enable_filter = false;

    Object.entries(this.filters).forEach(([key, value]) => {
      if (key === "is_archived") {
        if (value) {
          exist_enable_filter = value;
        }
      } else {
        if (value != null) {
          exist_enable_filter = true;
        }
      }
    });

    return exist_enable_filter;
  }

  public movingTestings() {
    this.$store.commit("movingTestings", true);
  }

  public allowWrite() {
    return this.$api.allowWrite();
  }
}
