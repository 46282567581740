
import { Component, Prop, Vue } from "vue-property-decorator";

import subscription_routes from "@/api/routes/subscriptions";

import Preloader from "@/components/Preloader.vue";
import CConfirmationEducation from "@/views/constructor/programs/components/CConfirmationEducation.vue";
import CDelegateConfirmationEducation from "@/views/constructor/programs/components/DelegateConfirmationEducation.vue";

interface IConfirmations {
  subscription_program_id: string;
  program_id: string;
  program_version_title: string;
  skill_id: string;
  skill_full_name: string;
  pillar_full_name?: string;
  pillar_short_name?: string;
  user_id: string;
  user_full_name: string;
  confirmation_created_at: string;
  level: number;

  current_user_inspector_id?: string;
  current_user_full_name?: string;

  inspectors_full_name: string[];
}

@Component({
  components: {
    Preloader,
    CConfirmationEducation,
    CDelegateConfirmationEducation
  }
})
export default class CTeamSkillConfirmations extends Vue {
  @Prop({ required: true }) private user_id!: string;

  public preload: boolean = false;
  public confirmations: IConfirmations[] = [];

  public edit_confirmation: IConfirmations | null = null;
  public edit_confirmation_idx: number | null = null;

  protected async created() {
    this.preload = true;

    await Promise.all([this.loadConfirmations()]);

    this.preload = false;
  }

  private async loadConfirmations() {
    return this.$api
      .get(subscription_routes.confirmations(this.user_id!))
      .then(({ data: res }: { data: IConfirmations[] }) => {
        this.confirmations = res;
      });
  }

  public editConfirmation(confirmation: IConfirmations, idx: number) {
    if (confirmation.current_user_inspector_id) {
      this.edit_confirmation = confirmation;
      this.edit_confirmation_idx = idx;

      this.showModal();
    }
  }

  public confirmed() {
    this.$delete(this.confirmations, this.edit_confirmation_idx!);

    this.hideDelegationModal();

    this.$notify({
      group: "notifications",
      type: "success",
      text: "Изменения сохранены",
      speed: 500
    });
  }

  public delegated() {
    this.hideDelegationModal();

    this.$notify({
      group: "notifications",
      type: "success",
      text: "Изменения сохранены",
      speed: 500
    });
  }

  public showModal() {
    this.$modal.show("confirmation_modal");
  }

  public hideModal() {
    this.$modal.hide("confirmation_modal");

    this.edit_confirmation = null;
    this.edit_confirmation_idx = null;
  }

  public showDelegationModal() {
    this.$modal.show("confirmation_delegation_modal");
  }

  public hideDelegationModal() {
    this.$modal.hide("confirmation_delegation_modal");

    this.hideModal();
  }

  public inspectorsText(confirmation: IConfirmations) {
    let text = "";

    if (confirmation.current_user_full_name) {
      text += confirmation.current_user_full_name;
    } else {
      text += `${confirmation.inspectors_full_name[0]}`;
    }

    if (confirmation.inspectors_full_name.length > 1) {
      text += ` и еще ${confirmation.inspectors_full_name.length - 1}`;
    }

    return text;
  }
}
