
import { Component, Vue } from "vue-property-decorator";

import CWindRoseChart from "@/components/WindRoseChart.vue";

import analyticsRoutes from "@/api/routes/analytics";
import { analytics_leader_routes } from "@/router/analytics";

import { IChartData, ISkillData } from "@/models/analytics/chart";

interface ILevelChartData {
  ids: string[];
  labels: string[];
  percent_array: number[];
}

@Component({
  components: {
    CWindRoseChart
  }
})
export default class VSkillLevelChart extends Vue {
  protected links = analytics_leader_routes.map(r => {
    return {
      title: r.meta!.name,
      to: {
        name: r.name
      }
    };
  });

  protected position_skill_level_percent: ILevelChartData | null = null;

  protected current_position_skill_level: IChartData | null = null;
  protected current_position_skill_level_table: ISkillData | null = null;

  protected position_skill_level_label: string = "";

  protected async created() {
    return this.loadSkillLevelAvg();
  }

  protected async setPositionChart(index: number) {
    const position_id = this.position_skill_level_percent!.ids![index]!;

    this.position_skill_level_label = this.position_skill_level_percent!.labels![
      index
    ]!;

    await this.loadCurrentPositionSkill(position_id);
  }

  protected async loadSkillLevelAvg() {
    return this.$api
      .get(analyticsRoutes.skill_level_percent)
      .then(({ data: res }: { data: ILevelChartData }) => {
        this.position_skill_level_percent = res;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async loadCurrentPositionSkill(position_level_id: string) {
    return this.$api
      .get(analyticsRoutes.current_position_level_skills, {
        params: { position_level_id: position_level_id }
      })
      .then(
        ({ data: res }: { data: { chart: IChartData; table: ISkillData } }) => {
          this.current_position_skill_level = res.chart;
          this.current_position_skill_level_table = res.table;
        }
      )
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }
}
