
import { Component, Vue } from "vue-property-decorator";

import Preloader from "@/components/Preloader.vue";
import Search from "@/components/Search.vue";
import CFactoryConstructor from "@/views/constructor/programs/index/Factory.vue";
import CPaginatedSelect from "@/components/Select.vue";
import CConstructorProgramsIndexContent from "@/views/constructor/programs/index/Content.vue";

import structureRoutes from "@/api/routes/structures";
import StructureFactory from "@/models/structure/factory";
import BlockBlock from "@/models/block/block";
import SkillCategory from "@/models/skill/category";
import blockRoutes from "@/api/routes/blocks";
import skillRoutes from "@/api/routes/skills";
import SkillLocal from "@/models/skill/local";
import { IRouteMeta } from "@/router/interface";

interface ISkillFilterData {
  readonly id?: string;
  readonly full_name?: string;
}

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
}

interface ISkillData extends IData {
  readonly skills: SkillLocal[];
}

@Component({
  name: "VConstructorProgramsIndex",
  components: {
    Search,
    Preloader,
    CConstructorProgramsIndexContent,
    CFactoryConstructor,
    CPaginatedSelect
  }
})
export default class VConstructorProgramsIndex extends Vue {
  $refs!: {
    tabs: HTMLFormElement;
  };

  public preload: boolean = false;
  public show_methodological: boolean = true;

  private factory_program_id: string = this.$store.state.factoryProgramId;

  public factories: StructureFactory[] = [];
  public current_factory: StructureFactory | null = null;

  public filter_blocks: BlockBlock[] = [{ id: "", full_name: "Все" }];
  public current_block: BlockBlock = this.filter_blocks[0];

  public filter_categories: SkillCategory[] = [{ value: "Все" }];
  public current_category: SkillCategory = this.filter_categories[0];

  public current_skill?: ISkillFilterData = {};
  protected reloadSkill?: Function;

  protected per_page: number = 100;
  protected current_page: number = 0;
  protected next_page: boolean = false;

  public filters: Map<string, boolean> = new Map();
  public show_filter: boolean = false;
  public filters_count: number = 0;

  protected watchers: Function[] = [];

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async created() {
    this.preload = true;

    this.watchers.push(
      this.$store.watch(
        state => state.factoryProgramId,
        factoryProgramId => {
          this.factory_program_id = factoryProgramId;

          if (this.factories?.length) {
            const target_factory = this.factories.find(
              i => i.id === this.factory_program_id
            );

            if (target_factory) {
              this.current_factory = { ...target_factory };
            }
          }
        }
      )
    );

    const promises = [this.loadBlocks(), this.loadCategories()];

    if (this.$api.allowOnlyUserWorkplaces()) {
      promises.push(this.loadFactory());
    } else {
      promises.push(this.loadFactories());
    }

    await Promise.all(promises);

    if (this.factory_program_id && this.factories?.length) {
      const target_factory = this.factories.find(
        i => i.id === this.factory_program_id
      );

      if (target_factory) {
        this.current_factory = { ...target_factory };
      }
    }

    this.preload = false;
  }

  private async loadFactory() {
    return this.$api
      .get(structureRoutes.me_factory)
      .then(({ data: res }: { data: StructureFactory }) => {
        this.factories = [res];

        this.current_factory = { ...this.factories[0] };
      });
  }

  protected async loadFactories() {
    return this.$api
      .get(structureRoutes.factories, {
        params: {
          only_my: true,
          section_id: (this.$router.currentRoute.meta! as IRouteMeta)
            .permissions?.[0]
        }
      })
      .then(({ data: res }: { data: StructureFactory[] }) => {
        this.factories = res;

        if (this.factories.length) {
          if (this.$route.params.factory) {
            this.current_factory = {
              ...(this.factories.find(
                factory => factory.id === this.$route.params.factory
              ) || this.factories[0])
            };
          } else {
            this.current_factory = { ...this.factories[0] };
          }
        }
      });
  }

  protected async loadBlocks() {
    return this.$api
      .get(blockRoutes.blocks)
      .then(({ data: res }: { data: { blocks: BlockBlock[] } }) => {
        this.filter_blocks.push(...res.blocks);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async loadCategories() {
    return this.$api
      .get(skillRoutes.categories)
      .then(({ data: res }: { data: SkillCategory[] }) => {
        this.filter_categories.push(...res.slice(1, res.length));
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public async loadSkills(page: number = 0) {
    return this.$api
      .get(skillRoutes.skills, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true,
          factory_id: this.current_factory?.id,
          block_id: this.current_block?.id,
          category_id: this.current_category?.id
        }
      })
      .then(({ data: res }: { data: ISkillData }) => {
        if (this.current_page !== res.current_page || this.current_page === 0) {
          this.current_page = res.current_page;

          this.next_page = res.next_page;

          if (res.skills.length) {
            return res.skills;
          } else {
            return [];
          }
        }

        return [];
      });
  }

  public async setReloadSkill(reload: Function) {
    this.reloadSkill = reload;
  }

  public async changeCategoryFilter() {
    this.current_category = this.current_category ?? this.filter_categories[0];

    if (this.current_category.id !== this.filter_categories[0].id) {
      this.filters.set("categories", true);

      this.show_methodological = false;
    } else {
      this.filters.delete("categories");

      this.show_methodological = true;
    }

    this.filters_count = this.filters.size;

    await this.reloadSkill?.call([]);
  }

  public async changeBlockFilter() {
    this.current_block = this.current_block ?? this.filter_blocks[0];

    if (this.current_block.id !== this.filter_blocks[0].id) {
      this.filters.set("blocks", true);
    } else {
      this.filters.delete("blocks");
    }

    this.filters_count = this.filters.size;

    if (this.current_factory) {
      await this.showFactory(this.current_factory);
    }
  }

  public async changeSkillFilter() {
    if (this.current_skill?.id) {
      this.filters.set("skills", true);
    } else {
      this.filters.delete("skills");
    }

    this.filters_count = this.filters.size;
  }

  public async showFactory(factory: StructureFactory) {
    this.$store.commit("factoryProgramId", factory.id);
    this.current_factory = { ...factory };

    await this.reloadSkill?.call([]);
  }

  public clearFilters() {
    this.filters.clear();

    this.current_block = this.filter_blocks[0];
    this.current_category = this.filter_categories[0];
    this.current_skill = {};
  }

  public tabScrollRight() {
    this.$refs.tabs.scrollLeft += 100;
  }

  public tabScrollLeft() {
    this.$refs.tabs.scrollLeft -= 100;
  }

  public allowWrite() {
    return this.$api.allowWrite();
  }
}
