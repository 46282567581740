var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"v-kpi-setting"}},[_c('div',{staticClass:"breadcrumbs d-xs-none"},[_c('router-link',{attrs:{"to":{ name: 'root' }}},[_vm._v("Главная")]),_c('i'),_c('router-link',{attrs:{"to":{ name: 'kpi_catalog' }}},[_vm._v("KPI")]),_c('i'),_c('span',[_vm._v("Настройки")])],1),_vm._m(0),(_vm.workplaceExist)?[_c('div',{staticClass:"tabs t-xs-0"},_vm._l((_vm.links),function(link){return _c('router-link',{key:link.title,attrs:{"to":link.to,"id":link.id,"tag":"div"}},[_vm._v(_vm._s(link.title))])}),1),_c('Preloader',{directives:[{name:"show",rawName:"v-show",value:(_vm.preload),expression:"preload"}]}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.preload),expression:"!preload"}],staticClass:"it-24"},[(_vm.currentTemplate)?[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10 col-sm-7"},[_c('div',{staticClass:"select",class:{ active: _vm.currentTemplate }},[_c('v-select',{attrs:{"options":_vm.templates,"label":"title","value":"id","disabled":!_vm.allowWrite()},on:{"input":_vm.changeTemplate},model:{value:(_vm.currentTemplate),callback:function ($$v) {_vm.currentTemplate=$$v},expression:"currentTemplate"}}),_c('label',[_vm._v("Шаблон KPI")])],1)]),(_vm.allowWrite())?_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn",on:{"click":_vm.updateSetting}},[_vm._v("Сохранить")])]):_vm._e()])]:_vm._e(),(_vm.editSetting)?[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-7"},[_c('h2',{staticClass:"font-18 font-superbold font-uppercase t-32"},[_vm._v(" Выставления итоговых оценок и формирование целей на след. месяц ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 col-sm-4 t-24"},[_c('div',{staticClass:"input",class:{
                    active: _vm.editSetting.task_starts_day
                  }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editSetting.task_starts_day),expression:"editSetting.task_starts_day"}],attrs:{"name":"task_starts_day","type":"number","min":"1","max":"30","autocomplete":"off","disabled":!_vm.allowWrite()},domProps:{"value":(_vm.editSetting.task_starts_day)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editSetting, "task_starts_day", $event.target.value)}}}),_c('label',{attrs:{"for":"task_starts_day"}},[_vm._v("Начало периода")])])]),_c('div',{staticClass:"col-6 col-sm-4 t-24"},[_c('div',{staticClass:"input",class:{
                    active: _vm.editSetting.task_starts_day
                  }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editSetting.task_ends_day),expression:"editSetting.task_ends_day"}],attrs:{"name":"task_ends_day","type":"number","min":"1","max":"30","autocomplete":"off","disabled":!_vm.allowWrite()},domProps:{"value":(_vm.editSetting.task_ends_day)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editSetting, "task_ends_day", $event.target.value)}}}),_c('label',{attrs:{"for":"task_ends_day"}},[_vm._v("Конец периода")])])])]),_c('h2',{staticClass:"font-18 font-superbold font-uppercase t-32"},[_vm._v(" Периодичность обновления ")]),_c('div',{staticClass:"row flex-none"},[_c('div',{staticClass:"col-12 t-24"},[_c('div',{staticClass:"input input-with-text input-with-text--hard",class:{
                    active: _vm.editSetting.task_update_day
                  }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editSetting.task_update_day),expression:"editSetting.task_update_day"}],attrs:{"name":"task_update_day","type":"number","min":"1","max":"30","autocomplete":"off","disabled":!_vm.allowWrite()},domProps:{"value":(_vm.editSetting.task_update_day)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editSetting, "task_update_day", $event.target.value)}}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.editSetting.task_update_day),expression:"editSetting.task_update_day"}],staticClass:"input-with-text__start"},[_vm._v("Каждого")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.editSetting.task_update_day),expression:"editSetting.task_update_day"}],staticClass:"input-with-text__end",style:({
                      left: `${_vm.editSetting.task_update_day.toString().length *
                        7 +
                        70}px`
                    })},[_vm._v("числа")]),_c('label',{attrs:{"for":"task_update_day"}},[_vm._v("Дата обновления целей, в каждом месяце")])])])])])])]:_vm._e()],2)]:_c('CWorkplacePlaceholder')],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row align-items-center middle"},[_c('div',{staticClass:"col col-sm"},[_c('h1',[_vm._v("Настройки")])])])
}]

export { render, staticRenderFns }