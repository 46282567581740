
import { Component, Vue } from "vue-property-decorator";

import Preloader from "@/components/Preloader.vue";
import CPaginatedSelect from "@/components/Select.vue";
import Search from "@/components/Search.vue";
import CStructure, { IStructureProp } from "@/components/Structure.vue";
import CDelegate from "@/views/team/components/Delegate.vue";
import CWorkplacePlaceholder from "@/components/WorkplacePlaceholder.vue";

import { EDeviceTypes } from "@/enums/deviceTypes";

import userRoutes from "@/api/routes/users";
import aktRoutes from "@/api/routes/akt";

import UserUser from "@/models/user/user";
import PositionLocal from "@/models/position/local";
import { IAktTemplate } from "@/models/akt/template";
import { IAktWorkplaceGroup } from "@/models/akt/assignment";
import StructureFactory from "@/models/structure/factory";
import structureRoutes from "@/api/routes/structures";
import { IRouteMeta } from "@/router/interface";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
}

interface IDataProgress {
  readonly id?: number;
  readonly name: string;
  readonly clazz: string;
}

interface IDataTeammate extends IData {
  readonly teammates: UserUser[];
}

interface IDataTemplates extends IData {
  readonly templates: IAktTemplate[];
}

@Component({
  components: {
    Preloader,
    CPaginatedSelect,
    Search,
    CDelegate,
    CStructure,
    CWorkplacePlaceholder
  },
  data: () => {
    return {
      EDeviceTypes
    };
  }
})
export default class AllTeam extends Vue {
  protected preload: boolean = false;
  protected preload_infinite: boolean = false;

  protected per_page: number = 100;
  protected current_page: number = 0;
  protected next_page: boolean = false;

  protected showFilter: boolean = false;

  protected DirectSubordinatesOnly: boolean = true;

  protected search: string = this.$store.state.search;

  protected teamPositions: PositionLocal[] = [{ name: "Все" }];
  protected positionFilter: PositionLocal = this.teamPositions[0];
  protected filters: Map<string, boolean> = new Map();

  protected teammates: UserUser[] = [];

  protected templates: IAktTemplate[] = [];
  protected workplaces: IAktWorkplaceGroup[] = [];
  protected aktGroupErrors: string | null = null;

  protected workplaceExist: boolean = true;

  protected progress: IDataProgress[] = [
    { name: "Все", clazz: "font-blue" },
    { id: 2, name: "Немного отстает", clazz: "font-orange" },
    { id: 4, name: "Успевает", clazz: "font-blue" },
    { id: 3, name: "Сильно отстает по активным программам", clazz: "font-red" }
  ];
  protected currentProgressFilter: IDataProgress = this.progress[0];

  protected factories: StructureFactory[] = [
    { full_name: "Все", level_name: "Уровень", skills: [] }
  ];

  protected factoryFilter: StructureFactory = this.factories[0];
  protected factory_id: string | null = null;
  protected factory_uec: boolean = false;
  protected structure: IStructureProp = {};

  protected currentUser: IJWTUser | null = this.$store.state.currentUser;

  protected watchers: Function[] = [];

  protected async created() {
    this.preload = true;

    this.watchers.push(
      this.$store.watch(
        state => state.search,
        search => {
          this.search = search;
          this.filterHandler();
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.currentUser = currentUser;
        }
      )
    );

    this.WorkplaceExist().then(
      async ({ data: res }: { data: { is_exist: boolean } }) => {
        this.workplaceExist = res.is_exist;

        if (res.is_exist) {
          await Promise.all([
            this.loadAndAssignTeam(),
            this.loadPositions(),
            this.loadTemplates(),
            this.loadWorkplaces(),
            this.loadFactories()
          ]);
        }
      }
    );

    this.preload = false;
  }

  protected async WorkplaceExist() {
    return this.$api.get(userRoutes.workplace_exists(this.currentUser?.id!));
  }

  protected async loadFactories() {
    return this.$api
      .get(structureRoutes.factories, {
        params: {
          only_my: true,
          section_id: (this.$router.currentRoute.meta! as IRouteMeta)
            .permissions?.[0]
        }
      })
      .then(({ data: res }: { data: StructureFactory[] }) => {
        this.factories.push(...res);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async loadTeam(
    page: number = 0,
    searchFilter: string = ""
  ): Promise<IDataTeammate> {
    const defaultParams = Object({
      factory_id: this.factory_id,
      infinity_id: this.structure.infinity_id,
      workline_id: this.structure.workline_id,
      workshop_id: this.structure.workshop_id,
      equipment_id: this.structure.equipment_id,
      direct_subordinates_only: this.DirectSubordinatesOnly,
      page,
      progress_id: this.currentProgressFilter.id,
      position: this.positionFilter.id,
      search: searchFilter,
      per_page: this.per_page,
      paginate: true
    });

    return this.$api
      .get(userRoutes.team, {
        params: defaultParams
      })
      .then(({ data: res }: { data: IDataTeammate }) => {
        return res;
      });
  }

  protected async loadXlsxTeam() {
    const defaultParams = Object({
      factory_id: this.factory_id,
      infinity_id: this.structure.infinity_id,
      workline_id: this.structure.workline_id,
      workshop_id: this.structure.workshop_id,
      equipment_id: this.structure.equipment_id,
      direct_subordinates_only: this.DirectSubordinatesOnly,
      progress_id: this.currentProgressFilter.id,
      position: this.positionFilter.id
    });

    return this.$api
      .post(userRoutes.post_team_xlsx, defaultParams)
      .then(({ data: res }: { data: { base64: string } }) => {
        const link = document.createElement("a");
        link.download = "Моя команда.xlsx";
        link.href = res.base64;
        link.click();
        link.remove();
      });
  }

  protected async loadAndAssignTeam(
    page: number = 0,
    searchFilter: string = ""
  ) {
    this.preload_infinite = true;

    const res: IDataTeammate = await this.loadTeam(page, searchFilter);

    this.current_page = res.current_page;

    if (page === 0) {
      this.teammates = [];
    }

    if (res.teammates.length) {
      this.next_page = res.next_page;

      this.teammates.push(...res.teammates);
    } else {
      this.next_page = false;
    }

    this.preload_infinite = false;
  }

  protected async changeStructureFilter(structure: IStructureProp) {
    this.structure = structure;

    this.teammates = [];

    await this.loadAndAssignTeam();
  }

  protected async loadTeamForSelect(page: number = 0): Promise<UserUser[]> {
    const res: IDataTeammate = await this.loadTeam(page);

    let team: UserUser[] = [];

    if (res.teammates.length) {
      this.next_page = res.next_page;

      team = res.teammates;
    } else {
      this.next_page = false;
    }

    return team;
  }

  protected async loadPositions() {
    return this.$api
      .get(userRoutes.position)
      .then(({ data: res }: { data: PositionLocal[] }) => {
        this.teamPositions.push(...res);
      });
  }

  protected async infiniteHandler() {
    if (this.next_page && !this.preload_infinite) {
      await this.loadAndAssignTeam(this.current_page + 1, this.search);
    }
  }

  protected async progressFilterHandler() {
    if (this.progress[0].id !== this.currentProgressFilter.id) {
      this.filters.set("progress", true);
    } else {
      this.filters.delete("progress");
    }

    this.teammates = [];

    await this.loadAndAssignTeam(0, this.search);
  }

  protected async filterHandler() {
    if (this.DirectSubordinatesOnly) {
      this.filters.set("DirectSubordinatesOnly", true);
    } else {
      this.filters.delete("DirectSubordinatesOnly");
    }

    this.teammates = [];

    await this.loadAndAssignTeam(0, this.search);
  }

  protected async positionHandler() {
    if (this.teamPositions[0].id !== this.positionFilter.id) {
      this.filters.set("position", true);
    } else {
      this.filters.delete("position");
    }

    this.teammates = [];

    await this.loadAndAssignTeam(0, this.search);
  }

  protected async resetFilters() {
    this.filters.clear();

    this.currentProgressFilter = this.progress[0];
    this.factoryFilter = this.factories[0];
    this.positionFilter = this.teamPositions[0];
    this.factory_id = "";
    this.teammates = [];

    await this.loadAndAssignTeam();
  }

  protected changeProgress(id: number) {
    return this.progress.find(i => i.id === id);
  }

  protected async loadWorkplaces() {
    return this.$api
      .get(aktRoutes.groups, {})
      .then(({ data: res }: { data: IAktWorkplaceGroup[] }) => {
        this.workplaces = res;
      });
  }

  protected async loadTemplates() {
    return this.$api
      .get(aktRoutes.templates_actual, {
        params: {
          paginate: false,
          include_archived: false
        }
      })
      .then(({ data: res }: { data: IDataTemplates }) => {
        this.templates = res.templates;
      });
  }

  protected hideAktGroupModal() {
    this.$modal.hide("aktGroup");
  }

  protected async changeFactory() {
    this.factoryFilter = this.factoryFilter ?? this.factories[0];
    this.factory_id = this.factoryFilter.id!;
    this.factory_uec = this.factoryFilter.uec!;

    if (this.factories[0].id !== this.factoryFilter.id) {
      this.filters.set("factory", true);
    } else {
      this.filters.delete("factory");
    }
    this.teammates = [];

    await this.loadAndAssignTeam();
  }

  protected showAktGroupModal() {
    this.$modal.show("aktGroup");
  }

  protected async saveAktGroups() {
    return this.$api
      .post(aktRoutes.groups, { workplaces: this.workplaces })
      .then(() => {
        this.hideAktGroupModal();
      })
      .catch(({ response: res }) => {
        this.aktGroupErrors = res.data.error;
      });
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected allowWrite() {
    return this.$api.allowWrite();
  }
}
