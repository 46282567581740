
import { Component, Vue } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";

@Component
export default class Auth extends Vue {
  protected login: string = "";
  protected password: string = "";

  protected authorizeErrors: string | null = null;

  @Validations()
  private validations = {
    login: {
      required
    },
    password: {
      required
    }
  };

  protected created() {
    this.$store.dispatch("updateCurrentUser", {
      authorized: false,
      currentUser: null,
      currentUserPermissions: null
    });
  }

  protected async signIn() {
    this.authorizeErrors = null;

    this.$v.$touch();
    if (this.$v.$anyError) {
      return;
    }

    this.$api
      .authorize(this.login, this.password)
      .then(() => {
        this.$router.push({ name: "me_dashboard" });
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });

        switch (res.status) {
          case 400:
            this.authorizeErrors = "Неверный логин или пароль";
            break;
          case 404:
            this.authorizeErrors = "Пользователь не найден";
            break;
          case 502:
          case 503:
          case 504:
            this.authorizeErrors = "Сервис недоступен";
            break;
        }
      });
  }
}
