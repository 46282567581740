
import { Component, Vue } from "vue-property-decorator";
import { plainToClass } from "class-transformer";

import testings_routes from "@/api/routes/testings";

import TestingFolder, { ITestingFolder } from "@/models/testing/folder";

@Component({
  name: "CTestingsFolderCreate"
})
export default class CTestingsFolderCreate extends Vue {
  private watchers: Function[] = [];

  public folder: ITestingFolder | null = null;

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected created() {
    this.watchers.push(
      this.$store.watch(
        state => state.creatingTestingFolder,
        (creatingTestingFolder: ITestingFolder | null) => {
          this.folder = creatingTestingFolder;

          if (this.folder) {
            this.showModal();
          }
        }
      )
    );
  }

  public async createFolder() {
    if (!this.folder?.name?.length) {
      this.$notify({
        group: "notifications",
        type: "error",
        text: "Заполните название папки",
        speed: 500
      });

      return;
    }

    return this.$api
      .post(testings_routes.folders, this.folder)
      .then(({ data: res }: { data: TestingFolder }) => {
        this.$store.commit(
          "createdTestingFolder",
          plainToClass(TestingFolder, res)
        );

        this.hideModal();

        this.$notify({
          group: "notifications",
          type: "success",
          text: "Папка создана",
          speed: 500
        });
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public showModal() {
    this.$modal.show("create_folder_modal");
  }

  public hideModal() {
    this.$modal.hide("create_folder_modal");

    this.$store.commit("creatingTestingFolder", null);
  }

  public allowWrite() {
    return this.$api.allowWrite();
  }
}
