var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-column flex-auto",attrs:{"id":"c-team-learn-program"}},[_c('Preloader',{directives:[{name:"show",rawName:"v-show",value:(_vm.preload),expression:"preload"}]}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.preload),expression:"!preload"}],staticClass:"pillar_list t-0-i"},[(
        _vm.programs_with_priority.length > 0 ||
          _vm.programs_without_priority.length > 0 ||
          _vm.programs_with_max_priority.length > 0
      )?_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.infiniteHandler),expression:"infiniteHandler"}],attrs:{"infinite-scroll-distance":"10","infinite-scroll-throttle-delay":"200"}},[(_vm.programs_with_max_priority.length > 0)?[_c('h4',{staticClass:"font-12 font-uppercase font-bold font-gray ib-8"},[_vm._v(" Максимальный приоритет ")]),_c('div',{staticClass:"b-32"},_vm._l((_vm.programs_with_max_priority),function(program){return _c('div',{key:program.id,staticClass:"row middle align-items-center it-4 ib-4 t-8 program-priority-item"},[_c('div',{staticClass:"col-auto"},[_c('v-progress-circular',{staticClass:"d-flex align-items-center justify-content-center",attrs:{"size":"32","width":"2","value":program.program_percent,"rotate":"-90","color":program.program_status ===
                    _vm.ESubscriptionStatus.AWAITING_COMPLETION ||
                  program.program_status ===
                    _vm.ESubscriptionStatus.AWAITING_CONFIRMATION
                    ? '#FFAB00'
                    : program.program_status === _vm.ESubscriptionStatus.FAILED
                    ? '#fa4747'
                    : '#1E64AA'}},[(program.program_percent !== 100)?[_c('span',{staticClass:"font-black font-10"},[_vm._v(" "+_vm._s(Math.round(program.program_percent))+"% ")])]:[(
                      program.program_status ===
                        _vm.ESubscriptionStatus.AWAITING_COMPLETION ||
                        program.program_status ===
                          _vm.ESubscriptionStatus.AWAITING_CONFIRMATION
                    )?_c('inline-svg',{staticClass:"w-20 h-20 font-yellow",attrs:{"src":"/img/icon-done-inherit.svg"}}):_c('inline-svg',{staticClass:"w-20 h-20 font-blue",attrs:{"src":'/img/icon-done-inherit.svg'}})]],2)],1),_c('div',{staticClass:"col"},[_c('router-link',{staticClass:"font-black font-12 font-bold d-flex align-items-center flex-wrap",attrs:{"to":{
                  name: 'subscription_program',
                  params: { id: program.id, student: _vm.student }
                }}},[_c('span',[_vm._v(_vm._s(program.program_version_title))]),(program.is_deadline_missed)?_c('img',{staticClass:"w-20 h-20 bl-4",attrs:{"src":"/img/icon-time-red.svg","alt":"deadline_missed"}}):_vm._e(),_c('p',{staticClass:"font-gray tag bl-26"},[_vm._v(" "+_vm._s(_vm._f("normalizedSubscriptionStatus")(program.program_status))+" ")])])],1),_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"d-flex align-items-center flex-nowrap w-240"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(program.pillar_short_name),expression:"program.pillar_short_name"}],staticClass:"mark w-24 h-24 font-10 br-16"},[_vm._v(" "+_vm._s(program.pillar_short_name)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(program.pillar_name),expression:"program.pillar_name"}],staticClass:"font-12 font-gray"},[_vm._v(" "+_vm._s(program.pillar_name)+" ")])])]),_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"w-190 bl-relative"},[_c('div',{staticClass:"input input--fake input-datepicker"},[_c('span',{staticClass:"font-pointer",on:{"click":function($event){_vm.$refs[`datepicker_${program.id}`].map(l =>
                        l.classList.toggle('open')
                      )}}},[_vm._v(" "+_vm._s(_vm._f("toDate")(program.starts_at))+" - "+_vm._s(_vm._f("toDate")(program.deadline_at))+" ")]),_c('label',[_vm._v("Даты")])]),_c('div',{ref:`datepicker_${program.id}`,refInFor:true,staticClass:"datepicker-period"},[_c('div',{staticClass:"datepicker-period__overlay",on:{"click":function($event){_vm.$refs[`datepicker_${program.id}`].map(l =>
                        l.classList.toggle('open')
                      )}}}),_c('div',{staticClass:"datepicker-period__wrap"},[_c('div',[_c('span',{staticClass:"d-block font-10 font-bold font-lightgray"},[_vm._v("Дата начала")]),_c('v-datepicker',{staticClass:"select",attrs:{"language":_vm.ru,"full-month-name":true,"inline":true},on:{"input":function($event){return _vm.changeStartsAt(program)}},model:{value:(program.starts_at),callback:function ($$v) {_vm.$set(program, "starts_at", $$v)},expression:"program.starts_at"}})],1),_c('div',[_c('span',{staticClass:"d-block font-10 font-bold font-lightgray"},[_vm._v("Дата окончания")]),_c('v-datepicker',{staticClass:"select",attrs:{"language":_vm.ru,"full-month-name":true,"inline":true},on:{"input":function($event){return _vm.changeDeadlineAt(program)}},model:{value:(program.deadline_at),callback:function ($$v) {_vm.$set(program, "deadline_at", $$v)},expression:"program.deadline_at"}})],1)])])])])])}),0)]:_vm._e(),(_vm.programs_with_priority.length > 0)?[_c('h4',{staticClass:"font-12 font-uppercase font-bold font-gray ib-8"},[_vm._v(" В горизонте ")]),_c('div',{staticClass:"b-32"},_vm._l((_vm.programs_with_priority),function(program){return _c('div',{key:program.id,staticClass:"row middle align-items-center it-4 ib-4 t-8 program-priority-item"},[_c('div',{staticClass:"col-auto"},[_c('v-progress-circular',{staticClass:"d-flex align-items-center justify-content-center",attrs:{"size":"32","width":"2","value":program.program_percent,"rotate":"-90","color":program.program_status ===
                    _vm.ESubscriptionStatus.AWAITING_COMPLETION ||
                  program.program_status ===
                    _vm.ESubscriptionStatus.AWAITING_CONFIRMATION
                    ? '#FFAB00'
                    : program.program_status === _vm.ESubscriptionStatus.FAILED
                    ? '#fa4747'
                    : '#1E64AA'}},[(program.program_percent !== 100)?[_c('span',{staticClass:"font-black font-10"},[_vm._v(_vm._s(Math.round(program.program_percent))+"%")])]:[(
                      program.program_status ===
                        _vm.ESubscriptionStatus.AWAITING_COMPLETION ||
                        program.program_status ===
                          _vm.ESubscriptionStatus.AWAITING_CONFIRMATION
                    )?_c('inline-svg',{staticClass:"w-20 h-20 font-yellow",attrs:{"src":"/img/icon-done-inherit.svg"}}):_c('inline-svg',{staticClass:"w-20 h-20 font-blue",attrs:{"src":'/img/icon-done-inherit.svg'}})]],2)],1),_c('div',{staticClass:"col"},[_c('router-link',{staticClass:"font-black font-12 font-bold d-flex align-items-center flex-wrap",attrs:{"to":{
                  name: 'subscription_program',
                  params: { id: program.id, student: _vm.student }
                }}},[_c('span',[_vm._v(_vm._s(program.program_version_title))]),(program.is_deadline_missed)?_c('img',{staticClass:"w-20 h-20 bl-4",attrs:{"src":"/img/icon-time-red.svg","alt":"deadline_missed"}}):_vm._e(),_c('p',{staticClass:"font-gray tag bl-26"},[_vm._v(" "+_vm._s(_vm._f("normalizedSubscriptionStatus")(program.program_status))+" ")])])],1),_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"d-flex align-items-center flex-nowrap w-240"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(program.pillar_short_name),expression:"program.pillar_short_name"}],staticClass:"mark w-24 h-24 font-10 br-16"},[_vm._v(" "+_vm._s(program.pillar_short_name)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(program.pillar_name),expression:"program.pillar_name"}],staticClass:"font-12 font-gray"},[_vm._v(" "+_vm._s(program.pillar_name)+" ")])])]),_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"w-190 bl-relative"},[_c('div',{staticClass:"input input--fake input-datepicker"},[_c('span',{staticClass:"font-pointer",on:{"click":function($event){_vm.$refs[`datepicker_${program.id}`].map(l =>
                        l.classList.toggle('open')
                      )}}},[_vm._v(" "+_vm._s(_vm._f("toDate")(program.starts_at))+" - "+_vm._s(_vm._f("toDate")(program.deadline_at))+" ")]),_c('label',[_vm._v("Даты")])]),_c('div',{ref:`datepicker_${program.id}`,refInFor:true,staticClass:"datepicker-period"},[_c('div',{staticClass:"datepicker-period__overlay",on:{"click":function($event){_vm.$refs[`datepicker_${program.id}`].map(l =>
                        l.classList.toggle('open')
                      )}}}),_c('div',{staticClass:"datepicker-period__wrap"},[_c('div',[_c('span',{staticClass:"d-block font-10 font-bold font-lightgray"},[_vm._v("Дата начала")]),_c('v-datepicker',{staticClass:"select",attrs:{"language":_vm.ru,"full-month-name":true,"inline":true},on:{"input":function($event){return _vm.changeStartsAt(program)}},model:{value:(program.starts_at),callback:function ($$v) {_vm.$set(program, "starts_at", $$v)},expression:"program.starts_at"}})],1),_c('div',[_c('span',{staticClass:"d-block font-10 font-bold font-lightgray"},[_vm._v("Дата окончания")]),_c('v-datepicker',{staticClass:"select",attrs:{"language":_vm.ru,"full-month-name":true,"inline":true},on:{"input":function($event){return _vm.changeDeadlineAt(program)}},model:{value:(program.deadline_at),callback:function ($$v) {_vm.$set(program, "deadline_at", $$v)},expression:"program.deadline_at"}})],1)])])])])])}),0)]:_vm._e(),(_vm.programs_without_priority.length > 0)?[_c('h4',{staticClass:"font-12 font-uppercase font-bold font-gray ib-8"},[_vm._v(" Не в горизонте ")]),_c('div',{staticClass:"b-32"},_vm._l((_vm.programs_without_priority),function(program){return _c('div',{key:program.id,staticClass:"row middle align-items-center it-4 ib-4 t-8 program-priority-item"},[_c('div',{staticClass:"col-auto"},[_c('v-progress-circular',{staticClass:"d-flex align-items-center justify-content-center",attrs:{"size":"32","width":"2","value":program.program_percent,"rotate":"-90","color":"#1E64AA"}},[(program.program_percent !== 100)?[_c('span',{staticClass:"font-black font-10"},[_vm._v(_vm._s(Math.round(program.program_percent))+"%")])]:[_c('inline-svg',{staticClass:"w-20 h-20 font-blue",attrs:{"src":'/img/icon-done-inherit.svg'}})]],2)],1),_c('div',{staticClass:"col"},[_c('router-link',{staticClass:"font-black font-12 font-bold d-flex align-items-center flex-wrap",attrs:{"to":{
                  name: 'subscription_program',
                  params: { id: program.id, student: _vm.student }
                }}},[_c('span',[_vm._v(_vm._s(program.program_version_title))]),(program.is_deadline_missed)?_c('img',{staticClass:"w-20 h-20 bl-4",attrs:{"src":"/img/icon-time-red.svg","alt":"deadline_missed"}}):_vm._e()])],1),_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"d-flex align-items-center flex-nowrap w-240"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(program.pillar_short_name),expression:"program.pillar_short_name"}],staticClass:"mark w-24 h-24 font-10 br-16"},[_vm._v(" "+_vm._s(program.pillar_short_name)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(program.pillar_name),expression:"program.pillar_name"}],staticClass:"font-12 font-gray"},[_vm._v(" "+_vm._s(program.pillar_name)+" ")])])])])}),0)]:_vm._e()],2):_c('div',{staticClass:"d-flex align-items-center justify-content-center flex-column"},[_c('div',{staticClass:"no-found-icon"},[_c('inline-svg',{staticClass:"w-40 h-40",attrs:{"src":'/img/icon-catalog.svg'}})],1),_c('p',{staticClass:"t-24 font-18 font-superbold align-center"},[_vm._v(" Программы отсутствуют ")]),_c('p',{staticClass:"align-center font-14 t-8 max-width-320"},[_vm._v(" Назначьте сотруднику программы обучения, чтобы следить за прогрессом обучения ")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }