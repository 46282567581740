
import { Component, Vue } from "vue-property-decorator";
import { plainToClass } from "class-transformer";

import Preloader from "@/components/Preloader.vue";

import akt_routes from "@/api/routes/akt";
import { examinations_child_list_routes } from "@/router/users/examinations";

import { AssignmentUser } from "@/models/akt/assignment_user";
import {
  AssignmentUserWorkplace,
  EAktAssignmentUserWorkplaceType
} from "@/models/akt/assignment_user_workplace";
import { normalizedAssignmentUserWorkplaceResultStatusColor } from "@/filters/akt";

@Component({
  name: "VUserAktTestings",
  components: {
    Preloader
  }
})
export default class VUserAktTestings extends Vue {
  public preload: boolean = false;

  public normalizedAssignmentUserWorkplaceResultStatusColor = normalizedAssignmentUserWorkplaceResultStatusColor;

  public links = examinations_child_list_routes.map(r => {
    return {
      title: r.meta!.name,
      to: {
        name: r.name
      }
    };
  });

  public current_year: number = new Date().getFullYear();
  public available_years: number[] = [this.current_year];

  public assignment_users: AssignmentUser[] = [];
  public available_workplaces: AssignmentUserWorkplace[] = [];
  public passed_workplaces: AssignmentUserWorkplace[] = [];

  private current_user: IJWTUser | null = this.$store.state.currentUser;

  public show_filter: boolean = false;
  public filters: Map<string, boolean> = new Map();
  public filters_count: number = 0;

  private watchers: Function[] = [];

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async created() {
    this.filters.set("current_year", true);
    this.filters_count = this.filters.size;

    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        async currentUser => {
          this.current_user = currentUser;

          await this.loadAssignments();
        }
      )
    );

    await Promise.all([this.loadYears(), this.loadAssignments()]);
  }

  private async loadYears() {
    return this.$api
      .get(akt_routes.assignment_available_years)
      .then(({ data: res }: { data: number[] }) => {
        this.available_years = res;
      });
  }

  private loadAssignments() {
    if (!this.current_user) {
      return;
    }

    this.preload = true;

    this.assignment_users = [];
    this.available_workplaces = [];
    this.passed_workplaces = [];

    return this.$api
      .get(akt_routes.assignments(this.current_user.id), {
        params: {
          include_workplaces: true,
          starts_year: this.current_year,
          only_active: true,
          type_id: EAktAssignmentUserWorkplaceType.ONLINE
        }
      })
      .then(({ data: res }: { data: AssignmentUser[] }) => {
        if (Object.keys(res).length) {
          this.assignment_users = plainToClass(AssignmentUser, res);

          this.assignment_users.forEach(assignment_user => {
            assignment_user.workplaces.forEach(workplace => {
              if (!workplace.attempt_starts_at) {
                workplace.attempt_starts_at = assignment_user.starts_at;
              }

              if (!workplace.attempt_ends_at) {
                workplace.attempt_ends_at = assignment_user.ends_at;
              }

              if (workplace.isNotPassed) {
                this.available_workplaces.push(workplace);
              } else {
                this.passed_workplaces.push(workplace);
              }
            });
          });
        }
      })
      .finally(() => {
        this.preload = false;
      });
  }

  public async changeYear() {
    if (!this.current_year) {
      this.current_year = new Date().getFullYear();
    }

    this.filters.set("current_year", true);

    await this.apply();
  }

  public async clearFilters() {
    this.filters.clear();

    this.current_year = new Date().getFullYear();
    this.filters.set("current_year", true);

    await this.apply();
  }

  private async apply() {
    this.filters_count = this.filters.size;

    await this.loadAssignments();
  }

  public async startAkt(id: string) {
    return this.$api
      .put(akt_routes.put_workplace_start(id), {})
      .then(() => {
        this.$router.push({ name: "akt_user_testing", params: { id } });
      })
      .catch(({ response }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: response.data.error,
          speed: 500
        });
      });
  }

  public showAkt(id: string) {
    this.$router.push({ name: "akt_user_testing", params: { id } });
  }
}
