
import { Component, Vue } from "vue-property-decorator";
import { plainToClass } from "class-transformer";

import testings_routes from "@/api/routes/testings";

import TestingFolder, { ITestingFolder } from "@/models/testing/folder";

@Component({
  name: "CTestingsFolderUpdate"
})
export default class CTestingsFolderUpdate extends Vue {
  private watchers: Function[] = [];

  public folder: ITestingFolder | null = null;

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected created() {
    this.watchers.push(
      this.$store.watch(
        state => state.updatingTestingFolder,
        (updatingTestingFolder: ITestingFolder | null) => {
          this.folder = updatingTestingFolder;

          if (this.folder) {
            this.showModal();
          }
        }
      )
    );
  }

  public async updateFolder() {
    if (!this.folder?.name?.length) {
      this.$notify({
        group: "notifications",
        type: "error",
        text: "Заполните название папки",
        speed: 500
      });

      return;
    }

    this.$api
      .put(testings_routes.folder(this.folder!.id!), this.folder)
      .then(({ data: res }: { data: TestingFolder }) => {
        this.$store.commit(
          "updatedTestingFolder",
          plainToClass(TestingFolder, res)
        );

        this.hideModal();

        this.$notify({
          group: "notifications",
          type: "success",
          text: "Папка переименована",
          speed: 500
        });
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public showModal() {
    this.$modal.show("update_folder_modal");
  }

  public hideModal() {
    this.$modal.hide("update_folder_modal");

    this.$store.commit("updatingTestingFolder", null);
  }

  public allowWrite() {
    return this.$api.allowWrite();
  }
}
