
import { Component, Prop, Vue } from "vue-property-decorator";
import { phases } from "@/consts";
import BlockBlock from "../models/block/block";

@Component
export default class CLevelSelector extends Vue {
  @Prop({ required: false, default: false }) public disabled!: boolean;

  @Prop({ required: true }) private blocks!: BlockBlock[];
  // если basic_blocks передано, то уровни навыков, содержащиеся в его навыках, будут учитываться, как минимально допустимые
  @Prop({ required: false, default: null }) private basic_blocks!:
    | BlockBlock[]
    | null;

  private selectedLevel: number = 0;
  protected phases = phases;

  private apply() {
    this.blocks.forEach(block => {
      block.skills?.forEach(skill => {
        if (skill.selected) {
          const basic_skill = this.basic_blocks
            ?.find(b => b.id === block.id)
            ?.skills?.find(
              s =>
                (s.id && s.id === skill.id) ||
                (s.skill_id && s.skill_id === skill.skill_id) ||
                (s.origin_id && s.origin_id === skill.origin_id)
            );

          if (
            basic_skill &&
            basic_skill.level &&
            this.selectedLevel < basic_skill.level
          ) {
            skill.level = basic_skill.level;
          } else if (
            typeof skill.min_level === "number" &&
            this.selectedLevel < skill.min_level
          ) {
            skill.level! = skill.min_level;
          } else {
            skill.level = this.selectedLevel;
          }
        }
      });
    });
  }
}
