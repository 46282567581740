
import { Vue, Component } from "vue-property-decorator";

import { ISignInResponse } from "@/api/routes/auth";

@Component
export default class AuthCallback extends Vue {
  protected authorizeErrors: string | null = null;

  protected async created() {
    this.$store.dispatch("updateCurrentUser", {
      authorized: false,
      currentUser: null,
      currentUserPermissions: null
    });

    await this.parseHashValues();
  }

  protected async parseHashValues() {
    let token = location.hash.substring(1);
    token = JSON.parse(
      '{"' +
        decodeURI(token)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    );

    return this.$api
      .authorizeByOpenID(token)
      .then((res: ISignInResponse) => {
        window.postMessage(res, "*");
        this.$router.push({ name: "me_dashboard" });
      })
      .catch(({ response: res }) => {
        switch (res.status) {
          case 400:
            this.authorizeErrors = "Некорректные данные";
            break;
          case 404:
            this.authorizeErrors = "Пользователь не найден";
            break;
          case 502:
          case 503:
          case 504:
            this.authorizeErrors = "Сервис недоступен";
            break;
        }
      });
  }
}
