import { render, staticRenderFns } from "./Practical.vue?vue&type=template&id=4f5d0967&scoped=true"
import script from "./Practical.vue?vue&type=script&lang=ts"
export * from "./Practical.vue?vue&type=script&lang=ts"
import style0 from "./Practical.vue?vue&type=style&index=0&id=4f5d0967&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f5d0967",
  null
  
)

export default component.exports