
import { Component, Vue } from "vue-property-decorator";

import CWindRoseChart from "@/components/WindRoseChart.vue";
import CCategoryChart from "@/views/dashboards/components/CategoryChart.vue";

import analyticsRoutes from "@/api/routes/analytics";
import { analytics_leader_routes } from "@/router/analytics";

import { IChartData } from "@/models/analytics/chart";
import { ESkillCategory } from "@/models/skill/category";

@Component({
  components: {
    CWindRoseChart,
    CCategoryChart
  }
})
export default class VSkillCategoryChart extends Vue {
  protected links = analytics_leader_routes.map(r => {
    return {
      title: r.meta!.name,
      to: {
        name: r.name
      }
    };
  });

  protected category: ESkillCategory = 0;

  protected category_avg: IChartData | null = null;

  protected async created() {
    await this.loadSkillCategoryAvg();
  }

  protected async SetCategoryChart(index: number) {
    this.category = this.category_avg!.ids![index]! as number;
  }

  protected async loadSkillCategoryAvg() {
    return this.$api
      .get(analyticsRoutes.categories_avg)
      .then(({ data: res }: { data: IChartData }) => {
        this.category_avg = res;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }
}
