
import { Component, Vue, Prop } from "vue-property-decorator";

import { AdmissionRole } from "@/models/admission/role";
import {
  AdmissionPermission,
  AdmissionPermissionText,
  EAdmissionLevel,
  EAdmissionPermission
} from "@/models/admission/permission";
import StructureFactory from "@/models/structure/factory";

@Component({
  name: "CAdmissionRoleChangePermissions"
})
export default class CAdmissionRoleChangePermissions extends Vue {
  @Prop({ required: true }) public role!: AdmissionRole;
  @Prop({ required: true }) public factories!: StructureFactory[];

  public AdmissionPermissionText = AdmissionPermissionText;

  public admission_levels = [
    {
      label: "Чтение",
      value: EAdmissionLevel.READ
    },
    {
      label: "Чтение и запись",
      value: EAdmissionLevel.WRITE
    }
  ];

  public changeAdmissionLevel(permission: AdmissionPermission) {
    if (!permission.admission_level_id) {
      permission.admission_level_id = EAdmissionLevel.READ;
    }
  }

  public changeFactories(permission: AdmissionPermission) {
    if (!permission.factory_ids) {
      this.$set(permission, "factory_ids", []);
    }
  }

  public changeOnlyUserWorkplaces(permission: AdmissionPermission) {
    if (permission.only_user_workplaces) {
      this.$set(permission, "factory_ids", []);
    }
  }

  public changeOnlyUserWorkplacesLabel(permission: AdmissionPermission) {
    if (permission.permission_id === EAdmissionPermission.ANALYTICS) {
      return "Доступ по своим Рабочим местам";
    }

    return "Доступ по своему Заводу";
  }
}
