
import { Component, Vue } from "vue-property-decorator";

import Preloader from "@/components/Preloader.vue";
import Search from "@/components/Search.vue";
import CWorkplacePlaceholder from "@/components/WorkplacePlaceholder.vue";

import kpiRoutes from "@/api/routes/kpi";

import { EDeviceTypes } from "@/enums/deviceTypes";

import KpiTemplate from "@/models/kpi/template";
import { EKpiSubLevelType, kpiSubLevelTypes } from "@/models/kpi/sub_level";
import { classToClass } from "class-transformer";
import { pluck } from "@/helpers";
import userRoutes from "@/api/routes/users";

interface IKpiTargetUser {
  id: string;
  setting_user_id: string;
  achieved: boolean;
  user: {
    id: string;
    email: string;
    full_name: string;
    identification_number: string;
    position_name?: string;
  };
  sub_levels: Array<{
    id: string;
    title: string;
    comment: string;
    type_id: string;
    target_id: string;
    rating: {
      leader: boolean;
      self?: boolean;
    };
  }>;
}

interface IKpiAssignmentCollectiveSubLevel {
  id: string;
  title: string;
  type_id: number;
  comment: string;
  bonus_percent: number;
  comment_error?: string;
  bonus_percent_error?: string;
  target_user_ids: string[];
  is_show?: boolean;
  is_selected?: boolean;
}

interface IKpiAssignmentCollective {
  id: string;
  use_prev_targets: string;
  template_id: string;
  template_id_error?: string;
  sub_levels: Array<IKpiAssignmentCollectiveSubLevel>;
}

interface IKpiAssignmentPersonal {
  id: string;
  use_prev_targets: string;
  template_id: string;
  template_id_error?: string;
  sub_levels: Array<{
    id: string;
    title: string;
    type_id: number;
  }>;
  sub_level_results: {
    [target_user_id: string]: Array<{
      id?: string | null; // target_personal_sub_level_id
      sub_level_id?: string;
      comment: string;
      bonus_percent: number;
      sub_level_error?: string;
      comment_error?: string;
      bonus_percent_error?: string;
    }>;
  };
}

interface IKpiAssignment {
  id: string;
  year: number;
  month: number;
  is_archived: boolean;
  factory_id: string;
  users: Array<{
    target_user_id: string;
    user_id: string;
    user_email: string;
    user_full_name: string;
    user_identification_number: string;
    user_position_name: string;
    is_personal_show?: boolean;
  }>;
  collective: IKpiAssignmentCollective;
  personal: IKpiAssignmentPersonal;
}

@Component({
  components: {
    CWorkplacePlaceholder,
    Preloader,
    Search
  },
  data: () => {
    return {
      kpiSubLevelTypes,
      EKpiSubLevelType,
      EDeviceTypes
    };
  }
})
export default class VKpiAssignment extends Vue {
  protected preload: boolean = false;

  protected search: string = this.$store.state.search;

  private currentUser: IJWTUser | null = this.$store.state.currentUser;

  protected kpi_users: IKpiTargetUser[] = [];
  protected kpi_users_searched: IKpiTargetUser[] = [];

  protected kpi_assignment: IKpiAssignment | null = null;
  protected kpi_personal_user_percent: {
    [target_user_id: string]: number;
  } = {};

  protected workplaceExist: boolean = true;

  protected collective_templates: KpiTemplate[] = [];
  protected collective_template: KpiTemplate | null = null;

  protected personal_templates: KpiTemplate[] = [];
  protected personal_template: KpiTemplate | null = null;

  protected currentTab: EKpiSubLevelType = EKpiSubLevelType.COLLECTIVE;
  protected collectiveTabError: boolean = false;
  protected personalTabError: boolean = false;

  protected edit_kpi_user: IKpiTargetUser | null = null;
  protected edit_kpi_user_index: number | null = null;

  protected emptyMessage =
    "Назначайте целевые показатели сотрудникам и проставляйте итоговую оценку";
  protected disableButtons = false;

  protected watchers: Function[] = [];

  protected async created() {
    this.preload = true;

    this.watchers.push(
      this.$store.watch(
        state => state.search,
        search => {
          this.search = search;
          this.changeSearch();
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.currentUser = currentUser;
        }
      )
    );

    this.WorkplaceExist().then(
      async ({ data: res }: { data: { is_exist: boolean } }) => {
        this.workplaceExist = res.is_exist;

        if (res.is_exist) {
          await Promise.all([
            this.loadTargets(null),
            this.loadTargetTemplates()
          ]);

          const coll_template = this.collective_templates.find(
            t => t.id === this.kpi_assignment?.collective.template_id
          );

          if (coll_template) {
            this.collective_template = coll_template;
          }

          const pers_template = this.personal_templates.find(
            t => t.id === this.kpi_assignment?.personal.template_id
          );
          if (pers_template) {
            this.personal_template = pers_template;
          }

          this.preload = false;
        }
      }
    );
  }

  protected async WorkplaceExist() {
    return this.$api.get(userRoutes.workplace_exists(this.currentUser?.id!));
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected changeSearch() {
    if (this.search?.length) {
      this.kpi_users_searched = this.kpi_users.filter(
        u =>
          u.user.full_name.toLowerCase().indexOf(this.search.toLowerCase()) !==
          -1
      );
    } else {
      this.kpi_users_searched = Array.from(this.kpi_users);
    }
  }

  protected async loadTargets(
    isPersonal?: boolean | null,
    prevMonth: boolean = false
  ) {
    return this.$api
      .get(kpiRoutes.get_kpi_targets, {
        params: { prev_month: prevMonth }
      })
      .then(
        ({
          data: res
        }: {
          data: { list: IKpiTargetUser[]; assignment: IKpiAssignment };
        }) => {
          this.kpi_users = Array.from(res.list);
          this.kpi_users_searched = Array.from(res.list);

          // Подгрузка параметров предыдущего месяца
          if (prevMonth) {
            res.assignment.collective.use_prev_targets = this.kpi_assignment!.collective.use_prev_targets!;
            res.assignment.personal.use_prev_targets = this.kpi_assignment!.personal.use_prev_targets!;
            res.assignment.is_archived = this.kpi_assignment!.is_archived!;
            res.assignment.month = this.kpi_assignment!.month!;
            res.assignment.year = this.kpi_assignment!.year!;

            // Получение шаблонов
            const coll_template = this.collective_templates.find(
              t => t.id === res.assignment.collective.template_id
            );

            if (coll_template && !isPersonal) {
              this.collective_template = coll_template;
            }

            const pers_template = this.personal_templates.find(
              t => t.id === res.assignment.personal.template_id
            );

            if (pers_template && isPersonal) {
              this.personal_template = pers_template;
            }
          }

          // Получение дааных о всем назначении
          if (!prevMonth && isPersonal === null) {
            this.kpi_assignment = res.assignment!;
          } else if (!isPersonal) {
            // Загрузка данных о коллективном назначении с предыдущего месяца
            res.assignment.collective.id = this.kpi_assignment!.collective.id!;

            this.$set(
              this.kpi_assignment!,
              "collective",
              res.assignment!.collective!
            );
          } else if (isPersonal) {
            // Получаем данные шаблона с предыдущего месяца
            this.loadTargetAssignmentPersonal(
              this.kpi_assignment!.id!,
              res.assignment!.personal!.template_id
            ).then(() => {
              // Переностим суб параменты с предыдущего месяца в наструйки каждого сотрудника в текущий
              for (const user of this.kpi_assignment!.users) {
                const prevUser = res.assignment!.users!.find(
                  i => i.user_id === user.user_id
                );

                // если в предыдущем месяце сотруднику назначали KPI, то перенести в текущий
                if (prevUser) {
                  const prevResults = res.assignment!.personal
                    .sub_level_results[prevUser!.target_user_id!];

                  // Удалить id sub_result для создания sub_result в текущем месяце с прошлыми параметрами
                  prevResults.map(i => {
                    i.id = null;
                  });

                  this.kpi_assignment!.personal.sub_level_results[
                    user.target_user_id!
                  ].push(...prevResults);
                }
              }
            });
          }
        }
      )
      .catch(() => {
        this.disableButtons = true;
        this.emptyMessage = "Настройте права в разделе Настройки KPI";
      });
  }

  protected changeCollectivePrevTargets() {
    if (this.kpi_assignment!.collective.use_prev_targets) {
      this.loadTargets(false, true);
    }
  }

  protected changeIndividualPrevTargets() {
    if (this.kpi_assignment!.personal.use_prev_targets) {
      this.loadTargets(true, true);
    }
  }

  protected changeKpiAssignment(prevMonth: boolean = false) {
    if (this.kpi_assignment) {
      if (this.kpi_assignment.collective?.sub_levels?.length) {
        this.kpi_assignment.collective.sub_levels.forEach(sub_level => {
          if (sub_level.target_user_ids.length) {
            sub_level.is_selected = true;

            // Добавить всех пользователей в target из предыдущего месяца
            if (this.kpi_assignment?.users?.length && prevMonth) {
              sub_level.target_user_ids = pluck(
                this.kpi_assignment.users,
                "target_user_id"
              );
            }
          }
        });
      }

      if (this.kpi_assignment.personal?.sub_level_results) {
        for (const [target_user_id, results] of Object.entries(
          this.kpi_assignment.personal.sub_level_results
        )) {
          this.kpi_personal_user_percent[target_user_id] = results.reduce(
            (acc, curr) => acc + (curr.bonus_percent || 0),
            0
          );
        }
      }
    }
  }

  protected async loadTargetTemplates() {
    return this.$api
      .get(kpiRoutes.get_kpi_target_templates)
      .then(
        ({
          data: res
        }: {
          data: { collectives: KpiTemplate[]; personals: KpiTemplate[] };
        }) => {
          this.collective_templates = res.collectives;
          this.personal_templates = res.personals;
        }
      );
  }

  protected async loadTargetAssignmentCollective(
    target_id: string,
    template_id?: string
  ) {
    return this.$api
      .get(
        kpiRoutes.get_kpi_target_assignment_collectives(target_id, template_id)
      )
      .then(({ data: res }: { data: IKpiAssignmentCollective }) => {
        this.$set(
          this.kpi_assignment!.collective,
          "sub_levels",
          res.sub_levels
        );
      });
  }

  protected async loadTargetAssignmentPersonal(
    target_id: string,
    template_id?: string
  ) {
    return this.$api
      .get(
        kpiRoutes.get_kpi_target_assignment_personals(target_id, template_id)
      )
      .then(({ data: res }: { data: IKpiAssignmentPersonal }) => {
        this.$set(this.kpi_assignment!.personal, "sub_levels", res.sub_levels);
        this.$set(
          this.kpi_assignment!.personal,
          "sub_level_results",
          res.sub_level_results
        );
      });
  }

  protected async changePersonalTemplate() {
    if (this.kpi_assignment) {
      this.kpi_assignment.personal.template_id = this.personal_template?.id!;

      await this.loadTargetAssignmentPersonal(
        this.kpi_assignment.id,
        this.kpi_assignment.personal.template_id
      );
    }
  }

  protected async changeCollectiveTemplate() {
    if (this.kpi_assignment) {
      this.kpi_assignment.collective.template_id = this.collective_template?.id!;

      await this.loadTargetAssignmentCollective(
        this.kpi_assignment.id,
        this.kpi_assignment.collective.template_id
      );
    }
  }

  protected toggleCollectiveSubLevel(sub_level_index: number) {
    const is_show = this.kpi_assignment!.collective.sub_levels[sub_level_index]
      .is_show;

    this.$set(
      this.kpi_assignment!.collective.sub_levels[sub_level_index],
      "is_show",
      !is_show
    );
  }

  protected togglePersonalUser(user_index: number) {
    const is_show = this.kpi_assignment!.users[user_index].is_personal_show;

    this.$set(
      this.kpi_assignment!.users[user_index],
      "is_personal_show",
      !is_show
    );
  }

  protected addPersonalTarget(target_user_id: string) {
    if (this.kpi_assignment) {
      const count = this.kpi_assignment.personal.sub_level_results[
        target_user_id
      ].length;

      if (count >= this.kpi_assignment.personal.sub_levels.length) {
        return;
      }

      this.kpi_assignment.personal.sub_level_results[target_user_id].push({
        comment: "",
        bonus_percent: 0
      });
    }
  }

  protected changePersonalBonusPercent(target_user_id: string) {
    this.kpi_personal_user_percent[
      target_user_id
    ] = this.kpi_assignment!.personal.sub_level_results[target_user_id].reduce(
      (acc, curr) => acc + (+curr.bonus_percent || 0),
      0
    );
  }

  protected removePersonalTarget(
    target_user_id: string,
    sub_level_index: number
  ) {
    if (this.kpi_assignment) {
      this.$delete(
        this.kpi_assignment.personal.sub_level_results[target_user_id],
        sub_level_index
      );
    }
  }

  protected beforeChangeAssignment() {
    this.showAssignmentModal();
  }

  protected showAssignmentModal() {
    this.$modal.show("editKpiAssignment");
  }

  protected hideAssignmentModal() {
    this.currentTab = EKpiSubLevelType.COLLECTIVE;

    this.$modal.hide("editKpiAssignment");
  }

  protected showEditKpiUserModal() {
    this.$modal.show("editKpiAssignmentUser");
  }

  protected hideEditKpiUserModal() {
    this.$modal.hide("editKpiAssignmentUser");
  }

  protected closeEditKpiUserModal() {
    this.edit_kpi_user_index = null;
    this.edit_kpi_user = null;
  }

  protected async saveKpiUser() {
    if (!this.allowWrite()) {
      return;
    }

    if (this.edit_kpi_user) {
      const data = this.edit_kpi_user.sub_levels.reduce((acc, curr) => {
        acc[curr.id] = curr.rating.leader;

        return acc;
      }, {} as { [id: string]: boolean });

      return this.$api
        .post(
          kpiRoutes.post_kpi_target_user_leader_rating(this.edit_kpi_user.id),
          {
            id: this.edit_kpi_user.id,
            sub_levels: this.edit_kpi_user.sub_levels
          }
        )
        .then(({ data: res }: { data: { id: string; achieved: boolean } }) => {
          this.kpi_users_searched[this.edit_kpi_user_index!].sub_levels.forEach(
            sub_level => {
              sub_level.rating.leader = data[sub_level.id];
            }
          );
          this.kpi_users_searched[this.edit_kpi_user_index!].achieved =
            res.achieved;

          const originIndex = this.kpi_users.findIndex(
            u => u.id === this.edit_kpi_user!.id
          );
          this.kpi_users[originIndex].sub_levels.forEach(sub_level => {
            sub_level.rating.leader = data[sub_level.id];
          });
          this.kpi_users[originIndex].achieved = res.achieved;

          this.hideEditKpiUserModal();
        })
        .catch(({ response: res }) => {
          this.$notify({
            group: "notifications",
            type: "error",
            text: res.data.error,
            speed: 500
          });
        });
    }
  }

  protected beforeEditKpiAssignmentUser(user_index: number) {
    if (!this.allowWrite()) {
      return;
    }

    this.edit_kpi_user_index = user_index;
    this.edit_kpi_user = classToClass(this.kpi_users_searched[user_index]);

    this.showEditKpiUserModal();
  }

  protected async saveAssignment() {
    if (!this.allowWrite()) {
      return;
    }

    let errors = false;
    this.collectiveTabError = false;
    this.personalTabError = false;

    this.kpi_assignment!.collective.sub_levels.forEach(sub_level => {
      if (sub_level.is_selected) {
        if (sub_level.comment?.length) {
          this.$set(sub_level, "comment_error", null);
        } else {
          this.$set(sub_level, "comment_error", "Введите комментарий");

          errors = true;
          this.collectiveTabError = true;
        }

        if (sub_level.bonus_percent != null) {
          if (
            +sub_level.bonus_percent !==
            this.collective_template!.collective_bonus
          ) {
            this.$set(
              sub_level,
              "bonus_percent_error",
              "Процент премии не соответствует целевому значению"
            );

            errors = true;
            this.collectiveTabError = true;
          } else {
            this.$set(sub_level, "bonus_percent_error", null);
          }
        } else {
          this.$set(sub_level, "bonus_percent_error", "Укажите процент премии");

          errors = true;
          this.collectiveTabError = true;
        }
      } else {
        this.$set(sub_level, "comment_error", null);
        this.$set(sub_level, "bonus_percent_error", null);
      }
    });

    if (this.kpi_assignment!.collective.template_id?.length) {
      this.$set(this.kpi_assignment!.collective, "template_id_error", null);
    } else {
      this.$set(
        this.kpi_assignment!.collective,
        "template_id_error",
        "Выберите шаблон"
      );

      errors = true;
      this.collectiveTabError = true;
    }

    if (this.kpi_assignment!.personal.template_id?.length) {
      this.$set(this.kpi_assignment!.personal, "template_id_error", null);
    } else {
      this.$set(
        this.kpi_assignment!.personal,
        "template_id_error",
        "Выберите шаблон"
      );

      errors = true;
      this.personalTabError = true;
    }

    this.kpi_assignment!.users.forEach(u => {
      const sub_level_ids: { [id: string]: boolean } = {};
      this.kpi_assignment!.personal.sub_level_results[u.target_user_id].forEach(
        sub_level => {
          if (sub_level.sub_level_id) {
            if (sub_level_ids[sub_level.sub_level_id]) {
              this.$set(sub_level, "sub_level_error", "Цель уже используется");

              errors = true;
              this.personalTabError = true;
            } else {
              this.$set(sub_level, "sub_level_error", null);

              sub_level_ids[sub_level.sub_level_id] = true;
            }
          } else {
            this.$set(sub_level, "sub_level_error", "Укажите цель");

            errors = true;
            this.personalTabError = true;
          }

          if (sub_level.comment?.length) {
            this.$set(sub_level, "comment_error", null);
          } else {
            this.$set(sub_level, "comment_error", "Введите комментарий");

            errors = true;
            this.personalTabError = true;
          }

          if (sub_level.bonus_percent != null) {
            if (
              this.kpi_personal_user_percent[u.target_user_id] !==
              this.personal_template!.personal_bonus
            ) {
              this.$set(
                sub_level,
                "bonus_percent_error",
                "Процент премии не соответствует целевому значению"
              );

              errors = true;
              this.personalTabError = true;
            } else {
              this.$set(sub_level, "bonus_percent_error", null);
            }
          } else {
            this.$set(
              sub_level,
              "bonus_percent_error",
              "Укажите процент премии"
            );

            errors = true;
            this.personalTabError = true;
          }
        }
      );
    });

    if (errors) {
      console.log(errors);
    } else {
      return this.$api
        .post(kpiRoutes.get_kpi_targets, this.kpi_assignment)
        .then(
          ({
            data: res
          }: {
            data: { list: IKpiTargetUser[]; assignment: IKpiAssignment };
          }) => {
            this.kpi_users = res.list;
            this.kpi_assignment = res.assignment;

            this.changeKpiAssignment();

            this.changeSearch();

            this.hideAssignmentModal();
          }
        );
    }
  }

  protected allowWrite() {
    return this.$api.allowWrite();
  }

  protected changeSubLevelChecked(sub_level: IKpiAssignmentCollectiveSubLevel) {
    if (sub_level.is_selected) {
      if (this.kpi_assignment?.users?.length) {
        sub_level.target_user_ids = pluck(
          this.kpi_assignment.users,
          "target_user_id"
        );
      }
    } else {
      sub_level.target_user_ids = [];
    }
  }

  protected changeSubLevelUserIds(sub_level: IKpiAssignmentCollectiveSubLevel) {
    if (sub_level.target_user_ids.length) {
      sub_level.is_selected = true;
    } else {
      sub_level.is_selected = false;
    }
  }

  protected get selectedCollectiveSubLevelsBonus() {
    let bonus = 0;

    if (this.kpi_assignment?.collective?.sub_levels?.length) {
      this.kpi_assignment.collective.sub_levels.forEach(sub_level => {
        if (sub_level.is_selected && sub_level.bonus_percent) {
          if (typeof sub_level.bonus_percent === "string") {
            bonus += parseInt(sub_level.bonus_percent, 10);
          } else {
            bonus += sub_level.bonus_percent;
          }
        }
      });
    }

    return bonus;
  }
}
