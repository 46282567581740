
import { ref, Ref } from "vue";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import CPeriodDatePicker from "@/components/PeriodDatePicker.vue";
import CInfiniteScroll from "@/components/InfiniteScroll.vue";

import akt_routes from "@/api/routes/akt";
import {
  IAktAssignmentPosition,
  IAktAssignmentSetting,
  IAktAssignmentStepType,
  IAktAssignmentWorkplace,
  IAktAssignmentSettingSteps,
  IAktAssignment
} from "@/models/akt/assignment";
import { Debounce } from "@/helpers";
import { toDateString } from "@/helpers/date";

@Component({
  name: "CAktAssignmentsEdit",
  components: {
    CPeriodDatePicker,
    CInfiniteScroll
  }
})
export default class CAktAssignmentsEdit extends Vue {
  @Prop({ required: true }) public readonly show!: boolean;
  @Prop({ required: true }) public readonly selected_year!: number;
  @Prop({ required: true }) public readonly current_year!: number;
  @Prop({ required: true }) public readonly assignment_id!: string | null;
  @Prop({ required: true })
  public readonly available_steps!: IAktAssignmentStepType[];

  public current_user: IJWTUser | null = this.$store.state.currentUser;

  public tabs = ["Название и Этапы", "Рабочие места", "Должности"];
  public current_tab: number = 0;
  public error_tab: number | null = null;

  public error_workplaces: Ref<Set<unknown>> = ref(new Set());

  public disabled: boolean = false || !this.allowWrite();

  public assignment_full_name_error: boolean = false;
  public assignment_full_name: string = "";

  private positions_is_loading: boolean = false;
  private positions_current_page: number = 0;
  private positions_next_page: boolean = true;
  private positions_per_page: number = 100;
  public positions_search: string = "";
  public available_positions: IAktAssignmentPosition[] = [];

  private workplaces_is_loading: boolean = false;
  private workplaces_current_page: number = 0;
  private workplaces_next_page: boolean = true;
  private workplaces_per_page: number = 100;
  public workplaces_search: string = "";
  public workplaces_factory_id: string | null = null;
  public available_workplaces: IAktAssignmentWorkplace[] = [];

  public selected_steps: IAktAssignmentSettingSteps = {};
  public selected_positions: Ref<Set<unknown>> = ref(new Set());
  public selected_workplaces: Ref<Set<unknown>> = ref(new Set());

  private watchers: Function[] = [];

  protected async created() {
    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.current_user = currentUser;
        }
      )
    );

    this.setDefaultSettings();

    await Promise.all([
      this.loadAvailablePositions(),
      this.loadAvailableWorkplaces()
    ]);
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  @Watch("selected_year")
  @Watch("current_year")
  protected changeDisabled() {
    this.disabled =
      this.selected_year != this.current_year || !this.allowWrite();
  }

  @Watch("positions_search")
  @Debounce(500)
  protected async searchPositions() {
    this.available_positions = [];

    await this.loadAvailablePositions();
  }

  public async positionsInfiniteHandler() {
    if (this.positions_next_page && !this.positions_is_loading) {
      await this.loadAvailablePositions(this.positions_current_page + 1);
    }
  }

  private async loadAvailablePositions(page: number = 0) {
    this.positions_is_loading = true;

    return this.$api
      .get(akt_routes.assignment_available_positions, {
        params: {
          page,
          per_page: this.positions_per_page,
          search: this.positions_search
        }
      })
      .then(({ data: res }: { data: IAktAssignmentPosition[] }) => {
        this.available_positions.push(...res);

        this.positions_current_page = page;
        this.positions_next_page = res.length === this.positions_per_page;
      })
      .finally(() => {
        this.positions_is_loading = false;
      });
  }

  public changeSelectedPositions(position_id: string) {
    if (this.selected_positions.value.has(position_id)) {
      this.selected_positions.value.delete(position_id);
    } else {
      this.selected_positions.value.add(position_id);
    }
  }

  @Watch("workplaces_search")
  @Debounce(500)
  protected async searchWorkplaces() {
    this.available_workplaces = [];

    await this.loadAvailableWorkplaces();
  }

  public async workplacesInfiniteHandler() {
    if (this.workplaces_next_page && !this.workplaces_is_loading) {
      await this.loadAvailableWorkplaces(this.workplaces_current_page + 1);
    }
  }

  private async loadAvailableWorkplaces(page: number = 0) {
    this.workplaces_is_loading = true;

    return this.$api
      .get(akt_routes.assignment_available_workplaces, {
        params: {
          page,
          per_page: this.workplaces_per_page,
          search: this.workplaces_search,
          factory_id: this.workplaces_factory_id
        }
      })
      .then(
        ({
          data: res
        }: {
          data: { structures: IAktAssignmentWorkplace[]; next_page: boolean };
        }) => {
          this.available_workplaces.push(...res.structures);

          this.workplaces_current_page = page;
          this.workplaces_next_page = res.next_page;
        }
      )
      .finally(() => {
        this.workplaces_is_loading = false;
      });
  }

  private async loadSettings() {
    if (this.assignment_id) {
      return this.$api
        .get(akt_routes.assignment_settings(this.assignment_id))
        .then(({ data: res }: { data: IAktAssignmentSetting }) => {
          this.assignment_full_name = res.full_name;
          this.selected_steps = res.steps;
          this.selected_positions.value = new Set(res.position_ids);
          this.selected_workplaces.value = new Set(res.workplaces);
        });
    }
  }

  public changeStartsAt(starts_at: string, step_id: string) {
    this.$set(
      this.selected_steps[step_id],
      "starts_at",
      toDateString(starts_at)
    );
  }

  public changeEndsAt(ends_at: string, step_id: string) {
    this.$set(this.selected_steps[step_id], "ends_at", toDateString(ends_at));
  }

  private clearErrors() {
    this.error_tab = null;
    this.assignment_full_name_error = false;
    this.error_workplaces.value = new Set();
  }

  private setDefaultSettings() {
    this.assignment_full_name = "";
    this.selected_positions.value = new Set();
    this.selected_workplaces.value = new Set();
    this.selected_steps = this.available_steps.reduce<{
      [step_type_id: string]: { starts_at: string; ends_at: string };
    }>((acc, curr) => {
      const starts_month =
        curr.default_starts_month < 10
          ? `0${curr.default_starts_month}`
          : curr.default_starts_month;
      const starts_day =
        curr.default_starts_day < 10
          ? `0${curr.default_starts_day}`
          : curr.default_starts_day;
      const ends_month =
        curr.default_ends_month < 10
          ? `0${curr.default_ends_month}`
          : curr.default_ends_month;
      const ends_day =
        curr.default_ends_day < 10
          ? `0${curr.default_ends_day}`
          : curr.default_ends_day;

      let starts_year = new Date().getFullYear();
      const ends_year = starts_year + 1;

      if (curr.position > 1) {
        starts_year += 1;
      }

      acc[curr.id] = {
        starts_at: `${starts_year}-${starts_month}-${starts_day}`,
        ends_at: `${ends_year}-${ends_month}-${ends_day}`
      };

      return acc;
    }, {});
  }

  @Watch("show")
  public async toggleModal() {
    if (this.show) {
      this.current_tab = 0;
      this.clearErrors();

      if (!this.assignment_id) {
        this.setDefaultSettings();
      }

      await this.loadSettings();

      this.openModal();
    } else {
      this.closeModal();
    }
  }

  protected openModal() {
    this.$modal.show("assignments-edit-modal");
  }

  protected closeModal() {
    this.$modal.hide("assignments-edit-modal");
  }

  public async deleteAssignment() {
    if (this.assignment_id) {
      return this.$api
        .destroy(akt_routes.assignment_delete(this.assignment_id))
        .then(() => {
          this.$modal.hide("assignments-delete-modal");

          this.deleted();
        })
        .catch(({ response: res }) => {
          this.$notify({
            group: "notifications",
            type: "error",
            text: res.data.error,
            speed: 500
          });
        });
    }
  }

  public async saveAssignment() {
    this.clearErrors();

    if (!this.assignment_full_name.length) {
      this.assignment_full_name_error = true;
      this.error_tab = 0;

      return;
    }

    let req;

    if (this.assignment_id) {
      req = this.$api
        .put(akt_routes.assignment_update(this.assignment_id), {
          starts_year: this.selected_year,
          full_name: this.assignment_full_name,
          position_ids: Array.from(this.selected_positions.value),
          workplaces: Array.from(this.selected_workplaces.value),
          steps: this.selected_steps
        })
        .then(({ data: res }: { data: IAktAssignment }) => {
          this.changed(res);
        });
    } else {
      req = this.$api
        .post(akt_routes.assignment_create, {
          starts_year: this.selected_year,
          full_name: this.assignment_full_name,
          position_ids: Array.from(this.selected_positions.value),
          workplaces: Array.from(this.selected_workplaces.value),
          steps: this.selected_steps
        })
        .then(({ data: res }: { data: IAktAssignment }) => {
          this.changed(res);
        });
    }

    req.catch(({ response: res }) => {
      if (res.data.exception?.conctated_workplaces_ids?.length) {
        this.error_tab = 1;

        for (const workplace_id of res.data.exception
          .conctated_workplaces_ids) {
          const [factory_id, infinity_id] = workplace_id.split("_");

          if (infinity_id?.length) {
            this.error_workplaces.value.add(factory_id);
          } else {
            this.error_workplaces.value.add(workplace_id);
          }
        }

        this.error_workplaces.value = new Set(this.error_workplaces.value);
      }

      this.$notify({
        group: "notifications",
        type: "error",
        text: res.data.error,
        speed: 500
      });
    });

    return req;
  }

  public workplaceId(workplace: IAktAssignmentWorkplace) {
    if (workplace.infinity_id?.length) {
      return `${workplace.factory_id}_${workplace.infinity_id}`;
    }

    return workplace.factory_id;
  }

  public changeSelectedWorkplaces(workplace: IAktAssignmentWorkplace) {
    const key = this.workplaceId(workplace);

    if (this.selected_workplaces.value.has(key)) {
      this.selected_workplaces.value.delete(key);
    } else {
      this.selected_workplaces.value.add(key);
    }
  }

  public selectedWorkplaceId(workplace: IAktAssignmentWorkplace) {
    return this.selected_workplaces.value.has(this.workplaceId(workplace));
  }

  public errorWorkplaceId(workplace: IAktAssignmentWorkplace) {
    if (this.selectedWorkplaceId(workplace)) {
      return (
        this.error_workplaces.value.has(this.workplaceId(workplace)) ||
        this.error_workplaces.value.has(workplace.factory_id)
      );
    }
  }

  public deleted() {
    this.$emit("deleted");
  }

  public closed() {
    this.$emit("closed");
  }

  public changed(data: IAktAssignment | null = null) {
    this.$emit("changed", data);
  }

  public allowWrite() {
    return this.$api.allowWrite();
  }
}
