import "reflect-metadata";
import {
  IsUUID,
  IsString,
  IsOptional,
  IsEmail,
  Min,
  IsNumber,
  IsPhoneNumber
} from "class-validator";
import { Type } from "class-transformer";

import BlockBlock from "../block/block";
import UserWorkplace from "./workplace";
import RoleRole from "../role/role";
import SkillLearning from "../skill/learning";
import AktAgreement from "../akt/agreement";
import ApplicationCertificate from "@/models/application/certificate";
import ApplicationProtocol from "@/models/application/protocol";
import UserDocument from "@/models/user/document";
import ApplicationPlan from "@/models/application/plan";
import { ERole } from "@/enums/roles";
import SignUser from "@/models/sign/user";

export default class UserUser {
  @IsOptional()
  @IsUUID("4")
  public readonly id!: string;

  @IsString()
  public full_name: string = "";

  @IsString()
  @IsPhoneNumber(null)
  public phone: string = "";

  @IsOptional()
  @IsNumber()
  public status?: number;

  @IsOptional()
  @IsEmail()
  public email?: string;

  @IsOptional()
  @IsEmail()
  public login?: string;

  @IsOptional()
  @IsEmail()
  public certificate_id?: string;

  @Type(() => ApplicationCertificate)
  public certificate?: ApplicationCertificate;

  @IsOptional()
  @IsEmail()
  public protocol_id?: string;

  @Type(() => ApplicationCertificate)
  public protocol?: ApplicationProtocol;

  @IsString()
  public identification_number: string = "";

  @IsOptional()
  @IsString()
  @Min(4)
  public password: string = "";

  @IsOptional()
  @IsString()
  public first_workplace_id?: string;

  @IsOptional()
  @IsString()
  public first_workplace_factory_id?: string;

  @IsOptional()
  public supervisors: UserUser[] = [];

  @IsOptional()
  public functional_supervisors: UserUser[] = [];

  @Type(() => UserDocument)
  public documents: UserDocument[] = [];

  private _is_leader?: boolean;
  public _is_trainer?: boolean;

  public position_name?: string;
  public structure_name?: string;

  public plan_id?: string;

  public calendar_sync: boolean = false;

  @Type(() => BlockBlock)
  public skill_currents?: BlockBlock[];

  @Type(() => BlockBlock)
  public skill_targets?: BlockBlock[];

  @Type(() => SkillLearning)
  public skill_learnings?: SkillLearning[] = [];

  @Type(() => UserWorkplace)
  public workplaces?: UserWorkplace[] = [];

  @Type(() => RoleRole)
  public user_roles?: RoleRole[];

  @Type(() => ApplicationPlan)
  public all_skills?: ApplicationPlan[];
  public unlock?: boolean = false;

  @Type(() => AktAgreement)
  public agreement?: AktAgreement;

  @Type(() => SignUser)
  public signs?: SignUser[];

  constructor(data?: any) {
    if (data) {
      this.id = data.id || this.id;
      this.email = data.email || this.email;
      this.login = data.login || this.login;
      this.phone = data.phone || this.phone;
      this.full_name = data.full_name || this.full_name;
      this.workplaces = data.workplaces || this.workplaces;
      this.identification_number =
        data.identification_number || this.identification_number;
      this.user_roles = data.user_roles || this.user_roles;
      this.certificate_id = data.certificate_id;
      this.protocol_id = data.protocol_id;
      this.skill_learnings = data.skill_learnings || this.skill_learnings;
      this.documents = data.user_document || this.documents;

      this.supervisors = data.supervisors || this.supervisors;
      this.functional_supervisors =
        data.functional_supervisors || this.functional_supervisors;

      if (this.user_roles) {
        this.setTrainerFlag();
      }
    }
  }

  public get is_leader(): boolean {
    if (this._is_leader === undefined) {
      this._is_leader = !!this.user_roles?.find(
        obj => obj.name === ERole.supervisor
      );
    }
    return this._is_leader;
  }

  public set is_leader(value) {
    this._is_leader = value;
  }

  public get is_trainer(): boolean {
    if (this._is_trainer === undefined) {
      this._is_trainer = !!this.user_roles?.find(
        obj => obj.name === ERole.trainer
      );
    }
    return this._is_trainer;
  }

  public set is_trainer(value) {
    this._is_trainer = value;
  }

  public show: boolean = false;

  public setTrainerFlag(): void {
    if (this.is_trainer === undefined) {
      this.is_trainer = !!this.user_roles?.find(
        obj => obj.name === ERole.trainer
      );
    }
  }

  public isHaveEndDocument() {
    return this.end_doc_count > 0;
  }

  @IsNumber()
  public end_doc_count: number = 0;

  public is_check = false;
}
