
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  classToClass,
  plainToClass,
  plainToClassFromExist
} from "class-transformer";

import Preloader from "@/components/Preloader.vue";
import CStructure, { IStructureProp } from "@/components/Structure.vue";
import CSkillRadio from "@/components/SkillRadio.vue";
import { IBlock, ILevel, ILevelOrigin } from "@/models/position/level";
import PositionLocalLevelWorkplace from "@/models/position/level_workplace";
import StructureFactory from "@/models/structure/factory";

import structureRoutes from "@/api/routes/structures";
import positionRoutes from "@/api/routes/positions";
import skillRoutes from "@/api/routes/skills";

import BlockBlock, { IBlockSkill } from "@/models/block/block";
import CLevelSelector from "../../../components/LevelSelector.vue";
import {
  updateSkillCheckboxesState,
  onSelectAllClicked,
  defaultBlocks
} from "../../../helpers/update_skill_checkboxes_state";
import { EThreeStateCheckbox } from "../../../enums/three_state_checkbox";
import SkillCategory from "../../../models/skill/category";
import { onDisplayZeroLevelSkillsChanged } from "../../../helpers/matrix_helper";
import { IRouteMeta } from "@/router/interface";

@Component({
  name: "CPositionLevelEditModal",
  components: {
    Preloader,
    CStructure,
    CSkillRadio,
    CLevelSelector
  },
  methods: {
    updateSkillCheckboxesState,
    onSelectAllClicked,
    onDisplayZeroLevelSkillsChanged
  },
  data: () => {
    return {
      defaultBlocks,
      EThreeStateCheckbox
    };
  }
})
export default class CPositionLevelEditModal extends Vue {
  @Prop({ required: false }) protected workplace_id?: string;

  protected preload: boolean = false;

  protected editLevelWorkplace: PositionLocalLevelWorkplace | null = null;
  protected editLevelWorkplaceError: string | null = null;
  protected deletedLevels: Set<string> = new Set();
  protected createdLevels: Set<string> = new Set();

  public level_origins: ILevelOrigin[] = [];

  protected factories: StructureFactory[] = [];
  protected currentFactory: StructureFactory | null = null;

  protected currentUser: IJWTUser | null = this.$store.state.currentUser;

  protected key: number = 0;

  protected watchers: Function[] = [];

  private currentBlockFilter: BlockBlock[] = [defaultBlocks[0]];
  protected blocksFilter: BlockBlock[][] = [];
  private skillCategoriesFilter: SkillCategory[] = [{ id: 0, value: "Все" }];
  private currentSkillCategoriesFilter: SkillCategory[] = [];
  protected searchSkills: string[] = [];
  /** Состояние чекбокса выбора всех навыков */
  private selectAllSkillsCheckboxState: EThreeStateCheckbox =
    EThreeStateCheckbox.UNCHECK;
  /** Показать навыки с целевым уровнем "0" */
  private displayZeroLevelSkills: boolean[] = [];

  protected async created() {
    this.preload = true;

    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.currentUser = currentUser;
        }
      )
    );

    if (this.workplace_id) {
      await Promise.all([
        this.loadLevelOrigins(),
        this.loadFactories(),
        this.loadWorkplace(this.workplace_id),
        this.loadCategories()
      ]);

      this.currentFactory = this.factories.find(
        f => f.id === this.editLevelWorkplace!.factory_id
      )!;
    } else {
      await Promise.all([
        this.loadLevelOrigins(),
        this.loadFactories(),
        this.loadCategories()
      ]);
      this.editLevelWorkplace = new PositionLocalLevelWorkplace();
    }

    this.preload = false;
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected mounted() {
    this.showEditModal();
  }

  protected async loadFactories() {
    return this.$api
      .get(structureRoutes.factories, {
        params: {
          only_my: true,
          section_id: (this.$router.currentRoute.meta! as IRouteMeta)
            .permissions?.[0]
        }
      })
      .then(({ data: res }: { data: StructureFactory[] }) => {
        this.factories = res;
      })
      .catch(({ response: res }) => {
        this.editLevelWorkplaceError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async loadLevelOrigins() {
    return this.$api
      .get(positionRoutes.levels_origin)
      .then(({ data: res }: { data: ILevelOrigin[] }) => {
        this.level_origins = res;
      });
  }

  protected async loadWorkplace(id: string) {
    return this.$api
      .get(positionRoutes.level(id), { params: { includes: "blocks" } })
      .then(({ data: res }: { data: PositionLocalLevelWorkplace }) => {
        this.editLevelWorkplace = plainToClass(
          PositionLocalLevelWorkplace,
          res
        );

        this.editLevelWorkplace.levels.forEach(level => {
          this.createdLevels.add(level.id!);
        });

        this.blocksFilter = this.editLevelWorkplace.levels.map(
          level => level.blocks
        ) as BlockBlock[][];
        this.currentBlockFilter = Array(
          this.editLevelWorkplace.levels.length
        ).fill(defaultBlocks[0]);
        this.searchSkills = Array(this.editLevelWorkplace.levels.length).fill(
          ""
        );
        this.currentSkillCategoriesFilter = Array(
          this.editLevelWorkplace.levels.length
        ).fill(this.skillCategoriesFilter[0]);

        this.displayZeroLevelSkills = Array(
          this.editLevelWorkplace.levels.length
        ).fill(false);
        this.editLevelWorkplace.levels.forEach(level => {
          level.blocks = onDisplayZeroLevelSkillsChanged(
            level.blocks as IBlock[],
            false
          ) as IBlock[];
        });
      });
  }

  private async loadCategories() {
    return this.$api
      .get(skillRoutes.categories)
      .then(({ data: res }: { data: SkillCategory[] }) => {
        this.skillCategoriesFilter.push(...res);
      });
  }

  protected changeSkillFilter(levelIndex: number) {
    if (!this.editLevelWorkplace) {
      return;
    }
    this.editLevelWorkplace.levels[levelIndex].blocks = [];
    this.blocksFilter[levelIndex].forEach(block => {
      if (
        block.id === this.currentBlockFilter[levelIndex].id ||
        !this.currentBlockFilter[levelIndex].id
      ) {
        const block_copy = plainToClass(BlockBlock, block);
        block_copy.skills = block_copy.skills?.filter(
          skill =>
            (skill.category_id ===
              this.currentSkillCategoriesFilter[levelIndex].id ||
              !this.currentSkillCategoriesFilter[levelIndex].id) &&
            (skill.full_name
              ?.toLowerCase()
              .includes(this.searchSkills[levelIndex].trim()?.toLowerCase()) ||
              !this.searchSkills[levelIndex].trim())
        );
        if (block_copy.skills?.length) {
          this.editLevelWorkplace?.levels[levelIndex].blocks?.push(
            block_copy as IBlock
          );
        }
      }
    });

    this.selectAllSkillsCheckboxState = updateSkillCheckboxesState(
      this.$refs,
      this.editLevelWorkplace.levels[levelIndex].blocks,
      this.selectAllSkillsCheckboxState
    );

    this.editLevelWorkplace.levels[
      levelIndex
    ].blocks = onDisplayZeroLevelSkillsChanged(
      this.editLevelWorkplace?.levels[levelIndex].blocks as IBlock[],
      this.displayZeroLevelSkills[levelIndex]
    ) as IBlock[];
  }

  protected showEditModal() {
    this.$modal.show("editPositionLevel");
  }

  protected hideEditModal() {
    this.$emit("closeModal", { workplace: this.editLevelWorkplace });
    this.$modal.hide("editPositionLevel");
  }

  protected changeFactory() {
    this.editLevelWorkplace!.factory_id = this.currentFactory?.id!;
    this.editLevelWorkplace!.workshop_id = null;
    this.editLevelWorkplace!.workline_id = null;
    this.editLevelWorkplace!.equipment_id = null;
    this.editLevelWorkplace!.infinity_id = null;
  }

  protected setStructure(structure: IStructureProp) {
    this.editLevelWorkplace!.factory_id = structure.factory_id!;
    this.editLevelWorkplace!.workshop_id = structure.workshop_id;
    this.editLevelWorkplace!.workline_id = structure.workline_id;
    this.editLevelWorkplace!.equipment_id = structure.equipment_id;
    this.editLevelWorkplace!.infinity_id = structure.infinity_id;
  }

  protected addLevel() {
    this.editLevelWorkplace!.levels.unshift({
      id: null,
      level_id: null,
      level_name: null,
      position: null,
      introductory_course: false,
      blocks: []
    });

    this.currentBlockFilter.unshift(defaultBlocks[0]);
    this.blocksFilter.unshift([]);
    this.searchSkills.unshift("");
    this.currentSkillCategoriesFilter.unshift(this.skillCategoriesFilter[0]);
    this.displayZeroLevelSkills.unshift(false);
  }

  protected delLevel(index: number) {
    const level = this.editLevelWorkplace!.levels[index];

    if (level.id) {
      this.deletedLevels.add(level.id);
    }

    this.$delete(this.editLevelWorkplace!.levels, index);
    this.$delete(this.currentBlockFilter, index);
    this.$delete(this.blocksFilter, index);
    this.$delete(this.searchSkills, index);
    this.$delete(this.currentSkillCategoriesFilter, index);
    this.$delete(this.displayZeroLevelSkills, index);
  }

  public async changeWorkplace() {
    this.preload = true;
    if (this.editLevelWorkplace!.id) {
      await this.updateWorkplace();
    } else {
      await this.createWorkplace();
    }
  }

  protected async createWorkplace() {
    if (this.checkErrors()) {
      this.preload = false;
      return;
    }

    return this.$api
      .post(positionRoutes.levels, this.editLevelWorkplace)
      .then(({ data: res }: { data: PositionLocalLevelWorkplace }) => {
        this.editLevelWorkplace = plainToClass(
          PositionLocalLevelWorkplace,
          res
        );

        this.$emit("createWorkplace", { workplace: this.editLevelWorkplace });
        this.hideEditModal();
      })
      .catch(({ response: res }) => {
        this.editLevelWorkplaceError = res.data.error;
        this.preload = false;
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async updateWorkplace() {
    if (this.checkErrors(true)) {
      this.preload = false;
      return;
    }

    return this.$api
      .put(
        positionRoutes.level(this.editLevelWorkplace!.id),
        this.editLevelWorkplace
      )
      .then(({ data: res }: { data: PositionLocalLevelWorkplace }) => {
        this.editLevelWorkplace = plainToClass(
          PositionLocalLevelWorkplace,
          res
        );

        const currentLevels = this.editLevelWorkplace.levels.reduce(
          (acc, curr) => {
            acc.add(curr.id!);

            return acc;
          },
          new Set()
        );

        Array.from(this.createdLevels).forEach(l => {
          if (!currentLevels.has(l)) {
            this.deletedLevels.add(l);
          }
        });

        this.editLevelWorkplace.deletedLevels = Array.from(this.deletedLevels);

        this.$emit("updateWorkplace", { workplace: this.editLevelWorkplace });
        this.hideEditModal();
      })
      .catch(({ response: res }) => {
        this.editLevelWorkplaceError = res.data.error;
        this.preload = false;
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected checkErrors(from_update: boolean = false) {
    const level_ids: Set<string> = new Set();
    const positions: Set<number> = new Set();

    let invalidLevelIds = false;
    let invalidPositions = false;
    let invalidStructure = false;
    let invalidLevels = false;

    if (!this.editLevelWorkplace!.factory_id) {
      this.editLevelWorkplace!.factory_id_error = "Укажите рабочее место";

      invalidStructure = true;
    } else {
      this.editLevelWorkplace!.factory_id_error = null;
    }

    if (!from_update && !this.editLevelWorkplace!.levels.length) {
      this.editLevelWorkplace!.levels_error =
        "Добавьте хоть один уровень развития";

      invalidLevels = true;
    } else {
      this.editLevelWorkplace!.levels_error = null;
    }

    this.editLevelWorkplace!.levels?.forEach(level => {
      if (level.level_id) {
        if (level_ids.has(level.level_id)) {
          level.level_id_error = "Уровень уже используется";

          invalidLevelIds = true;
        } else {
          level_ids.add(level.id!);

          level.level_id_error = null;
        }
      } else {
        level.level_id_error = "Укажите уровень";

        invalidLevelIds = true;
      }

      if (level.position != null) {
        if (positions.has(level.position)) {
          level.position_error = "Позиция уже используется";

          invalidPositions = true;
        } else {
          positions.add(level.position);

          level.position_error = null;
        }
      } else {
        level.position_error = "Укажите позицию";

        invalidPositions = true;
      }
    });

    const errors =
      invalidLevelIds || invalidPositions || invalidStructure || invalidLevels;

    if (errors) {
      this.key++;
    }

    return errors;
  }

  protected closeAllBlocks(level_index: number) {
    if (this.editLevelWorkplace?.levels?.[level_index]) {
      const div = this.$refs[`container_${level_index}`] as HTMLDivElement[];

      let close: boolean = true;
      close = div[0].classList.contains("close-all-sublevels");
      if (close) {
        div[0].classList.remove("close-all-sublevels");
        this.editLevelWorkplace?.levels[level_index].blocks?.forEach(
          (block, block_index) => {
            const el = this.$refs[
              `block_${level_index}_${block.id}`
            ] as HTMLDivElement[];
            el[0].classList.remove("close");
          }
        );
      } else {
        div[0].classList.add("close-all-sublevels");
        this.editLevelWorkplace?.levels[level_index].blocks?.forEach(
          (block, block_index) => {
            const el = this.$refs[
              `block_${level_index}_${block.id}`
            ] as HTMLDivElement[];
            el[0].classList.add("close");
          }
        );
      }
    }
  }

  protected changeLevel(
    skill: IBlockSkill,
    blockIndex: number,
    skillIndex: number,
    levelIndex: number
  ) {
    this.$set(
      this.editLevelWorkplace!.levels[levelIndex]!.blocks![blockIndex]!.skills!,
      skillIndex,
      skill
    );
  }

  protected async changeOriginLevel(level: ILevel, level_index: number) {
    this.currentBlockFilter[level_index] = defaultBlocks[0];
    this.currentSkillCategoriesFilter[
      level_index
    ] = this.skillCategoriesFilter[0];
    this.searchSkills[level_index] = "";
    this.selectAllSkillsCheckboxState = EThreeStateCheckbox.UNCHECK;

    if (level.level_id) {
      const { data: res }: { data: IBlock[] } = await this.$api.get(
        positionRoutes.level_blocks(level.level_id),
        {
          params: {
            level_local_id: level.id,
            factory_id: this.editLevelWorkplace!.factory_id,
            infinity_id: this.editLevelWorkplace!.infinity_id
          }
        }
      );

      this.$set(level, "blocks", res);
    } else {
      this.$set(level, "blocks", null);
    }
    this.blocksFilter[level_index] = plainToClassFromExist(
      this.blocksFilter[level_index],
      level.blocks
    );

    level.blocks = onDisplayZeroLevelSkillsChanged(
      level.blocks as IBlock[],
      this.displayZeroLevelSkills[level_index]
    ) as IBlock[];
  }

  public decPosition(level: ILevel) {
    if (level.position == null) {
      level.position = 0;
    }

    level.position <= 0 ? 0 : level.position--;
  }

  public incPosition(level: ILevel) {
    if (level.position == null) {
      level.position = 0;
    } else {
      level.position >= this.level_origins.length - 1
        ? this.level_origins.length - 1
        : level.position++;
    }
  }

  public changeIntroductoryCourse(level_index: number) {
    if (!this.editLevelWorkplace) {
      return;
    }

    this.editLevelWorkplace.levels.forEach((level, idx) => {
      if (idx !== level_index) {
        this.$set(level, "introductory_course", false);
      }
    });
  }

  public allowWrite() {
    return this.$api.allowWrite();
  }
}
