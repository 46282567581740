
import { Component, Vue, Prop } from "vue-property-decorator";

import structure_routes from "@/api/routes/structures";
import program_routes from "@/api/routes/programs";
import skill_routes from "@/api/routes/skills";

import CPaginatedSelect from "@/components/Select.vue";
import CStructure, { IStructureProp } from "@/components/Structure.vue";

import StructureFactory from "@/models/structure/factory";
import SkillLocal from "@/models/skill/local";
import SkillCategory from "@/models/skill/category";
import { IRouteMeta } from "@/router/interface";

interface ISkillFilterData {
  readonly id?: string;
  readonly full_name?: string;
}

interface ISkillData {
  readonly current_page: number;
  readonly next_page: boolean;
  readonly skills: SkillLocal[];
}

@Component({
  name: "CProgramCopy",
  components: {
    CStructure,
    CPaginatedSelect
  }
})
export default class CProgramCopy extends Vue {
  @Prop({ required: true }) private program_id!: string;
  @Prop({ required: true }) private current_factory_id!: string;

  public factories: StructureFactory[] = [];
  public factory: StructureFactory | null = null;
  public structure: IStructureProp = {};

  public skill_categories: SkillCategory[] = [];
  public skill_category: SkillCategory | null = null;

  public skill: ISkillFilterData = {};
  private reloadSkill?: Function;

  private per_page: number = 50;
  private current_page: number = 0;
  private next_page: boolean = false;

  public copy_errors: string | null = null;

  protected async created() {
    await Promise.all([this.loadFactories(), this.loadSkillCategories()]);
  }

  private async loadFactories() {
    return this.$api
      .get(structure_routes.factories, {
        params: {
          only_my: true,
          section_id: (this.$router.currentRoute.meta! as IRouteMeta)
            .permissions?.[0]
        }
      })
      .then(async ({ data: res }: { data: StructureFactory[] }) => {
        this.factories = res;

        this.factory = this.factories.find(
          f => f.id === this.current_factory_id
        )!;

        await this.changeFactory();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private async loadSkillCategories() {
    return this.$api
      .get(skill_routes.categories)
      .then(({ data: res }: { data: SkillCategory[] }) => {
        this.skill_categories = res;

        this.skill_category = this.skill_categories[0];
      });
  }

  public async loadSkills(page: number = 0) {
    return this.$api
      .get(program_routes.available_skills(this.factory!.id!), {
        params: {
          page,
          per_page: this.per_page,
          paginate: true,
          category_id: this.skill_category?.id || null,
          infinity_id: this.structure?.infinity_id || null
        }
      })
      .then(({ data: res }: { data: ISkillData }) => {
        if (this.current_page !== res.current_page || this.current_page === 0) {
          this.current_page = res.current_page;

          this.next_page = res.next_page;

          if (res.skills.length) {
            return res.skills;
          } else {
            return [];
          }
        }

        return [];
      });
  }

  public async setReloadSkill(reload: Function) {
    this.reloadSkill = reload;
  }

  public async changeFactory() {
    this.factory = this.factory ?? this.factories[0];
    this.structure = {};

    this.skill = {};
    this.copy_errors = null;

    await this.reloadSkill?.call([]);
  }

  public async changeStructure(structure: IStructureProp) {
    this.structure = structure;

    this.skill = {};
    this.copy_errors = null;

    await this.reloadSkill?.call([]);
  }

  public async changeSkillCategory() {
    this.skill_category = this.skill_category ?? this.skill_categories[0];

    this.skill = {};
    this.copy_errors = null;

    await this.reloadSkill?.call([]);
  }

  public showCopyModal() {
    this.$modal.show("copy_program_modal");
  }

  public hideCopyModal() {
    this.$modal.hide("copy_program_modal");
  }

  public async copyProgram() {
    if (!this.skill.id) {
      this.copy_errors = "Необходимо выбрать навык.";

      return;
    }

    return this.$api
      .post(program_routes.copy(this.program_id), {
        factory_id: this.factory!.id,
        program_id: this.program_id,
        skill_id: this.skill!.id
      })
      .then(() => {
        this.hideCopyModal();

        this.$notify({
          group: "notifications",
          type: "success",
          text: "Копирование программы прошло успешно",
          speed: 500
        });
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }
}
