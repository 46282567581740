
import { Vue, Component, Prop } from "vue-property-decorator";

import CInfiniteScroll from "@/components/InfiniteScroll.vue";

import userRoutes from "@/api/routes/users";

import UserUser from "@/models/user/user";
import programRoutes from "@/api/routes/programs";
import ProgramInspector from "@/models/program/inspector";
import { EAdmissionPermission } from "@/models/admission/permission";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
  readonly users: UserUser[];
}

@Component({
  components: {
    CInfiniteScroll
  }
})
export default class ConstructorProgramsAddInspectors extends Vue {
  @Prop({ required: true }) level!: number;
  @Prop({ required: true }) program_id!: string;
  @Prop({ required: true }) program_version_id!: string;

  protected preload_infinite: boolean = false;

  protected search: string = "";

  protected per_page: number = 100;
  protected current_page: number = 0;
  protected next_page: boolean = false;

  protected selectedUsers: Set<string> = new Set();

  private currentUser: IJWTUser | null = this.$store.state.currentUser;

  protected users: UserUser[] = [];

  protected watchers: Function[] = [];

  protected async created() {
    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.currentUser = currentUser;
        }
      )
    );

    await this.clearUsers();
  }

  protected async clearUsers() {
    this.current_page = 0;
    this.users = [];
    await this.loadUsers();
  }

  protected async infiniteHandler() {
    if (this.next_page && !this.preload_infinite) {
      await this.loadUsers(this.current_page + 1);
    }
  }

  protected async loadUsers(page: number = 0) {
    this.preload_infinite = true;

    let only_my = true;

    if (this.currentUser?.is_admin) {
      only_my = false;
    }

    return this.$api
      .get(userRoutes.users, {
        params: {
          page,
          per_page: this.per_page,
          search: this.search,
          paginate: true,
          level: this.level,
          program_id: this.program_id,
          exclude_inspectors: true,
          include_position: true,
          only_my,
          permission_id: EAdmissionPermission.EDUCATION_PROGRAMS
        }
      })
      .then(({ data: res }: { data: IData }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;
        this.users.push(...res.users);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      })
      .finally(() => {
        this.preload_infinite = false;
      });
  }

  protected changeCheckedUsers(id: string) {
    if (this.selectedUsers.has(id)) {
      this.selectedUsers.delete(id);
    } else {
      this.selectedUsers.add(id);
    }

    // Set не реактивен во 2-рой версии вью.
    this.selectedUsers = new Set(this.selectedUsers);
  }

  protected async addUsers() {
    await this.$api
      .post(programRoutes.inspectors, {
        inspectors: Array.from(this.selectedUsers),
        level: this.level,
        program_id: this.program_id,
        program_version_id: this.program_version_id
      })
      .then(({ data: res }: { data: ProgramInspector[] }) => {
        this.changesExist();
        this.$emit("input", res);
        this.$emit("close", true);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          duration: 500,
          speed: 500
        });
      });
  }

  protected async changesExist() {
    if (!this.program_version_id) {
      return;
    }

    return this.$api
      .put(programRoutes.changes_exist(this.program_version_id!), {})
      .then(() => {
        this.$emit("changesExist", true);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          duration: 500,
          speed: 500
        });
      });
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }
}
