
import { Component, Vue } from "vue-property-decorator";
import { plainToClass } from "class-transformer";

import { users_routes } from "@/router/users";

import akt_routes from "@/api/routes/akt";
import task_routes from "@/api/routes/tasks";
import subscription_routes from "@/api/routes/subscriptions";

import { ONE_DAY_IN_MILISECONDS } from "@/helpers/date";
import { IEvent } from "@/helpers/calendar_helper";

import Preloader from "@/components/Preloader.vue";
import CPlaceholder from "@/components/Placeholder.vue";
import CDashboardPlanItem from "@/components/dashboard/DashboardPlanItem.vue";
import CDashboardExaminationItem, {
  EDataType
} from "@/components/dashboard/DashboardExaminationItem.vue";
import CDashboardSkillsItem from "@/components/dashboard/DashboardSkillsItem.vue";
import CDashboardCalendarItem from "@/components/dashboard/DashboardCalendarItem.vue";
import CDashboardProgressLine from "@/components/dashboard/DashboardProgressLine.vue";
import CDashboardHeads from "@/components/dashboard/DashboardHeads.vue";

import { IDataProgress } from "@/views/user/Catalog.vue";
import { ITaskData } from "@/views/user/examination/Tasks.vue";

import {
  IDataPrograms,
  IStudentProgramsData
} from "@/models/subscriptions/program";
import {
  AssignmentUserWorkplace,
  EAktAssignmentUserWorkplaceType
} from "@/models/akt/assignment_user_workplace";
import { AssignmentUser } from "@/models/akt/assignment_user";

import userApiRoutes from "@/api/routes/users";
import { IDataAssignment } from "@/views/user/examination/Testings.vue";
import TestingAssignment from "@/models/testing/assignment";
import TaskTask from "@/models/task/task";
import { IUserWorkplace } from "@/models/user/workplace";
import { IRouteConfig } from "@/router/interface";

@Component({
  components: {
    CDashboardHeads,
    Preloader,
    CDashboardPlanItem,
    CDashboardExaminationItem,
    CDashboardSkillsItem,
    CDashboardCalendarItem,
    CDashboardProgressLine,
    CPlaceholder
  }
})
export default class NewDashboard extends Vue {
  private current_user: IJWTUser | null = this.$store.state.currentUser;

  private watchers: Function[] = [];

  public EDataType = EDataType;
  public preload: boolean = false;

  public plan_link?: IRouteConfig | null = null;
  public calendar_link?: IRouteConfig | null = null;
  public skills_link?: IRouteConfig | null = null;
  public examination_link?: IRouteConfig | null = null;

  public programs: IStudentProgramsData[] = [];
  public program_progress: IDataProgress[] = [
    { id: 2, name: "Немного отстает", clazz: "font-orange" },
    { id: 4, name: "Успевает", clazz: "font-blue" },
    { id: 3, name: "Сильно отстает по активным программам", clazz: "font-red" }
  ];
  public current_program_progress: IDataProgress = this.program_progress[1];

  public available_workplaces: AssignmentUserWorkplace[] = [];

  public test_assignments: TestingAssignment[] = [];

  public tasks: TaskTask[] = [];

  public calendar_events: IEvent[] = [];

  public workplaces: IUserWorkplace[] = [];
  public current_workplace: IUserWorkplace | null = null;

  protected async created() {
    this.plan_link = users_routes.find(child => child.name === "me_catalog");
    this.calendar_link = users_routes.find(child => child.name === "calendar");
    this.skills_link = users_routes.find(child => child.name === "me_skills");
    this.examination_link = users_routes.find(
      child => child.name === "me_examination"
    );

    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        async current_user => {
          this.current_user = current_user;

          await this.loadAll();
        }
      )
    );

    await this.loadAll();
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  private async loadAll() {
    if (!this.preload && this.current_user?.id) {
      this.preload = true;

      await Promise.all([
        this.loadWorkplaces(),
        this.getProgress(),
        this.loadPrograms(),
        this.loadAktAssignment(),
        this.loadTestAssignments(),
        this.getUserTasks(),
        this.loadCalendarEvents()
      ]).finally(() => {
        this.preload = false;
      });
    }
  }

  public changeWorkplace() {
    if (this.current_workplace == null) {
      this.current_workplace =
        this.workplaces.find(w => w.is_main) || this.workplaces[0];
    }
  }

  private async getProgress() {
    return this.$api
      .get(subscription_routes.progress, {
        params: {
          id: this.current_user!.id
        }
      })
      .then(({ data: res }: { data: { progress_status: number } }) => {
        this.current_program_progress = this.program_progress.find(
          i => i.id === res.progress_status
        )!;
      });
  }

  private async loadPrograms() {
    return this.$api
      .get(subscription_routes.assignment_programs(this.current_user!.id), {
        params: {
          page: 0,
          per_page: 6,
          paginate: true
        }
      })
      .then(({ data: res }: { data: IDataPrograms }) => {
        this.programs.push(...res.programs);
      });
  }

  private async loadAktAssignment() {
    return this.$api
      .get(akt_routes.assignments(this.current_user!.id!), {
        params: {
          include_workplaces: true,
          starts_year: new Date().getFullYear(),
          only_active: true,
          type_id: EAktAssignmentUserWorkplaceType.ONLINE
        }
      })
      .then(({ data: res }: { data: AssignmentUser[] }) => {
        if (Object.keys(res).length) {
          const assignment_users = plainToClass(AssignmentUser, res);

          assignment_users.forEach(assignment_user => {
            assignment_user.workplaces.forEach(workplace => {
              if (!workplace.attempt_starts_at) {
                workplace.attempt_starts_at = assignment_user.starts_at;
              }

              if (!workplace.attempt_ends_at) {
                workplace.attempt_ends_at = assignment_user.ends_at;
              }

              if (workplace.isNotPassed) {
                this.available_workplaces.push(workplace);
              }
            });
          });
        }
      });
  }

  private async loadWorkplaces() {
    return this.$api
      .get(userApiRoutes.workplacesMe)
      .then(({ data: res }: { data: Array<IUserWorkplace> }) => {
        this.workplaces = res;

        this.current_workplace =
          this.workplaces.find(w => w.is_main) || this.workplaces[0];
      });
  }

  private async loadTestAssignments() {
    this.$api
      .get(userApiRoutes.testing_assignments(this.current_user!.id!), {
        params: {
          page: 0,
          per_page: 3,
          paginate: true
        }
      })
      .then(({ data: res }: { data: IDataAssignment }) => {
        this.test_assignments.push(...res.assignments);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private async getUserTasks() {
    return this.$api
      .get(task_routes.tasks, {
        params: {
          page: 0,
          per_page: 3,
          paginate: true,
          user_id: this.current_user!.id!
        }
      })
      .then(({ data: res }: { data: ITaskData }) => {
        this.tasks.push(...res.tasks);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private async loadCalendarEvents() {
    this.$api
      .get(userApiRoutes.calendar(this.current_user!.id!), {
        params: { today_only: true }
      })
      .then(({ data: res }: { data: IEvent[] }) => {
        this.calendar_events = res.map((e, index) => {
          e.starts_at = new Date(e.starts_at);
          e.ends_at = new Date(e.ends_at);

          e.start = new Date(e.starts_at);
          e.end = new Date(e.ends_at);
          e.index = index;
          e.timed = !(
            Math.floor(
              (e.ends_at.getTime() - e.starts_at.getTime()) /
                ONE_DAY_IN_MILISECONDS
            ) > 0
          );
          return e;
        });
      });
  }
}
