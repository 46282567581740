
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import {
  classToClass,
  plainToClass,
  plainToClassFromExist
} from "class-transformer";

import Workshop from "./Workshop.vue";
import Preloader from "@/components/Preloader.vue";
import CSkillRadio from "@/components/SkillRadio.vue";

import structureRoutes from "@/api/routes/structures";
import skillRoutes from "@/api/routes/skills";

import StructureWorkshop from "@/models/structure/workshop";
import StructureFactory from "@/models/structure/factory";
import InventoryInventory from "@/models/inventory/inventory";
import BlockBlock, { IBlockSkill } from "@/models/block/block";
import { EThreeStateCheckbox } from "@/enums/three_state_checkbox";
import CLevelSelector from "../../components/LevelSelector.vue";
import {
  updateSkillCheckboxesState,
  onSelectAllClicked,
  defaultBlocks
} from "@/helpers/update_skill_checkboxes_state";
import SkillCategory from "../../models/skill/category";
import { onDisplayZeroLevelSkillsChanged } from "../../helpers/matrix_helper";

@Component({
  components: {
    Workshop,
    Preloader,
    CSkillRadio,
    CLevelSelector
  },
  methods: {
    updateSkillCheckboxesState,
    onSelectAllClicked,
    onDisplayZeroLevelSkillsChanged
  },
  data: () => {
    return {
      defaultBlocks,
      EThreeStateCheckbox
    };
  }
})
export default class FactoryUec extends Vue {
  @Prop({ required: true }) protected factory!: StructureFactory;
  @Prop({ required: true }) protected inventories!: InventoryInventory[];
  @Prop({ required: true }) protected currentUser!: IJWTUser | null;

  protected preload: boolean = false;
  protected saveLoader: boolean = false;
  protected toggleSkillBlocks: boolean = false;

  protected workshops: StructureWorkshop[] = [];

  protected editWorkshop: StructureWorkshop = new StructureWorkshop();
  protected editWorkshopIndex: number | null = null;
  protected editWorkshopMatrix: BlockBlock[] = [];
  protected editWorkshopErrors: string | string[] | null = null;

  protected editInventory: InventoryInventory | null = null;

  private currentBlockFilter: BlockBlock = defaultBlocks[0];
  protected blocksFilter: BlockBlock[] = [];
  private skillCategoriesFilter: SkillCategory[] = [{ id: 0, value: "Все" }];
  private currentSkillCategoriesFilter: SkillCategory = this
    .skillCategoriesFilter[0];
  protected searchSkills: string = "";
  /** Состояние чекбокса выбора всех навыков */
  private selectAllSkillsCheckboxState: EThreeStateCheckbox =
    EThreeStateCheckbox.UNCHECK;
  /** Показать навыки с целевым уровнем "0" */
  private displayZeroLevelSkills = false;

  protected async created() {
    this.loadCategories();
    if (this.factory?.id) {
      await this.loadFactory();
      await this.loadWorkshops();
    }
  }

  @Watch("factory")
  protected async loadWorkshops() {
    if (this.preload) {
      return;
    }

    this.preload = true;

    return this.$api
      .get(structureRoutes.workshops, {
        params: { factory_id: this.factory!.id }
      })
      .then(({ data: res }: { data: StructureWorkshop[] }) => {
        res.forEach(r => (r.is_show = false));

        this.workshops = res;

        this.preload = false;
      });
  }

  protected async loadFactory() {
    return this.$api
      .get(structureRoutes.factory(this.factory!.id!), {
        params: {
          matrix: true,
          inventory_id: this.factory!.inventory_id
        }
      })
      .then(({ data: res }: { data: StructureFactory }) => {
        this.factory.blocks = res.blocks;
      });
  }

  private loadCategories() {
    this.$api
      .get(skillRoutes.categories)
      .then(({ data: res }: { data: SkillCategory[] }) => {
        this.skillCategoriesFilter.push(...res);
      });
  }

  protected changeSkillFilter() {
    this.editWorkshop.blocks = [];
    this.blocksFilter.forEach(block => {
      if (
        block.id === this.currentBlockFilter.id ||
        !this.currentBlockFilter.id
      ) {
        const block_copy = plainToClass(BlockBlock, block);
        block_copy.skills = block_copy.skills?.filter(
          skill =>
            (skill.category_id === this.currentSkillCategoriesFilter.id ||
              !this.currentSkillCategoriesFilter.id) &&
            (skill.full_name
              ?.toLowerCase()
              .includes(this.searchSkills.trim()?.toLowerCase()) ||
              !this.searchSkills.trim())
        );
        if (block_copy.skills?.length) {
          this.editWorkshop.blocks?.push(block_copy);
        }
      }
    });

    this.selectAllSkillsCheckboxState = updateSkillCheckboxesState(
      this.$refs,
      this.editWorkshop.blocks,
      this.selectAllSkillsCheckboxState
    );

    this.editWorkshop.blocks = onDisplayZeroLevelSkillsChanged(
      this.editWorkshop.blocks || [],
      this.displayZeroLevelSkills
    );
  }

  protected toggleWorkshop(index: number) {
    const workshop = this.workshops[index];

    workshop.is_show = !workshop.is_show;
  }

  protected changeLevel(
    skill: IBlockSkill,
    blockIndex: number,
    skillIndex: number
  ) {
    this.$set(
      this.editWorkshop!.blocks![blockIndex]!.skills!,
      skillIndex,
      skill
    );
  }

  protected showModal() {
    this.displayZeroLevelSkills = false;
    this.$modal.show("editWorkshop");
  }

  protected hideModal() {
    this.$modal.hide("editWorkshop");
  }

  protected async loadWorkshop(
    id: string,
    inventory_id: string,
    update_full: boolean = true
  ) {
    this.currentBlockFilter = defaultBlocks[0];
    this.currentSkillCategoriesFilter = this.skillCategoriesFilter[0];
    this.searchSkills = "";
    this.selectAllSkillsCheckboxState = EThreeStateCheckbox.UNCHECK;

    return this.$api
      .get(structureRoutes.workshop(id), {
        params: {
          matrix: true,
          factory_id: this.factory.id,
          inventory_id
        }
      })
      .then(({ data: res }: { data: StructureWorkshop }) => {
        if (update_full) {
          this.editWorkshop = res;
          this.editWorkshopMatrix =
            classToClass(this.editWorkshop.blocks) || [];
        } else {
          this.editWorkshop.blocks = res.blocks;
        }

        this.blocksFilter = plainToClassFromExist(
          this.blocksFilter,
          res.blocks
        );

        this.editWorkshop.blocks = onDisplayZeroLevelSkillsChanged(
          this.editWorkshop.blocks || [],
          this.displayZeroLevelSkills
        );
      });
  }

  protected async changeInventory(obj: InventoryInventory) {
    await this.loadWorkshop(this.editWorkshop!.id! || "new", obj.id!, false);
  }

  protected async beforeCreateWorkshop() {
    this.editWorkshopIndex = null;
    this.editWorkshopErrors = null;
    this.editInventory = null;

    await this.loadFactory();

    await this.loadWorkshop("new", "");

    this.editWorkshop.factory_id = this.factory!.id!;

    this.showModal();
  }

  protected async createWorkshop() {
    this.editWorkshop.inventory_id = this.editInventory?.id || null;
    this.saveLoader = true;

    return this.$api
      .post(structureRoutes.workshops, this.editWorkshop)
      .then(({ data: res }: { data: StructureWorkshop }) => {
        this.workshops.push({ ...res, ...{ is_show: false } });

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.editWorkshopErrors = res.data.error;
      })
      .finally(() => {
        this.saveLoader = false;
      });
  }

  protected async beforeUpdateWorkshop(index: number) {
    await this.loadFactory();

    const workshop = this.workshops[index];
    this.editWorkshopIndex = index;
    this.editWorkshopErrors = null;
    this.editInventory = this.inventories.find(
      i => i.id === workshop.inventory_id
    )!;

    await this.loadWorkshop(workshop.id!, workshop.inventory_id!);

    this.showModal();
  }

  protected async updateWorkshop() {
    this.editWorkshop.inventory_id = this.editInventory?.id || null;
    this.saveLoader = true;

    return this.$api
      .put(structureRoutes.workshop(this.editWorkshop.id!), this.editWorkshop)
      .then(({ data: res }: { data: StructureWorkshop }) => {
        const workshop = this.workshops[this.editWorkshopIndex!];

        workshop.full_name = res.full_name;
        workshop.inventory_id = res.inventory_id;

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.editWorkshopErrors = res.data.error;
      })
      .finally(() => {
        this.saveLoader = false;
      });
  }

  protected async destroyWorkshop() {
    this.saveLoader = true;
    return this.$api
      .destroy(structureRoutes.workshop(this.editWorkshop.id!))
      .then(() => {
        this.$delete(this.workshops, this.editWorkshopIndex!);
        this.$modal.hide("destroyWorkshopModal");
        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.editWorkshopErrors = res.data.error;
      })
      .finally(() => {
        this.saveLoader = false;
      });
  }

  public allowWrite() {
    return this.$api.allowWrite();
  }
}
