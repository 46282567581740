
import { Component, Vue, Prop } from "vue-property-decorator";
import { AssignmentUserWorkplace } from "@/models/akt/assignment_user_workplace";
import TestingAssignment from "@/models/testing/assignment";
import TaskTask from "@/models/task/task";
import { examinations_child_list_routes } from "@/router/users/examinations";
import {
  normalizedAssignmentUserWorkplaceResultStatusColor,
  normalizedAssignmentUserWorplaceResultStatus
} from "@/filters/akt";
import {
  normalizedTestingAssignmentStatus,
  normalizedTestingAssignmentStatusColor
} from "@/filters/testing";
import {
  normalizedTaskStatus,
  normalizedTaskStatusIcon,
  normalizedTaskStatusColor
} from "@/filters/task";
import akt_routes from "@/api/routes/akt";

interface IItemData {
  id: string;
  start_date?: Date | string;
  end_date?: Date | string;
  text: string;
  status: string;
  status_icon: string;
  status_color: string;
}

interface IHeaderInfo {
  name: string;
  link: {
    title: string;
    to: { name: string };
  };
}

export enum EDataType {
  AKT = "me_akt",
  TEST = "me_testings",
  TASK = "me_tasks"
}

@Component({ name: "CDashboardExaminationItem" })
export default class CDashboardExaminationItem extends Vue {
  @Prop({ required: true }) protected data!:
    | AssignmentUserWorkplace[]
    | TestingAssignment[]
    | TaskTask[];
  @Prop({ required: true }) public data_type!: EDataType;

  private link = examinations_child_list_routes.find(
    r => r.name === this.data_type
  );

  public header_name: string = examinations_child_list_routes.find(
    r => r.name === this.data_type
  )!.meta!.name;

  public item_data: IItemData[] = [];

  protected created() {
    this.prepareData();
  }

  protected prepareData() {
    if (this.data.length) {
      switch (this.data_type) {
        case EDataType.AKT:
          this.item_data = this.prepareAkt(
            this.data as AssignmentUserWorkplace[]
          );
          break;
        case EDataType.TEST:
          this.item_data = this.prepareTest(this.data as TestingAssignment[]);
          break;
        case EDataType.TASK:
          this.item_data = this.prepareTask(this.data as TaskTask[]);
          break;
        default:
          break;
      }
    }
  }

  protected prepareAkt(data: AssignmentUserWorkplace[]): IItemData[] {
    return data.map(item => {
      return {
        id: item.id,
        start_date: item.attempt_starts_at,
        end_date: item.attempt_ends_at,
        text: item.template_title!,
        status: normalizedAssignmentUserWorplaceResultStatus(
          item.result_status_id
        ),
        status_icon:
          "/img/icon-status-result-" + item.result_status_id + ".svg",
        status_color: normalizedAssignmentUserWorkplaceResultStatusColor(
          item.result_status_id
        )
      };
    });
  }

  protected prepareTest(data: TestingAssignment[]): IItemData[] {
    return data.map(item => {
      return {
        id: item.id,
        end_date: item.ends_at,
        text: item.testing_version.name,
        status: normalizedTestingAssignmentStatus(item.status),
        status_icon: "/img/icon-status-assignment-" + item.status + ".svg",
        status_color: normalizedTestingAssignmentStatusColor(item.status)
      };
    });
  }

  protected prepareTask(data: TaskTask[]): IItemData[] {
    return data.map(item => {
      return {
        id: item.id,
        end_date: item.ends_at,
        text: item.description,
        status: normalizedTaskStatus(item.status),
        status_icon: normalizedTaskStatusIcon(item.status),
        status_color: normalizedTaskStatusColor(item.status)
      };
    });
  }

  public async showExamination(id: string, data_type: EDataType) {
    switch (data_type) {
      case EDataType.AKT:
        return await this.startAkt(id);
      case EDataType.TEST:
        return this.$router.push({ name: "user_pass_testing", params: { id } });
      case EDataType.TASK:
        return this.$router.push({
          name: "examination_task",
          params: { task_id: id }
        });
      default:
        return;
    }
  }

  private async startAkt(id: string) {
    return this.$api
      .put(akt_routes.put_workplace_start(id), {})
      .then(() => {
        this.$router.push({ name: "akt_user_testing", params: { id } });
      })
      .catch(({ response }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: response.data.error,
          speed: 500
        });
      });
  }
}
