
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import analytics_routes from "@/api/routes/analytics";

import Preloader from "@/components/Preloader.vue";
import CInfiniteScroll from "@/components/InfiniteScroll.vue";

import AnalyticsFilter, { IAnalytics } from "@/models/analytics/filter";
import { IRouteMeta } from "@/router/interface";

interface IDataAnalytics {
  readonly current_page: number;
  readonly next_page: boolean;
  readonly analytics: IAnalytics[];
}

@Component({
  name: "CAnalyticsTableBody",
  components: {
    Preloader,
    CInfiniteScroll
  }
})
export default class CAnalyticsTableBody extends Vue {
  @Prop({ required: true }) public filter!: AnalyticsFilter;

  private per_page: number = 100;
  private current_page: number = 0;
  private next_page: boolean = false;

  public analytics: IAnalytics[] = [];
  public preload: boolean = false;
  private preload_infinite: boolean = false;

  private watchers: Function[] = [];

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  @Watch("filter")
  protected async changeFilters() {
    this.analytics = [];

    await this.loadAnalytics();
  }

  public async infiniteHandler() {
    if (this.next_page && !this.preload_infinite) {
      await this.loadAnalytics(this.current_page + 1);
    }
  }

  protected async loadAnalytics(page: number = 0) {
    this.preload_infinite = true;

    return this.$api
      .get(analytics_routes.analytics, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true,
          only_my: true,
          section_id: (this.$router.currentRoute.meta! as IRouteMeta)
            .permissions?.[0]
        }
      })
      .then(({ data: res }: { data: IDataAnalytics }) => {
        this.analytics.push(...res.analytics);

        this.current_page = res.current_page;
        this.next_page = res.next_page;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      })
      .finally(() => {
        this.preload_infinite = false;
      });
  }
}
