
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import Preloader from "@/components/Preloader.vue";
import VInfinity from "./Infinity.vue";
import CSkillRadio from "@/components/SkillRadio.vue";

import structureRoutes from "@/api/routes/structures";
import skillRoutes from "@/api/routes/skills";

import StructureFactory from "@/models/structure/factory";
import StructureInfinity from "@/models/structure/infinity";
import InventoryInventory from "@/models/inventory/inventory";
import BlockBlock, { IBlockSkill } from "@/models/block/block";
import CLevelSelector from "../../components/LevelSelector.vue";
import {
  updateSkillCheckboxesState,
  onSelectAllClicked,
  defaultBlocks
} from "../../helpers/update_skill_checkboxes_state";
import { EThreeStateCheckbox } from "../../enums/three_state_checkbox";
import SkillCategory from "../../models/skill/category";
import { plainToClass, plainToClassFromExist } from "class-transformer";
import { isLocalFunctional } from "../../models/skill/local";
import { onDisplayZeroLevelSkillsChanged } from "../../helpers/matrix_helper";

@Component({
  name: "VFactory",
  components: {
    Preloader,
    VInfinity,
    CSkillRadio,
    CLevelSelector
  },
  methods: {
    updateSkillCheckboxesState,
    onSelectAllClicked,
    isLocalFunctional,
    onDisplayZeroLevelSkillsChanged
  },
  data: () => {
    return {
      defaultBlocks,
      EThreeStateCheckbox
    };
  }
})
export default class VFactory extends Vue {
  @Prop({ required: true }) private factory!: StructureFactory;
  @Prop({ required: true }) private inventories!: InventoryInventory[];
  @Prop({ required: true }) protected currentUser!: IJWTUser | null;

  protected preload: boolean = false;
  protected saveLoader: boolean = false;
  protected toggleSkillBlocks: boolean = false;

  protected infinities: StructureInfinity[] = [];

  protected tabs = ["Уровень"];
  protected currentTab: number = 0;

  protected editInfinity: StructureInfinity = new StructureInfinity();
  protected editInfinityIndex: number | null = null;
  protected editInfinityErrors: string | string[] | null = null;

  protected editInventory: InventoryInventory | null = null;

  private currentBlockFilter: BlockBlock = defaultBlocks[0];
  protected blocksFilter: BlockBlock[] = [];
  private skillCategoriesFilter: SkillCategory[] = [{ id: 0, value: "Все" }];
  private currentSkillCategoriesFilter: SkillCategory = this
    .skillCategoriesFilter[0];
  protected searchSkills: string = "";
  /** Состояние чекбокса выбора всех навыков */
  private selectAllSkillsCheckboxState: EThreeStateCheckbox =
    EThreeStateCheckbox.UNCHECK;
  /** Показать навыки с целевым уровнем "0" */
  private displayZeroLevelSkills = false;

  $refs!: {
    blocks: HTMLFormElement[];
  };

  protected async created() {
    this.loadCategories();
    if (this.factory?.id) {
      await this.loadFactory();
      await this.loadInfinities();
    }
  }

  protected async loadFactory() {
    return this.$api
      .get(structureRoutes.factory(this.factory!.id!), {
        params: {
          matrix: true,
          inventory_id: this.factory!.inventory_id
        }
      })
      .then(({ data: res }: { data: StructureFactory }) => {
        this.factory.blocks = res.blocks;
      });
  }

  @Watch("factory")
  protected async loadInfinities() {
    this.preload = true;

    return this.$api
      .get(structureRoutes.infinities, {
        params: { factory_id: this.factory!.id }
      })
      .then(({ data: res }: { data: StructureInfinity[] }) => {
        res.forEach(r => (r.is_show = false));

        this.infinities = res;

        this.preload = false;
      });
  }

  protected changeLevel(
    skill: IBlockSkill,
    blockIndex: number,
    skillIndex: number
  ) {
    this.$set(
      this.editInfinity!.blocks![blockIndex]!.skills!,
      skillIndex,
      skill
    );
  }

  protected toggleInfinity(index: number) {
    const infinity = this.infinities[index];

    infinity.is_show = !infinity.is_show;
  }

  protected showModal() {
    this.$modal.show("editInfinity");
  }

  protected hideModal() {
    this.$modal.hide("editInfinity");
  }

  protected async loadInfinity(
    id: string,
    inventory_id: string,
    update_full: boolean = true
  ) {
    this.currentBlockFilter = defaultBlocks[0];
    this.currentSkillCategoriesFilter = this.skillCategoriesFilter[0];
    this.searchSkills = "";
    this.selectAllSkillsCheckboxState = EThreeStateCheckbox.UNCHECK;

    return this.$api
      .get(structureRoutes.infinity(id), {
        params: {
          matrix: true,
          parent_id: this.factory.id,
          inventory_id
        }
      })
      .then(({ data: res }: { data: StructureInfinity }) => {
        if (update_full) {
          this.editInfinity = res;
        } else {
          this.editInfinity.blocks = res.blocks;
        }

        this.blocksFilter = plainToClassFromExist(
          this.blocksFilter,
          res.blocks
        );

        this.displayZeroLevelSkills = false;
        this.editInfinity.blocks = onDisplayZeroLevelSkillsChanged(
          this.editInfinity.blocks || [],
          this.displayZeroLevelSkills
        );
      });
  }

  private loadCategories() {
    this.$api
      .get(skillRoutes.categories)
      .then(({ data: res }: { data: SkillCategory[] }) => {
        this.skillCategoriesFilter.push(...res);
      });
  }

  protected changeSkillFilter() {
    this.editInfinity.blocks = [];
    this.blocksFilter.forEach(block => {
      if (
        block.id === this.currentBlockFilter.id ||
        !this.currentBlockFilter.id
      ) {
        const block_copy = plainToClass(BlockBlock, block);
        block_copy.skills = block_copy.skills?.filter(
          skill =>
            (skill.category_id === this.currentSkillCategoriesFilter.id ||
              !this.currentSkillCategoriesFilter.id) &&
            (skill.full_name
              ?.toLowerCase()
              .includes(this.searchSkills.trim()?.toLowerCase()) ||
              !this.searchSkills.trim())
        );
        if (block_copy.skills?.length) {
          this.editInfinity.blocks?.push(block_copy);
        }
      }
    });

    this.editInfinity.blocks = onDisplayZeroLevelSkillsChanged(
      this.editInfinity.blocks || [],
      this.displayZeroLevelSkills
    );

    this.selectAllSkillsCheckboxState = updateSkillCheckboxesState(
      this.$refs,
      this.editInfinity.blocks,
      this.selectAllSkillsCheckboxState
    );
  }

  protected async changeInventory(obj: InventoryInventory) {
    await this.loadInfinity(this.editInfinity!.id! || "new", obj.id!, false);
  }

  protected async beforeCreateInfinity() {
    this.editInfinityIndex = null;
    this.editInfinityErrors = null;
    this.editInventory = null;

    await this.loadFactory();

    await this.loadInfinity("new", "");

    this.editInfinity.factory_id = this.factory.id!;
    this.editInfinity.parent_id = null;

    this.showModal();
  }

  protected async createInfinity() {
    this.editInfinity.inventory_id = this.editInventory?.id || null;
    this.saveLoader = true;

    return this.$api
      .post(structureRoutes.infinities, this.editInfinity)
      .then(({ data: res }: { data: StructureInfinity }) => {
        this.infinities.push({ ...res, ...{ is_show: false } });

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.editInfinityErrors = res.data.error;
      })
      .finally(() => {
        this.saveLoader = false;
      });
  }

  protected async beforeUpdateInfinity(index: number) {
    this.saveLoader = true;
    await this.loadFactory();

    const infinity = this.infinities[index];
    this.editInfinityIndex = index;
    this.editInfinityErrors = null;
    this.editInventory = this.inventories.find(
      i => i.id === infinity.inventory_id
    )!;

    await this.loadInfinity(infinity.id!, infinity.inventory_id!);

    this.saveLoader = false;
    this.showModal();
  }

  protected async updateInfinity() {
    this.editInfinity.inventory_id = this.editInventory?.id || null;
    this.saveLoader = true;

    return this.$api
      .put(structureRoutes.infinity(this.editInfinity.id!), this.editInfinity)
      .then(({ data: res }: { data: StructureInfinity }) => {
        const editInfinity = this.infinities[this.editInfinityIndex!];

        editInfinity.full_name = res.full_name;
        editInfinity.inventory_id = res.inventory_id;

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.editInfinityErrors = res.data.error;
      })
      .finally(() => {
        this.saveLoader = false;
      });
  }

  protected async destroyInfinity() {
    if (window.confirm("Вы уверены, что хотите отправить Уровень в архив?")) {
      return this.$api
        .post(structureRoutes.infinity_archived(this.editInfinity.id!), {})
        .then(() => {
          this.$delete(this.infinities, this.editInfinityIndex!);

          this.hideModal();
        })
        .catch(({ response: res }) => {
          this.editInfinityErrors = res.data.error;
        });
    }
  }

  protected disabledLevel(skill: IBlockSkill, block: BlockBlock) {
    return isLocalFunctional(skill)
      ? 0
      : this.factory?.blocks
          ?.find(b => b.id === block.id)
          ?.skills?.find(
            s =>
              s?.id === skill?.id ||
              (s?.origin_id && s?.origin_id === skill?.origin_id)
          )?.level;
  }

  protected showFunctionalLevel(skill: IBlockSkill, block: BlockBlock) {
    return (
      this.factory?.blocks
        ?.find(b => b.id === block.id)
        ?.skills?.find(
          s =>
            s?.id === skill?.id ||
            (s?.origin_id && s?.origin_id === skill?.origin_id)
        ) || isLocalFunctional(skill)
    );
  }

  public allowWrite() {
    return this.$api.allowWrite();
  }
}
