
import { Component, Prop, Vue } from "vue-property-decorator";

import Preloader from "@/components/Preloader.vue";
import CPrioritySkillFilters, {
  IPrioritySkillFiltersInput
} from "@/components/priority/skill_filters.vue";

import { CheckInteger } from "@/helpers/number";

import prioritiesRoutes from "@/api/routes/priorities";
import skillsRoutes from "@/api/routes/skills";
import blocksRoutes from "@/api/routes/blocks";
import pillarsRoutes from "@/api/routes/pillars";
import signsRoutes from "@/api/routes/signs";

import UserUser from "@/models/user/user";
import {
  IPriority,
  IPriorityShort,
  IPrioritySkill,
  sort_fn
} from "@/models/priority/priority";
import { PriorityTypes } from "@/enums/priority_types";
import SignSign from "@/models/sign/sign";
import PillarPillar from "@/models/pillar/pillar";
import BlockBlock from "@/models/block/block";
import SkillCategory from "@/models/skill/category";

import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";

@Component({
  components: {
    Preloader,
    CPrioritySkillFilters
  },
  data: () => {
    return {
      CheckInteger
    };
  }
})
export default class CTeamPriority extends Vue {
  @Prop({ required: true }) protected user!: UserUser;

  @Validations()
  protected validations = {
    actionEditValue: { required },
    editPriority: {
      skills: {
        $each: {
          priority: { required }
        }
      }
    }
  };

  protected preload: boolean = false;
  protected saveLoader: boolean = false;

  protected watchers: Function[] = [];

  protected actions: { id: string; label: string }[] = [
    { id: "set_value", label: "Установить значение" },
    { id: "set_max", label: "Высший приоритет" }
  ];
  protected currentAction = this.actions[0];
  protected actionEditValue: number = 1000;

  protected editPriority: IPriority | null = null;
  protected editPrioritySkillChecked: string[] = [];

  protected categories: SkillCategory[] = [];
  protected blocks: BlockBlock[] = [];
  protected pillars: PillarPillar[] = [];
  protected signs: SignSign[] = [];

  /** Для применения фильтров */
  protected showEditSkills: boolean = true;

  protected async created() {
    this.preload = true;

    await Promise.all([
      this.loadUserPriority(),
      this.loadCategories(),
      this.loadBlocks(),
      this.loadPillars(),
      this.loadSigns()
    ]);

    this.preload = false;
  }

  protected async loadUserPriority() {
    return this.$api
      .get(prioritiesRoutes.user(this.user.id))
      .then(({ data: res }: { data: IPriority }) => {
        this.editPriority = res;

        if (!this.editPriority.id) {
          const first_workplace = this.user.workplaces?.[0];

          if (first_workplace) {
            this.editPriority.factory_id = first_workplace.factory_id;
          }

          this.editPriority.user_id = this.user.id;
          this.editPriority.priority_type = PriorityTypes.USERLOCAL;
        }

        if (this.editPriority.skills?.length) {
          this.editPriority.skills.forEach(skill => {
            skill.show = true;
            skill.checked = false;
          });
        }
      });
  }

  protected async createPriority() {
    if (this.$v.editPriority.skills?.$error) {
      this.$notify({
        group: "notifications",
        type: "error",
        text: "Значение приоритета не может быть пустым",
        speed: 500
      });
      return;
    }

    this.saveLoader = true;

    return this.$api
      .post(prioritiesRoutes.create, {
        priority: this.editPriority
      })
      .then(({ data: res }: { data: IPriorityShort }) => {
        this.editPriority!.id = res.id;

        this.editPriority!.skills = this.editPriority!.skills?.sort(sort_fn());

        this.$notify({
          group: "notifications",
          type: "success",
          text: "Приоритет для пользователя создан",
          speed: 500
        });
      })
      .catch(({ response }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: response.data.error,
          speed: 500
        });
      })
      .finally(() => {
        this.saveLoader = false;
      });
  }

  protected async updatePriority() {
    if (this.$v.editPriority.skills?.$error) {
      this.$notify({
        group: "notifications",
        type: "error",
        text: "Значение приоритета не может быть пустым",
        speed: 500
      });
      return;
    }

    this.saveLoader = true;

    return this.$api
      .put(prioritiesRoutes.update(this.editPriority!.id!), {
        priority: this.editPriority
      })
      .then(() => {
        this.editPriority!.skills = this.editPriority!.skills?.sort(sort_fn());

        this.$notify({
          group: "notifications",
          type: "success",
          text: "Изменения сохранены",
          speed: 500
        });
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      })
      .finally(() => {
        this.saveLoader = false;
      });
  }

  protected async loadNewSkills() {
    return this.$api
      .get(prioritiesRoutes.new_skills, {
        params: {
          factory_id: this.editPriority!.factory_id,
          priority_id: this.editPriority!.id,
          user_id: this.user.id
        }
      })
      .then(({ data: res }: { data: IPrioritySkill[] }) => {
        this.$set(this.editPriority!, "skills", res);

        if (this.editPriority!.skills?.length) {
          this.editPriority!.skills.forEach(skill => {
            skill.show = true;
            skill.checked = false;
          });
        }
      });
  }

  protected changeEditPrioritySkillChecked(select: boolean = false) {
    if (this.editPrioritySkillChecked.length && !select) {
      this.editPrioritySkillChecked = [];

      this.editPriority!.skills!.forEach(skill => {
        skill.checked = false;
      });
    } else {
      this.editPrioritySkillChecked = [];

      this.editPriority!.skills!.forEach(skill => {
        if (skill.show) {
          skill.checked = true;

          this.editPrioritySkillChecked.push(skill.skill_id);
        }
      });
    }
  }

  protected selectEditPrioritySkill(index: number) {
    const skill = this.editPriority!.skills![index];

    if (!skill.is_archived) {
      const checked = this.editPriority!.skills![index].checked;

      this.editPriority!.skills![index].checked = !checked;
    }
  }

  protected setEditAction() {
    if (this.$v.actionEditValue.$error) {
      this.$notify({
        group: "notifications",
        type: "error",
        text: "Значение приоритета не может быть пустым",
        speed: 500
      });
      return;
    }

    if (this.currentAction.id === "set_max") {
      this.editPriority?.skills?.forEach(skill => {
        if (skill.checked) {
          skill.priority = 0;
        }
      });
    } else {
      this.editPriority?.skills?.forEach(skill => {
        if (skill.checked) {
          skill.priority = this.actionEditValue;
        }
      });
    }
  }

  protected async loadCategories() {
    this.$api
      .get(skillsRoutes.categories)
      .then(({ data: res }: { data: SkillCategory[] }) => {
        this.categories = res;
      });
  }

  protected async loadBlocks() {
    this.$api
      .get(blocksRoutes.blocks, { params: { paginate: false } })
      .then(({ data: res }: { data: { blocks: BlockBlock[] } }) => {
        this.blocks = res.blocks;
      });
  }

  protected async loadPillars() {
    this.$api
      .get(pillarsRoutes.pillars)
      .then(({ data: res }: { data: PillarPillar[] }) => {
        this.pillars = res;
      });
  }

  protected async loadSigns() {
    this.$api
      .get(signsRoutes.signs, {
        params: { paginate: false, include_skills: false }
      })
      .then(({ data: res }: { data: { signs: SignSign[] } }) => {
        this.signs = res.signs;
      });
  }

  protected changeEditFilter(value: IPrioritySkillFiltersInput) {
    if (this.editPriority?.skills?.length) {
      this.showEditSkills = false;

      for (const skill of this.editPriority.skills) {
        let show: boolean = true;

        if (value.category_id && skill.category_id !== value.category_id) {
          show = false;
        }

        if (value.pillar_id && skill.pillar_id !== value.pillar_id) {
          show = false;
        }

        if (value.block_id && !skill.block_ids![value.block_id]) {
          show = false;
        }

        if (value.sign_id && !skill.sign_ids![value.sign_id]) {
          show = false;
        }

        if (
          value.search &&
          value.search.length > 0 &&
          !skill.full_name.toLowerCase().includes(value.search.toLowerCase())
        ) {
          show = false;
        }

        skill.show = show;
      }

      this.showEditSkills = true;
    }
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected allowWrite() {
    return this.$api.allowWrite();
  }
}
