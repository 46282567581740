var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"c-akt-testing-subscription"}},[_c('Preloader',{directives:[{name:"show",rawName:"v-show",value:(_vm.preload),expression:"preload"}]}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.preload),expression:"!preload"}],staticClass:"d-flex flex-column flex-auto"},[(_vm.subscription)?[_c('div',{staticClass:"flex-auto"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('p',{staticClass:"font-14 font-medium"},[_vm._v(" "+_vm._s(_vm.subscription.question_version.title)+" ")])]),(
              _vm.subscription.question_version.use_image &&
                _vm.subscription.question_version.image_url
            )?_c('div',{staticClass:"col-4"},[(_vm.subscription.question_version.image_url.endsWith('.pdf'))?_c('PdfView',{key:_vm.subscription.question_version.image_url,attrs:{"url":_vm.subscription.question_version.image_url,"preview":true}}):_c('ImageView',{key:_vm.subscription.question_version.image_url,attrs:{"url":_vm.subscription.question_version.image_url,"size":'responsive',"id":'image_preview'}})],1):_vm._e()]),(_vm.subscription.question_version.isOpen)?_c('div',{key:_vm.subscription.question_version.id},[(_vm.changeResult || _vm.subscription.is_checked)?_c('div',{staticClass:"t-24"},[_c('p',{staticClass:"font-10 font-lightgray"},[_vm._v("Правильный ответ")]),_c('p',{staticClass:"t-6 font-12"},[_vm._v(" "+_vm._s(_vm.subscription.question_version.questionable.value)+" ")])]):_vm._e(),_c('div',{staticClass:"t-24"},[_c('p',{staticClass:"font-10 font-lightgray"},[_vm._v("Ваш ответ")]),_c('p',{staticClass:"t-6 font-12"},[_vm._v(_vm._s(_vm.textResult))])]),(_vm.changeResult && _vm.allowWrite())?_c('div',{staticClass:"t-18 not-for-print row align-items-center"},[_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"border-all it-8 il-8 ir-8 ib-8 round-8"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-text",class:{
                        'btn-blue':
                          _vm.subscription.is_correct && _vm.subscription.is_checked
                      },on:{"click":function($event){return _vm.setCorrect(true)}}},[_c('inline-svg',{staticClass:"w-20 h-20",attrs:{"src":'/img/icon-check.svg'}}),_c('span',[_vm._v("Правильно")])],1)]),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-text",class:{
                        'btn-red':
                          !_vm.subscription.is_correct && _vm.subscription.is_checked
                      },on:{"click":function($event){return _vm.setCorrect(false)}}},[_c('inline-svg',{staticClass:"w-20 h-20",attrs:{"src":'/img/icon-close-inherit.svg'}}),_c('span',[_vm._v("Неправильно")])],1)])])])]),(_vm.subscription.is_checked && _vm.justChanged)?_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"subscribe-alert"},[_c('inline-svg',{staticClass:"w-16 h-16 br-8",attrs:{"src":'/img/icon-save.svg'}}),_c('span',{staticClass:"font-10"},[_vm._v("Изменения сохранены")])],1)]):_vm._e()]):_vm._e()]):_c('div',{key:_vm.subscription.question_version.id,staticClass:"t-24"},_vm._l((_vm.subscription.question_version
              .questionable.answer_versions),function(answer_version,av_index){return _c('label',{key:answer_version.id,staticClass:"answer_item",class:{
              'answer_item--checked':
                answer_version.id === _vm.singleChecked ||
                _vm.multipleChecked.includes(answer_version.id)
            },attrs:{"for":'answer_' + answer_version.id}},[(_vm.subscription.question_version.isSingle)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.singleChecked),expression:"singleChecked"}],staticClass:"radio",attrs:{"type":"radio","id":'answer_' + answer_version.id,"disabled":true},domProps:{"value":answer_version.id,"checked":_vm._q(_vm.singleChecked,answer_version.id)},on:{"change":function($event){_vm.singleChecked=answer_version.id}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.multipleChecked),expression:"multipleChecked"}],attrs:{"type":"checkbox","disabled":true,"id":'answer_' + answer_version.id},domProps:{"value":answer_version.id,"checked":Array.isArray(_vm.multipleChecked)?_vm._i(_vm.multipleChecked,answer_version.id)>-1:(_vm.multipleChecked)},on:{"change":function($event){var $$a=_vm.multipleChecked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=answer_version.id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.multipleChecked=$$a.concat([$$v]))}else{$$i>-1&&(_vm.multipleChecked=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.multipleChecked=$$c}}}}),_c('div',{staticClass:"answer_item_icon",class:[
                answer_version.answer.is_correct
                  ? 'answer_item_icon--green'
                  : 'answer_item_icon--red'
              ],domProps:{"textContent":_vm._s((av_index + 1 + 9).toString(36).toUpperCase())}}),_c('div',{staticClass:"answer_item_label flex-fill"},[_vm._v(" "+_vm._s(answer_version.title)+" ")]),(
                answer_version.id === _vm.singleChecked ||
                  _vm.multipleChecked.includes(answer_version.id)
              )?_c('div',{staticClass:"bl-16 line-0",class:[
                answer_version.answer.is_correct ? 'font-green' : 'font-red'
              ]},[_c('inline-svg',{staticClass:"w-30 h-30",attrs:{"src":answer_version.answer.is_correct
                    ? '/img/icon-done-green.svg'
                    : '/img/icon-close-current.svg'}})],1):_vm._e(),(
                !_vm.subscription.question_version.isSingle &&
                  answer_version.answer.is_correct &&
                  !_vm.multipleChecked.includes(answer_version.id)
              )?_c('div',{staticClass:"bl-16 line-0 font-red"},[_c('inline-svg',{staticClass:"w-30 h-30",attrs:{"src":'/img/icon-close-current.svg'}})],1):_vm._e()])}),0)])]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }