
import { Component, Vue } from "vue-property-decorator";

import Preloader from "@/components/Preloader.vue";
import CSkillRadio from "@/components/SkillRadio.vue";

import inventoryRoutes from "@/api/routes/inventories";
import skillRoutes from "@/api/routes/skills";

import InventoryInventory from "@/models/inventory/inventory";

import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import BlockBlock, { IBlockSkill } from "@/models/block/block";
import CLevelSelector from "../components/LevelSelector.vue";
import {
  updateSkillCheckboxesState,
  onSelectAllClicked,
  defaultBlocks
} from "../helpers/update_skill_checkboxes_state";
import { EThreeStateCheckbox } from "../enums/three_state_checkbox";
import { plainToClass, plainToClassFromExist } from "class-transformer";
import SkillCategory from "../models/skill/category";
import { onDisplayZeroLevelSkillsChanged } from "../helpers/matrix_helper";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
  readonly inventories: InventoryInventory[];
}

@Component({
  components: {
    Preloader,
    CSkillRadio,
    CLevelSelector
  },
  methods: {
    updateSkillCheckboxesState,
    onSelectAllClicked,
    onDisplayZeroLevelSkillsChanged
  },
  data: () => {
    return {
      defaultBlocks,
      EThreeStateCheckbox
    };
  }
})
export default class Inventories extends Vue {
  private preload: boolean = false;
  private toggleSkillBlocks: boolean = false;

  private currentUser: IJWTUser | null = this.$store.state.currentUser;

  private per_page: number = 100;
  private current_page: number = 0;
  private next_page: boolean = false;

  private inventories: InventoryInventory[] = [];

  private editInventoryIndex: number | null = null;
  private editInventory: InventoryInventory = new InventoryInventory();
  private editInventoryErrors: string | null = null;

  private currentBlockFilter: BlockBlock = defaultBlocks[0];
  protected blocksFilter: BlockBlock[] = [];
  private skillCategoriesFilter: SkillCategory[] = [{ id: 0, value: "Все" }];
  private currentSkillCategoriesFilter: SkillCategory = this
    .skillCategoriesFilter[0];
  protected searchSkills: string = "";
  /** Состояние чекбокса выбора всех навыков */
  private selectAllSkillsCheckboxState: EThreeStateCheckbox =
    EThreeStateCheckbox.UNCHECK;
  /** Показать навыки с целевым уровнем "0" */
  private displayZeroLevelSkills = false;

  @Validations()
  private validations = {
    editInventory: {
      full_name: {
        required
      }
    }
  };

  private created() {
    Promise.all([this.loadInventories(), this.loadCategories()]);
  }

  private loadInventories(page: number = 0) {
    this.preload = true;

    this.$api
      .get(inventoryRoutes.inventories_paginate, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true
        }
      })
      .then(({ data: res }: { data: IData }) => {
        this.current_page = res.current_page;

        if (res.inventories.length) {
          this.next_page = res.next_page;

          this.inventories.push(...res.inventories);
        } else {
          this.next_page = false;
        }

        this.preload = false;
      });
  }

  private loadCategories() {
    this.$api
      .get(skillRoutes.categories)
      .then(({ data: res }: { data: SkillCategory[] }) => {
        this.skillCategoriesFilter.push(...res);
      });
  }

  protected changeLevel(
    skill: IBlockSkill,
    blockIndex: number,
    skillIndex: number
  ) {
    this.$set(
      this.editInventory!.blocks![blockIndex]!.skills!,
      skillIndex,
      skill
    );
  }

  private loadInventory(id: string) {
    this.currentBlockFilter = defaultBlocks[0];
    this.currentSkillCategoriesFilter = this.skillCategoriesFilter[0];
    this.searchSkills = "";
    this.selectAllSkillsCheckboxState = EThreeStateCheckbox.UNCHECK;

    return this.$api
      .get(inventoryRoutes.inventory(id))
      .then(({ data: res }: { data: InventoryInventory }) => {
        this.editInventory = res;

        this.blocksFilter = plainToClassFromExist(
          this.blocksFilter,
          res.blocks
        );

        this.displayZeroLevelSkills = false;
        this.editInventory.blocks = onDisplayZeroLevelSkillsChanged(
          this.editInventory.blocks || [],
          this.displayZeroLevelSkills
        );
      });
  }

  protected changeSkillFilter() {
    this.editInventory.blocks = [];
    this.blocksFilter.forEach(block => {
      if (
        block.id === this.currentBlockFilter.id ||
        !this.currentBlockFilter.id
      ) {
        const block_copy = plainToClass(BlockBlock, block);
        block_copy.skills = block_copy.skills?.filter(
          skill =>
            (skill.category_id === this.currentSkillCategoriesFilter.id ||
              !this.currentSkillCategoriesFilter.id) &&
            (skill.full_name
              ?.toLowerCase()
              .includes(this.searchSkills.trim()?.toLowerCase()) ||
              !this.searchSkills.trim())
        );
        if (block_copy.skills?.length) {
          this.editInventory.blocks?.push(block_copy);
        }
      }
    });

    this.selectAllSkillsCheckboxState = updateSkillCheckboxesState(
      this.$refs,
      this.editInventory.blocks,
      this.selectAllSkillsCheckboxState
    );

    this.editInventory.blocks = onDisplayZeroLevelSkillsChanged(
      this.editInventory.blocks || [],
      this.displayZeroLevelSkills
    );
  }

  private infiniteHandler() {
    if (this.next_page) {
      this.loadInventories(this.current_page + 1);
    }
  }

  private showModal() {
    this.$v.$reset();
    this.$modal.show("editInventory");
  }

  private hideModal() {
    this.$modal.hide("editInventory");
  }

  private beforeCreateInventory() {
    this.loadInventory("new").then(_r => {
      this.showModal();
    });
  }

  private async beforeUpdateInventory(index: number) {
    this.editInventoryIndex = index;

    const inventory = this.inventories[index];

    this.loadInventory(inventory.id!).then(_ => {
      this.showModal();
    });
  }

  private createInventory() {
    this.editInventory.full_name = this.editInventory.full_name?.trim();

    this.$v.$touch();
    if (this.$v.$anyError) {
      return;
    }

    this.$api
      .post(inventoryRoutes.inventories, this.editInventory)
      .then(({ data: res }: { data: InventoryInventory }) => {
        this.inventories.push(res);

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.editInventoryErrors = res.data.error;
      });
  }

  private updateInventory() {
    this.editInventory.full_name = this.editInventory.full_name?.trim();

    this.$v.$touch();
    if (this.$v.$anyError) {
      return;
    }

    this.$api
      .put(
        inventoryRoutes.inventory(this.editInventory.id!),
        this.editInventory
      )
      .then(({ data: res }: { data: InventoryInventory }) => {
        this.$set(this.inventories, this.editInventoryIndex!, res);

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.editInventoryErrors = res.data.error;
      });
  }

  private destroyInventory() {
    this.$api
      .destroy(inventoryRoutes.inventory(this.editInventory.id!))
      .then(({ data: res }: { data: InventoryInventory }) => {
        this.$delete(this.inventories, this.editInventoryIndex!);
        this.$modal.hide("destroyInventoryModal");
        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.editInventoryErrors = res.data.error;
      });
  }
}
