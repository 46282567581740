
import { ref, Ref } from "vue";
import { Component, Vue, Prop } from "vue-property-decorator";

import user_routes from "@/api/routes/users";

import CInfiniteScroll from "@/components/InfiniteScroll.vue";
import CStructure, { IStructureProp } from "@/components/Structure.vue";

import TaskTask from "@/models/task/task";
import StructureFactory from "@/models/structure/factory";
import UserUser from "@/models/user/user";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
  readonly users: UserUser[];
}

@Component({
  name: "CTaskChangeEmployees",
  components: {
    CInfiniteScroll,
    CStructure
  }
})
export default class CTaskChangeEmployees extends Vue {
  @Prop({ required: true }) public task!: TaskTask;
  @Prop({ required: true }) public factories!: StructureFactory[];

  public factories_options: StructureFactory[] = [
    { full_name: "Все", level_name: "Уровень", skills: [] }
  ];
  public selected_factory: StructureFactory | null = this.factories_options[0];
  public structure: IStructureProp = {};
  public searched_user: string = "";

  private per_page: number = 100;
  private current_page: number = 0;
  private next_page: boolean = false;
  private infinite_preload: boolean = false;

  public selected_all_unsigned_employees: boolean = false;
  public checked_unsigned_employees_count: number = 0;
  public key_unsigned_employees: number = 0; // для ререндера
  public unsigned_employees: Ref<Map<string, UserUser>> = ref(new Map());

  public selected_all_signed_employees: boolean = false;
  public checked_signed_employees_count: number = 0;
  public key_signed_employees: number = 0; // для ререндера
  public signed_employees: Ref<Map<string, UserUser>> = ref(new Map());

  public key_employees: number = 0; // для ререндера

  public employees_to_add: Map<string, UserUser> = new Map();
  public employees_to_remove: Map<string, UserUser> = new Map();

  protected async created() {
    this.signed_employees.value = new Map();
    this.unsigned_employees.value = new Map();

    this.factories_options.push(...this.factories);

    await this.loadUsers();

    this.key_employees++;
  }

  public async infiniteHandler() {
    if (this.next_page && !this.infinite_preload) {
      await this.loadUsers(this.current_page + 1);
    }
  }

  public async searchUsers() {
    await this.clearUsers();
  }

  public async changeFactory() {
    this.structure = {};

    await this.clearUsers();
  }

  public async changeStructureFilter(structure: IStructureProp) {
    this.structure = structure;

    await this.clearUsers();
  }

  public async clearUsers() {
    this.current_page = 0;
    this.next_page = false;
    this.selected_all_unsigned_employees = false;

    this.unsigned_employees.value = new Map();

    await this.loadUsers();

    this.key_employees++;
  }

  private async loadUsers(page: number = 0) {
    this.infinite_preload = true;

    return this.$api
      .get(user_routes.users_tree_search, {
        params: {
          search: this.searched_user,
          factory_id: this.selected_factory?.id,
          infinity_id: this.structure?.infinity_id,
          any_infinity: !this.structure?.infinity_id,
          page,
          per_page: this.per_page,
          paginate: true
        }
      })
      .then(({ data: res }: { data: IData }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;

        res.users.forEach(s => {
          if (!this.signed_employees.value.has(s.id)) {
            this.unsigned_employees.value.set(s.id, s);

            if (this.selected_all_unsigned_employees) {
              s.is_check = true;

              this.checked_unsigned_employees_count++;
            }
          }
        });
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      })
      .finally(async () => {
        this.infinite_preload = false;
        this.key_unsigned_employees++;
      });
  }

  public checkAllUnsignedEmployees() {
    if (this.selected_all_unsigned_employees) {
      this.unsigned_employees.value.forEach(user => {
        user.is_check = false;
      });

      this.checked_unsigned_employees_count = 0;
      this.selected_all_unsigned_employees = false;
    } else {
      this.unsigned_employees.value.forEach(user => {
        user.is_check = true;
      });

      this.checked_unsigned_employees_count = this.unsigned_employees.value.size;
      this.selected_all_unsigned_employees = true;
    }
  }

  public checkAllIconUnsignedEmployees() {
    return this.checked_unsigned_employees_count === 0
      ? null
      : this.selected_all_unsigned_employees
      ? "/img/icon-plus.svg"
      : "/img/icon-minus.svg";
  }

  public addAllCheckedUnsignedEmployees() {
    this.unsigned_employees.value.forEach(user => {
      if (user.is_check) {
        user.is_check = false;
        this.signed_employees.value.set(user.id, user);
        this.unsigned_employees.value.delete(user.id);

        this.employees_to_add.set(user.id, user);
      }
    });

    this.checked_unsigned_employees_count = 0;
    this.selected_all_unsigned_employees = false;
    this.selected_all_signed_employees = false;

    this.task.user_ids = Array.from(this.signed_employees.value.keys());
  }

  public checkUnsignedEmployees(user: UserUser) {
    this.$set(user, "is_check", !user.is_check);

    this.checked_unsigned_employees_count += user.is_check ? 1 : -1;
    this.selected_all_unsigned_employees =
      this.checked_unsigned_employees_count ===
      this.unsigned_employees.value.size;
  }

  public checkAllSignedEmployees() {
    if (this.selected_all_signed_employees) {
      this.signed_employees.value.forEach(user => {
        user.is_check = false;
      });

      this.checked_signed_employees_count = 0;
      this.selected_all_signed_employees = false;
    } else {
      this.signed_employees.value.forEach(user => {
        user.is_check = true;
      });

      this.checked_signed_employees_count = this.signed_employees.value.size;
      this.selected_all_signed_employees = true;
    }
  }

  public checkAllIconSignedEmployees() {
    return this.checked_signed_employees_count === 0
      ? null
      : this.selected_all_signed_employees
      ? "/img/icon-plus.svg"
      : "/img/icon-minus.svg";
  }

  public removeAllCheckedSignedEmployees() {
    this.signed_employees.value.forEach(user => {
      if (user.is_check) {
        user.is_check = false;
        this.unsigned_employees.value.set(user.id, user);
        this.signed_employees.value.delete(user.id);

        this.employees_to_remove.set(user.id, user);
      }
    });

    this.checked_signed_employees_count = 0;
    this.selected_all_unsigned_employees = false;
    this.selected_all_signed_employees = false;

    this.task.user_ids = Array.from(this.signed_employees.value.keys());
  }

  public checkSignedEmployees(user: UserUser) {
    this.$set(user, "is_check", !user.is_check);

    this.checked_signed_employees_count += user.is_check ? 1 : -1;
    this.selected_all_signed_employees =
      this.checked_signed_employees_count === this.signed_employees.value.size;
  }

  public allowWrite() {
    return this.$api.allowWrite();
  }
}
