
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import CStructure, { IStructureProp } from "@/components/Structure.vue";
import Preloader from "@/components/Preloader.vue";

import StructureFactory from "@/models/structure/factory";
import UserWorkplace from "@/models/user/workplace";
import PositionLocal from "@/models/position/local";
import { ILevelForFilter, ILevelOrigin } from "@/models/position/level";

import positionRoutes from "@/api/routes/positions";

@Component({
  name: "CWorkplace",
  components: {
    CStructure,
    Preloader
  }
})
export default class CWorkplace extends Vue {
  @Prop({ required: false, default: false }) public disabled!: boolean;

  @Prop({ required: true }) protected factories!: StructureFactory[];
  protected factory: StructureFactory | null = null;
  protected factory_id: string | null = null;
  protected factory_uec: boolean = false;

  @Prop({ required: true }) protected levelsForFilter!: ILevelForFilter;
  protected levels: ILevelOrigin[] = [];
  protected current_level_id: string | null = null;
  protected target_level_id: string | null = null;

  @Prop({ required: true }) workplace_index!: number;
  @Prop({ required: true }) workplace!: UserWorkplace;

  @Prop({ default: false }) protected checkErrors!: boolean;
  protected factoryError: string | null = null;
  protected positionError: string | null = null;

  @Prop({ default: false }) protected sameFactory!: boolean;

  protected positions: PositionLocal[] = [];
  protected position: PositionLocal | null = null;

  protected preload: boolean = false;

  @Watch("checkErrors")
  protected changeCheckErrors() {
    if (this.checkErrors) {
      if (!this.factory?.id) {
        this.factoryError = "Укажите завод";
      } else if (this.sameFactory) {
        this.factoryError = "Сотрудник может работать только на одном заводе";
      } else {
        this.factoryError = null;
      }

      if (!this.position?.id) {
        this.positionError = "Укажите должность";
      } else {
        this.positionError = null;
      }
    } else {
      this.factoryError = null;
      this.positionError = null;
    }
  }

  protected async created() {
    this.preload = true;

    if (this.workplace.factory_id) {
      this.factory_id = this.workplace.factory_id;

      this.changeLevels(true);

      this.factory = this.factories.find(
        f => f.id === this.workplace.factory_id
      )!;

      this.factory_uec = !!this.factory?.uec;

      if (this.factory) {
        await this.loadPositions();

        if (this.workplace.position_id) {
          this.position = this.positions.find(
            w => w.id === this.workplace.position_id
          )!;
        }
      }
    }

    this.changeCheckErrors();

    this.preload = false;
  }

  protected changeLevels(created: boolean = false) {
    this.levels = [];

    if (created) {
      if (this.workplace.current_position_level_id) {
        this.current_level_id = this.workplace.current_position_level_id;
      }

      if (this.workplace.target_position_level_id) {
        this.target_level_id = this.workplace.target_position_level_id;
      }
    } else {
      this.current_level_id = null;
      this.target_level_id = null;
      this.workplace.next_position_level_id = null;
    }

    if (this.workplace.factory_id) {
      if (!this.levelsForFilter[this.workplace.factory_id]) {
        return;
      }

      if (this.workplace.infinity_id) {
        if (
          this.levelsForFilter[this.workplace.factory_id].infinities[
            this.workplace.infinity_id
          ]
        ) {
          this.levels = Array.from(
            this.levelsForFilter[this.workplace.factory_id].infinities[
              this.workplace.infinity_id
            ].levels
          );
        }
      } else if (this.workplace.workshop_id) {
        if (
          !this.levelsForFilter[this.workplace.factory_id].workshops[
            this.workplace.workshop_id
          ]
        ) {
          return;
        }

        if (this.workplace.workline_id) {
          if (
            !this.levelsForFilter[this.workplace.factory_id].workshops[
              this.workplace.workshop_id
            ].worklines[this.workplace.workline_id]
          ) {
            return;
          }

          if (this.workplace.equipment_id) {
            if (
              !this.levelsForFilter[this.workplace.factory_id].workshops[
                this.workplace.workshop_id
              ].worklines[this.workplace.workline_id].equipments[
                this.workplace.equipment_id
              ]
            ) {
              return;
            }

            this.levels = Array.from(
              this.levelsForFilter[this.workplace.factory_id].workshops[
                this.workplace.workshop_id
              ].worklines[this.workplace.workline_id].equipments[
                this.workplace.equipment_id
              ].levels
            );
          } else {
            this.levels = Array.from(
              this.levelsForFilter[this.workplace.factory_id].workshops[
                this.workplace.workshop_id
              ].worklines[this.workplace.workline_id].levels
            );
          }
        } else {
          this.levels = Array.from(
            this.levelsForFilter[this.workplace.factory_id].workshops[
              this.workplace.workshop_id
            ].levels
          );
        }
      } else {
        this.levels = Array.from(
          this.levelsForFilter[this.workplace.factory_id].levels
        );
      }
    }

    if (this.levels.length && !this.target_level_id) {
      this.target_level_id = this.levels[0].id;
    }
  }

  protected changeTargetLevelId() {
    this.workplace.target_position_level_id = this.target_level_id;

    this.changeWorkplace();
  }

  protected async changeFactory(obj: StructureFactory) {
    this.positions = [];
    this.position = null;

    this.factory_id = obj?.id || null;
    this.workplace.factory_id = obj?.id || null;
    this.factory_uec = !!obj?.uec;

    this.changeLevels();
    this.changeWorkplace();

    if (obj) {
      await this.loadPositions();
    }

    this.changeCheckErrors();
  }

  protected changePosition() {
    this.changeWorkplace();
    this.changeCheckErrors();
  }

  private async loadPositions() {
    return this.$api
      .get(positionRoutes.positions, {
        params: {
          factory_id: this.factory!.id,
          paginate: false,
          matrices: true
        }
      })
      .then(({ data: res }: { data: { positions: PositionLocal[] } }) => {
        this.positions = res.positions;
      });
  }

  protected changeStructure(obj: IStructureProp) {
    this.workplace.workshop_id = obj.workshop_id;
    this.workplace.workline_id = obj.workline_id;
    this.workplace.equipment_id = obj.equipment_id;
    this.workplace.infinity_id = obj.infinity_id;

    this.changeLevels();
    this.setStructure();
  }

  protected setStructure() {
    this.workplace.factory_id = this.factory?.id || null;
    this.workplace.position_id = this.position?.id || null;
  }

  protected changeWorkplace() {
    this.setStructure();

    this.$emit("changeWorkplace", {
      index: this.workplace_index,
      worplace: this.workplace
    });
  }
}
