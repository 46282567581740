
import { Component, Vue, Prop } from "vue-property-decorator";
import { plainToClass } from "class-transformer";

import akt_routes from "@/api/routes/akt";
import users_routes from "@/api/routes/users";
import structures_routes from "@/api/routes/structures";
import skills_routes from "@/api/routes/skills";

import SkillCategory from "@/models/skill/category";
import UserUser from "@/models/user/user";
import AktQuestionVersion from "@/models/akt/question_version";
import { IQuestion } from "@/models/akt/question";
import { IAktTag } from "@/models/akt/tag";
import StructureFactory from "@/models/structure/factory";
import { EAdmissionPermission } from "@/models/admission/permission";

import CAktQuestionChangeQuestionable from "./QuestionChangeQuestionable.vue";
import CAktQuestionChangeSettings from "./QuestionChangeSettings.vue";
import CAktQuestionChangeApproval from "./QuestionChangeApproval.vue";

export interface IChangingAktQuestion {
  id: string | null;
  question_index: number | null;
}

export interface IChangedAktQuestion {
  question: IQuestion;
  question_index: number | null;
}

interface ICreateOrUpdateResponse {
  question: IQuestion;
  new_tags: IAktTag[];
}

@Component({
  name: "CAktQuestionChange",
  components: {
    CAktQuestionChangeQuestionable,
    CAktQuestionChangeSettings,
    CAktQuestionChangeApproval
  }
})
export default class CAktQuestionChange extends Vue {
  private watchers: Function[] = [];

  public tabs = ["Вопрос", "Параметры", "Этапы согласования"];
  public current_tab: number = 0;

  private modal_opened: boolean = false;

  @Prop({ required: true }) public tags!: IAktTag[];

  public responsibles_list: UserUser[] = [];
  public skill_categories: SkillCategory[] = [];
  public factories: StructureFactory[] = [];

  public question_version_id: string | null = null;
  private question_index: number | null = null;

  public question_version: AktQuestionVersion | null = null;

  public current_user: IJWTUser | null = this.$store.state.currentUser;

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async created() {
    await Promise.all([
      this.loadResponsibles(),
      this.loadSkillCategories(),
      this.loadFactories()
    ]);

    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.current_user = currentUser;
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.changingAktQuestion,
        async (changingAktQuestion: IChangingAktQuestion | null) => {
          await this.initHandler(changingAktQuestion);
        }
      )
    );

    if (this.$store.state.changingAktQuestion && !this.modal_opened) {
      await this.initHandler(this.$store.state.changingAktQuestion);
    }
  }

  private async initHandler(
    changing_akt_question: IChangingAktQuestion | null
  ) {
    if (changing_akt_question != null) {
      this.current_tab = 0;
      this.question_version_id = changing_akt_question.id;
      this.question_index = changing_akt_question.question_index;

      await this.loadQuestion();

      this.showModal();
    } else {
      this.question_version_id = null;
      this.question_index = null;
      this.question_version = null;
    }
  }

  private async loadResponsibles() {
    return this.$api
      .get(users_routes.users, {
        params: {
          paginate: false,
          exclude_current_user: true,
          only_my: false,
          permission_id: EAdmissionPermission.AKT_QUESTIONS
        }
      })
      .then(({ data: res }: { data: { users: UserUser[] } }) => {
        this.responsibles_list = res.users;
      });
  }

  private async loadSkillCategories() {
    return this.$api
      .get(skills_routes.categories)
      .then(({ data: res }: { data: SkillCategory[] }) => {
        this.skill_categories = res;
      });
  }

  private async loadFactories() {
    return this.$api
      .get(structures_routes.factories)
      .then(({ data: res }: { data: StructureFactory[] }) => {
        this.factories = res;
      });
  }

  public showModal() {
    this.$modal.show("change_akt_question_modal");
    this.modal_opened = true;
  }

  public hideModal() {
    this.hideDestroyModal();

    this.$modal.hide("change_akt_question_modal");
    this.modal_opened = false;

    this.$store.commit("changingAktQuestion", null);
  }

  public showDestroyModal() {
    this.$modal.show("destroy_akt_question_modal");
  }

  public hideDestroyModal() {
    this.$modal.hide("destroy_akt_question_modal");
  }

  public async destroyQuestion() {
    return this.$api
      .post(
        akt_routes.questions_archived(this.question_version!.question!.id!),
        {}
      )
      .then(() => {
        this.$store.commit("removedAktQuestion", {
          question_index: this.question_index
        });

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public async createQuestion(send_to_approve: boolean) {
    return this.$api
      .post(akt_routes.questions, {
        question_version: this.question_version,
        responsibles: this.question_version!.responsibles,
        send_to_approve
      })
      .then(({ data: res }: { data: ICreateOrUpdateResponse }) => {
        this.tags.push(...res.new_tags);

        this.$store.commit("changedAktQuestion", {
          question: res.question,
          question_index: this.question_index
        });

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public async updateQuestion(send_to_approve: boolean) {
    return this.$api
      .put(akt_routes.question(this.question_version!.id!), {
        question_version: this.question_version,
        responsibles: this.question_version!.responsibles,
        send_to_approve
      })
      .then(({ data: res }: { data: ICreateOrUpdateResponse }) => {
        this.tags.push(...res.new_tags);

        this.$store.commit("changedAktQuestion", {
          question: res.question,
          question_index: this.question_index
        });

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public async reSendToApprove() {
    return this.$api
      .post(akt_routes.question_re_approve(this.question_version!.id!), {})
      .then(() => {
        this.$notify({
          group: "notifications",
          type: "success",
          text: "Уведомления отправлены",
          speed: 500
        });

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public async removeFromApprove() {
    return this.$api
      .destroy(akt_routes.question_to_approve(this.question_version!.id!))
      .then(({ data: res }: { data: IQuestion }) => {
        this.$store.commit("changedAktQuestion", {
          question: res,
          question_index: this.question_index
        });

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public async updateResponsibles() {
    if (!this.question_version!.isSendToApprove) {
      return;
    }

    let only_new: boolean = false;

    if (window.confirm("Уведомить только новых ответственных?")) {
      only_new = true;
    }

    return this.$api
      .post(akt_routes.question_to_approve(this.question_version!.id!), {
        responsibles: this.question_version!.responsibles,
        only_new
      })
      .then(() => {
        this.$notify({
          group: "notifications",
          type: "success",
          text: "Уведомления отправлены",
          speed: 500
        });

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private async loadQuestion() {
    if (!this.question_version_id) {
      this.question_version = new AktQuestionVersion();

      return;
    }

    return this.$api
      .get(akt_routes.question(this.question_version_id))
      .then(async ({ data: res }: { data: AktQuestionVersion }) => {
        this.question_version = plainToClass(AktQuestionVersion, res);
      });
  }

  public copyQuestion() {
    //
  }
}
