
import { Component, Vue, Prop } from "vue-property-decorator";

import analytics_routes from "@/api/routes/analytics";
import structure_routes from "@/api/routes/structures";
import user_routes from "@/api/routes/users";

import Preloader from "@/components/Preloader.vue";

import AnalyticsFilter from "@/models/analytics/filter";
import StructureFactory from "@/models/structure/factory";
import StructureInfinity from "@/models/structure/infinity";
import { IUserWorkplace } from "@/models/user/workplace";

import CAnalyticsInfinityTree, { IChangeEvent } from "./InfinityTree.vue";
import { IRouteMeta } from "@/router/interface";

@Component({
  name: "CAnalyticsFilters",
  components: {
    Preloader,
    CAnalyticsInfinityTree
  }
})
export default class CAnalyticsFilters extends Vue {
  @Prop({ required: true }) public filter!: AnalyticsFilter;

  public factories: StructureFactory[] = [];
  public factory: StructureFactory | null = null;

  public workplace_name: string = "";

  public templates: AnalyticsFilter[] = [];

  public preload: boolean = false;

  private current_user: IJWTUser | null = this.$store.state.currentUser;

  public current_user_workplaces: IUserWorkplace[] = [];
  public started_infinity_id: string | null = null;
  public started_infinities: StructureInfinity[] = [];

  private watchers: Function[] = [];

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async created() {
    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.current_user = currentUser;
        }
      )
    );

    let load_factories_fn = this.loadFactory;
    let load_workplaces_fn = this.loadWorkplaces;

    if (!this.$api.allowOnlyUserWorkplaces() || this.current_user?.is_hr) {
      this.factories.unshift({
        id: null,
        full_name: "Все",
        level_name: "Завод",
        skills: []
      });

      load_factories_fn = this.loadFactories;
      load_workplaces_fn = async () => void 0;
    }

    this.preload = true;

    await Promise.all([
      this.loadTemplates(),
      load_factories_fn(),
      load_workplaces_fn()
    ]).finally(() => {
      this.changeFactory();
      this.changeInfinity(null);

      this.preload = false;
    });
  }

  public changeFactory() {
    this.factory = this.factory ?? this.factories[0];

    if (this.factory.id == null || this.factory.id !== this.filter.factory_id) {
      this.filter.infinity_id = null;
      this.workplace_name = "";
    }

    this.filter.factory_id = this.factory.id!;
  }

  private async loadWorkplaces() {
    return this.$api
      .get(user_routes.workplacesMe)
      .then(({ data: res }: { data: IUserWorkplace[] }) => {
        this.current_user_workplaces = res;

        this.started_infinity_id = res[0].infinity_id;

        this.started_infinities = res.reduce<StructureInfinity[]>(
          (acc, curr) => {
            if (curr.infinity_id) {
              acc.push({
                id: curr.infinity_id,
                full_name: curr.infinity_full_name!,
                level_name: curr.infinity_level_name!
              });
            }

            return acc;
          },
          []
        );
      });
  }

  private async loadFactory() {
    return this.$api
      .get(structure_routes.me_factory)
      .then(({ data: res }: { data: StructureFactory }) => {
        this.factories.push(res);
        this.factory = this.factories[0];
      });
  }

  private async loadFactories() {
    this.$api
      .get(structure_routes.factories, {
        params: {
          only_my: true,
          section_id: (this.$router.currentRoute.meta! as IRouteMeta)
            .permissions?.[0]
        }
      })
      .then(({ data: res }: { data: StructureFactory[] }) => {
        this.factories.push(...res);
        this.factory = this.factories.find(
          f => f.id === this.filter.factory_id
        )!;
      });
  }

  private async loadTemplates() {
    return this.$api
      .get(analytics_routes.templates)
      .then(({ data: res }: { data: AnalyticsFilter[] }) => {
        this.templates = res;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public applyTemplate(template: AnalyticsFilter) {
    this.$emit("applyTemplate", template);
  }

  public changeInfinity(data: IChangeEvent | null) {
    if (data) {
      this.workplace_name = data.workplace_name;
      this.filter.infinity_id = data.infinity_id;
    } else if (this.started_infinity_id) {
      this.filter.infinity_id = this.started_infinity_id;

      this.workplace_name =
        this.started_infinities.find(f => f.id === this.started_infinity_id)
          ?.full_name || "";
    } else {
      this.workplace_name = "";
      this.filter.infinity_id = null;
    }
  }

  public apply() {
    this.$emit("apply", true);
  }

  public clear() {
    const all_option = this.factories.findIndex(f => f.id === null) !== -1;

    if (all_option) {
      this.factory = null;
    } else {
      this.factory = this.factories.find(f => f.id === this.filter.factory_id)!;
    }

    this.changeFactory();
    this.changeInfinity(null);

    this.$emit("clear", true);
  }
}
