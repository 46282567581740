
import { Component, Vue } from "vue-property-decorator";

import Preloader from "@/components/Preloader.vue";
import Search from "@/components/Search.vue";
import CUserEdit from "@/components/user/UserEdit.vue";
import CGroupEdit from "@/components/user/GroupEdit.vue";

import { staffs_and_groups_child_routes } from "@/router/employees/users";

import StructureFactory from "@/models/structure/factory";

import CStaffsAndGroupStaffsFactoryUec from "./components/FactoryUec.vue";
import CStaffsAndGroupStaffsFactory from "./components/Factory.vue";
import CStaffsAndGroupStaffsStructure from "./components/Structure.vue";

import structure_routes from "@/api/routes/structures";
import userRoutes from "@/api/routes/users";
import UserUser from "@/models/user/user";
import { IRouteMeta } from "@/router/interface";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
  readonly users: UserUser[];
}

export interface IEditTreeUser {
  id: string;
  full_name: string;
  action: "user" | "group";
}

@Component({
  components: {
    Search,
    Preloader,
    CStaffsAndGroupStaffsFactoryUec,
    CStaffsAndGroupStaffsFactory,
    CStaffsAndGroupStaffsStructure,
    CUserEdit,
    CGroupEdit
  }
})
export default class VStaffsTree extends Vue {
  protected showEditModal: boolean = false;
  protected showGroupModal: boolean = false;

  protected editTreeUser: {
    id: string;
    full_name: string;
    action: string;
  } | null = null;

  protected links = staffs_and_groups_child_routes.map(r => {
    return {
      title: r.meta!.name,
      to: {
        name: r.name
      }
    };
  });

  private currentUser: IJWTUser | null = this.$store.state.currentUser;

  protected preload: boolean = false;
  private current_user_factory: StructureFactory | null = null;
  private only_current_user_factory = this.$api.allowOnlyUserWorkplaces();

  protected key: number = 0;

  protected search: string = this.$store.state.search;
  protected preload_infinite: boolean = false;
  protected searched_users: UserUser[] = [];
  protected per_page: number = 100;
  protected current_page: number = 0;
  protected next_page: boolean = false;

  protected watchers: Function[] = [];

  protected async created() {
    await this.loadFactory();

    this.watchers.push(
      this.$store.watch(
        state => state.editTreeUser,
        editTreeUser => {
          this.editTreeUser = editTreeUser;
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.currentUser = currentUser;
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.search,
        search => {
          this.search = search;
          if (this.search.length) {
            this.searchUsers();
          }
        }
      )
    );

    this.preload = true;

    await this.searchUsers();

    this.preload = false;
  }

  private async loadFactory() {
    return this.$api
      .get(structure_routes.me_factory)
      .then(({ data: res }: { data: StructureFactory }) => {
        this.current_user_factory = res;
      });
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  // protected closeGroupModal() {
  //   this.$store.commit("editTreeUser", null);
  // }

  protected changeEditUser({ user: editedUser }: { user: UserUser }) {
    this.$store.dispatch("editedTreeUser", editedUser);
  }

  protected cancelEditUser() {
    this.$store.commit("editTreeUser", null);
  }

  protected beforeCreateUser() {
    this.$store.commit("editTreeUser", {
      id: "",
      full_name: "",
      action: "user"
    });
  }

  protected async beforeUpdateUser(user_index: number) {
    const user = this.searched_users[user_index];

    this.$store.commit("editTreeUser", {
      id: user.id,
      full_name: user.full_name,
      action: "user"
    });
  }

  public allowWrite() {
    return this.$api.allowWrite();
  }

  protected async searchUsers(page: number = 0) {
    this.preload_infinite = true;

    if (page === 0) {
      this.searched_users = [];
    }

    let params;

    if (this.only_current_user_factory && this.current_user_factory) {
      params = {
        only_my: false,
        factory_id: this.current_user_factory.id
      };
    } else {
      params = {
        only_my: true
      };
    }

    this.$api
      .get(userRoutes.users_tree_search, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true,
          search: this.search,
          show_workplace: true,
          section_id: (this.$router.currentRoute.meta! as IRouteMeta)
            .permissions?.[0],
          ...params
        }
      })
      .then(({ data: res }: { data: IData }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;

        this.searched_users.push(...res.users);
      })
      .finally(() => {
        this.preload_infinite = false;
      });
  }

  protected infiniteHandler() {
    if (this.next_page && !this.preload_infinite) {
      this.searchUsers(this.current_page + 1);
    }
  }
}
