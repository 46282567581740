
import { Component, Vue, Prop } from "vue-property-decorator";
import {
  classToClass,
  plainToClass,
  plainToClassFromExist
} from "class-transformer";

import Preloader from "@/components/Preloader.vue";

import Workline from "./Workline.vue";
import CSkillRadio from "@/components/SkillRadio.vue";

import structureRoutes from "@/api/routes/structures";
import skillRoutes from "@/api/routes/skills";

import StructureWorkshop from "@/models/structure/workshop";
import StructureWorkline from "@/models/structure/workline";
import InventoryInventory from "@/models/inventory/inventory";
import BlockBlock, { IBlockSkill } from "@/models/block/block";
import CLevelSelector from "@/components/LevelSelector.vue";
import {
  updateSkillCheckboxesState,
  onSelectAllClicked,
  defaultBlocks
} from "@/helpers/update_skill_checkboxes_state";
import { EThreeStateCheckbox } from "@/enums/three_state_checkbox";
import SkillCategory from "@/models/skill/category";
import { onDisplayZeroLevelSkillsChanged } from "@/helpers/matrix_helper";

@Component({
  components: {
    Preloader,
    Workline,
    CSkillRadio,
    CLevelSelector
  },
  methods: {
    updateSkillCheckboxesState,
    onSelectAllClicked,
    onDisplayZeroLevelSkillsChanged
  },
  data: () => {
    return {
      defaultBlocks,
      EThreeStateCheckbox
    };
  }
})
export default class Workshop extends Vue {
  @Prop({ required: true }) protected workshop!: StructureWorkshop;
  @Prop({ required: true }) protected inventories!: InventoryInventory[];
  @Prop({ required: true }) protected currentUser!: IJWTUser | null;

  protected saveLoader: boolean = false;
  protected toggleSkillBlocks: boolean = false;

  protected worklines: StructureWorkline[] = [];

  protected editWorkline: StructureWorkline = new StructureWorkline();
  protected editWorklineIndex: number | null = null;
  protected editWorklineMatrix: BlockBlock[] = [];
  protected editWorklineErrors: string | string[] | null = null;

  protected editInventory: InventoryInventory | null = null;

  protected destroyWorklineModal: boolean = false;

  private currentBlockFilter: BlockBlock = defaultBlocks[0];
  protected blocksFilter: BlockBlock[] = [];
  private skillCategoriesFilter: SkillCategory[] = [{ id: 0, value: "Все" }];
  private currentSkillCategoriesFilter: SkillCategory = this
    .skillCategoriesFilter[0];
  protected searchSkills: string = "";
  /** Состояние чекбокса выбора всех навыков */
  private selectAllSkillsCheckboxState: EThreeStateCheckbox =
    EThreeStateCheckbox.UNCHECK;
  /** Показать навыки с целевым уровнем "0" */
  private displayZeroLevelSkills = false;

  $refs!: {
    blocks: HTMLFormElement[];
  };

  protected async created() {
    await Promise.all([this.loadWorklines(), this.loadCategories()]);
  }

  protected async loadWorklines() {
    return this.$api
      .get(structureRoutes.worklines, {
        params: { workshop_id: this.workshop.id }
      })
      .then(({ data: res }: { data: StructureWorkline[] }) => {
        res.forEach(r => (r.is_show = false));

        this.worklines = res;
      });
  }

  protected async loadWorkshop() {
    return this.$api
      .get(structureRoutes.workshop(this.workshop!.id!), {
        params: {
          matrix: true,
          inventory_id: this.workshop!.inventory_id
        }
      })
      .then(({ data: res }: { data: StructureWorkshop }) => {
        this.workshop.blocks = res.blocks;
      });
  }

  protected toggleWorkline(index: number) {
    const workline = this.worklines[index];

    workline.is_show = !workline.is_show;
  }

  protected showModal() {
    this.displayZeroLevelSkills = false;
    this.$modal.show(this.workshop!.id!);
  }

  protected hideModal() {
    this.$modal.hide(this.workshop!.id!);
  }

  protected async loadWorkline(
    id: string,
    inventory_id: string,
    update_full: boolean = true
  ) {
    this.currentBlockFilter = defaultBlocks[0];
    this.currentSkillCategoriesFilter = this.skillCategoriesFilter[0];
    this.searchSkills = "";
    this.selectAllSkillsCheckboxState = EThreeStateCheckbox.UNCHECK;

    return this.$api
      .get(structureRoutes.workline(id), {
        params: {
          matrix: true,
          workshop_id: this.workshop.id,
          inventory_id
        }
      })
      .then(({ data: res }: { data: StructureWorkline }) => {
        if (update_full) {
          this.editWorkline = res;
          this.editWorklineMatrix =
            classToClass(this.editWorkline.blocks) || [];
        } else {
          this.editWorkline.blocks = res.blocks;
        }

        this.blocksFilter = plainToClassFromExist(
          this.blocksFilter,
          res.blocks
        );

        this.editWorkline.blocks = onDisplayZeroLevelSkillsChanged(
          this.editWorkline.blocks || [],
          this.displayZeroLevelSkills
        );
      });
  }

  private loadCategories() {
    this.$api
      .get(skillRoutes.categories)
      .then(({ data: res }: { data: SkillCategory[] }) => {
        this.skillCategoriesFilter.push(...res);
      });
  }

  protected async changeInventory(obj: InventoryInventory) {
    await this.loadWorkline(this.editWorkline!.id! || "new", obj.id!, false);
  }

  protected async beforeCreateWorkline() {
    this.editWorklineIndex = null;
    this.editWorklineErrors = null;
    this.editInventory = null;

    await this.loadWorkshop();

    await this.loadWorkline("new", "");

    this.editWorkline.workshop_id = this.workshop.id;

    this.showModal();
  }

  protected changeSkillFilter() {
    this.editWorkline.blocks = [];
    this.blocksFilter.forEach(block => {
      if (
        block.id === this.currentBlockFilter.id ||
        !this.currentBlockFilter.id
      ) {
        const block_copy = plainToClass(BlockBlock, block);
        block_copy.skills = block_copy.skills?.filter(
          skill =>
            (skill.category_id === this.currentSkillCategoriesFilter.id ||
              !this.currentSkillCategoriesFilter.id) &&
            (skill.full_name
              ?.toLowerCase()
              .includes(this.searchSkills.trim()?.toLowerCase()) ||
              !this.searchSkills.trim())
        );
        if (block_copy.skills?.length) {
          this.editWorkline.blocks?.push(block_copy);
        }
      }
    });

    this.selectAllSkillsCheckboxState = updateSkillCheckboxesState(
      this.$refs,
      this.editWorkline.blocks,
      this.selectAllSkillsCheckboxState
    );
    this.editWorkline.blocks = onDisplayZeroLevelSkillsChanged(
      this.editWorkline.blocks || [],
      this.displayZeroLevelSkills
    );
  }

  protected changeLevel(
    skill: IBlockSkill,
    blockIndex: number,
    skillIndex: number
  ) {
    this.$set(
      this.editWorkline!.blocks![blockIndex]!.skills!,
      skillIndex,
      skill
    );
  }

  protected async createWorkline() {
    this.editWorkline.inventory_id = this.editInventory?.id || null;
    this.saveLoader = true;

    return this.$api
      .post(structureRoutes.worklines, this.editWorkline)
      .then(({ data: res }: { data: StructureWorkline }) => {
        this.worklines.push({ ...res, ...{ is_show: false } });

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.editWorklineErrors = res.data.error;
      })
      .finally(() => {
        this.saveLoader = false;
      });
  }

  protected async beforeUpdateWorkline(index: number) {
    await this.loadWorkshop();

    const workline = this.worklines[index];
    this.editWorklineIndex = index;
    this.editWorklineErrors = null;
    this.editInventory = this.inventories.find(
      i => i.id === workline.inventory_id
    )!;

    await this.loadWorkline(workline.id!, workline.inventory_id!);

    this.showModal();
  }

  protected async updateWorkline() {
    this.editWorkline.inventory_id = this.editInventory?.id || null;
    this.saveLoader = true;

    return this.$api
      .put(structureRoutes.workline(this.editWorkline.id!), this.editWorkline)
      .then(({ data: res }: { data: StructureWorkline }) => {
        const workline = this.worklines[this.editWorklineIndex!];

        workline.full_name = res.full_name;
        workline.inventory_id = res.inventory_id;
        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.editWorklineErrors = res.data.error;
      })
      .finally(() => {
        this.saveLoader = false;
      });
  }

  protected async destroyWorkline() {
    this.saveLoader = true;
    return this.$api
      .destroy(structureRoutes.workline(this.editWorkline.id!))
      .then(() => {
        this.$delete(this.worklines, this.editWorklineIndex!);
        this.$modal.hide("destroyWorklineModal");
        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.editWorklineErrors = res.data.error;
      })
      .finally(() => {
        this.saveLoader = false;
      });
  }

  public allowWrite() {
    return this.$api.allowWrite();
  }
}
