
import { Component, Vue } from "vue-property-decorator";
import { classToClass, plainToClass } from "class-transformer";

import Preloader from "@/components/Preloader.vue";
import Search from "@/components/Search.vue";
import CStructure, { IStructureProp } from "@/components/Structure.vue";
import CWorkplace from "@/views/skills/components/Workplace.vue";
import CFactorySkills from "@/views/skills/components/Factory.vue";

import skillRoutes from "@/api/routes/skills";
import pillarRoutes from "@/api/routes/pillars";
import blockRoutes from "@/api/routes/blocks";
import structureRoutes from "@/api/routes/structures";
import signsRoutes from "@/api/routes/signs";

import SkillCategory from "@/models/skill/category";
import SkillLocal, {
  ESkillCategory,
  skillDocumentTypes
} from "@/models/skill/local";
import PillarPillar from "@/models/pillar/pillar";
import BlockBlock from "@/models/block/block";
import StructureFactory from "@/models/structure/factory";
import UserWorkplace from "@/models/user/workplace";
import SignSign from "@/models/sign/sign";
import { phases } from "@/consts";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import {
  applicationTypes,
  educationTypes,
  EEducationTypes
} from "@/models/application/application";
import {
  blocksTabs,
  compulsoryTabs,
  ICompulsoryTabs,
  pillarsTabs
} from "@/models/tabs";
import aktRoutes from "@/api/routes/akt";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
}

interface IDataSkill extends IData {
  readonly skills: SkillLocal[];
}

interface IDataBlock extends IData {
  readonly blocks: BlockBlock[];
}

interface IDataSign extends IData {
  readonly signs: SignSign[];
}

@Component({
  components: {
    Preloader,
    Search,
    CStructure,
    CWorkplace,
    CFactorySkills
  },
  data: () => {
    return {
      applicationTypes,
      educationTypes,
      skillDocumentTypes,
      phases,
      ESkillCategory,
      compulsoryTabs,
      pillarsTabs,
      blocksTabs
    };
  }
})
export default class AllSkills extends Vue {
  @Validations()
  private validations = {
    editSkill: {
      full_name: {
        required
      }
    },
    editSkillCategory: {
      required
    }
  };

  private preload: boolean = false;
  private showFilter: boolean = false;

  private tabs = ["Данные", "Группы"];
  private currentTab: number = 0;

  private appointmentTabs = ["Рабочие места", "Навыки"];
  private currentAppointmentTab: number = 0;

  private per_page: number = 100;
  private current_page: number = 0;
  private next_page: boolean = false;

  private categories: SkillCategory[] = [];
  private categoriesTabs: SkillCategory[] = [{ id: 0, value: "Все" }];
  private currentCategoryTab = this.categoriesTabs[0];

  private pillars: PillarPillar[] = [];
  private pillarsTabs = pillarsTabs;

  private currentPillarTab = this.pillarsTabs[0];

  private blocksTabs = blocksTabs;

  private compulsoryTabs = compulsoryTabs;
  private currentCompulsoryTab?: ICompulsoryTabs = this.compulsoryTabs[0];

  private currentBlockTab = this.blocksTabs[0];

  private search: string = this.$store.state.search;

  private filters: ("block" | "pillar" | "category" | "compulsory")[] = [];

  private showNotInFactory: boolean = false;
  private factories: StructureFactory[] = [];
  private factory_id: string | null = null;
  private factory_uec: boolean = false;
  private structure: IStructureProp = {};
  private NullFactory: boolean = false;

  protected can_change_structure: boolean = false;

  private skills: SkillLocal[] = [];

  private signs: SignSign[] = [];
  private selectedSigns: SignSign[] = [];

  private editSkill: SkillLocal = new SkillLocal();
  private editSkillIndex: number | null = null;
  private editEquipmentSkillIndex: number | null = null;
  private editSkillErrors: string | null = null;

  private editSkillPillar: PillarPillar | null = null;
  private editSkillCategory: SkillCategory | null = null;
  private editSkillFactory: StructureFactory | null = null;

  private appointmentBlocks: BlockBlock[] = [];
  private appointmentWorkplaces: UserWorkplace[] = [];
  private appointmentError: string | null = null;

  private currentUser: IJWTUser | null = this.$store.state.currentUser;

  private watchers: Function[] = [];

  private async created() {
    this.preload = true;

    this.watchers.push(
      this.$store.watch(
        state => state.search,
        search => {
          this.search = search;
          this.clearSkills();
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.currentUser = currentUser;
        }
      )
    );

    await Promise.all([
      this.loadSkillCategories(),
      this.loadPillars(),
      this.loadBlocks(),
      this.loadSkills(),
      this.loadFactories(),
      this.loadSigns()
    ]);

    this.preload = false;
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  private async loadPillars() {
    return this.$api
      .get(pillarRoutes.pillars)
      .then(({ data: res }: { data: PillarPillar[] }) => {
        this.pillars = res;

        this.pillarsTabs = this.pillarsTabs.concat(res);
      });
  }

  private async loadBlocks() {
    return this.$api
      .get(blockRoutes.blocks)
      .then(({ data: res }: { data: IDataBlock }) => {
        this.blocksTabs = this.blocksTabs.concat(res.blocks);
      });
  }

  private async loadSkillCategories() {
    return this.$api
      .get(skillRoutes.categories)
      .then(({ data: res }: { data: SkillCategory[] }) => {
        this.categories = res.slice(0, 3);

        this.categoriesTabs.push(...res);
      });
  }

  private async loadSkills(page: number = 0) {
    let not_factory = false;
    if (this.currentCategoryTab.id === ESkillCategory.FUNCTIONAL) {
      not_factory = true;
    }

    return this.$api
      .get(skillRoutes.skills, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true,
          category_id: this.currentCategoryTab.id,
          pillar_id: this.currentPillarTab.id,
          block_id: this.currentBlockTab.id,
          not_factory: not_factory,
          search: this.search,
          only_is_local: false,
          only_is_not_workplace: true,
          is_compulsory_education: this.currentCompulsoryTab?.value
        }
      })
      .then(({ data: res }: { data: IDataSkill }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;

        this.skills.push(...res.skills);
      });
  }

  private async loadSkill(id: string, onlyBlock: boolean = false) {
    return this.$api
      .get(skillRoutes.skill(id), {
        params: {
          include: true,
          is_compulsory_education:
            this.editSkill.is_compulsory_education || false
        }
      })
      .then(async ({ data: res }: { data: SkillLocal }) => {
        if (!onlyBlock) {
          this.editSkill = plainToClass(SkillLocal, res);
          await this.loadSelectedSigns();
        } else {
          this.editSkill.blocks = res.blocks;
        }
      });
  }

  private async loadFactories() {
    return this.$api
      .get(structureRoutes.factories)
      .then(({ data: res }: { data: StructureFactory[] }) => {
        this.factories = res;
      });
  }

  protected changeSign() {
    if (this.editSkill.education_type === EEducationTypes.EXTERNAL) {
      this.selectedSigns = [];
    }
  }

  protected changeSkillCategory() {
    if (!this.editSkillCategory || this.editSkillCategory.id !== 2) {
      this.can_change_structure = false;
    } else {
      this.can_change_structure = true;
    }
  }

  private async loadSigns() {
    return this.$api
      .get(signsRoutes.signs, { params: { only_local: true } })
      .then(({ data: res }: { data: IDataSign }) => {
        this.signs.push(...res.signs);
      });
  }

  private async loadSelectedSigns() {
    if (this.editSkill!.id!) {
      return this.$api
        .get(skillRoutes.signs(this.editSkill.id))
        .then(({ data: res }: { data: SignSign[] }) => {
          this.selectedSigns.push(...res);
        });
    }
  }

  private async infiniteHandler() {
    if (this.next_page) {
      await this.loadSkills(this.current_page + 1);
    }
  }

  private async clearSkills() {
    this.current_page = 0;

    this.skills = [];
    await this.loadSkills();
  }

  private removeAppointmentWorkplace(index: number) {
    this.$delete(this.appointmentWorkplaces, index);
  }

  private async changeCategory(obj: SkillCategory) {
    this.currentCategoryTab = obj;

    const index = this.filters.findIndex(f => f === "category");
    if (index === -1) {
      if (this.currentCategoryTab.id !== this.categoriesTabs[0].id) {
        this.filters.push("category");
      }
    } else {
      if (this.currentCategoryTab.id === this.categoriesTabs[0].id) {
        this.currentCompulsoryTab = this.compulsoryTabs[0];

        this.filters.splice(index, 1);
      }
    }

    await this.clearSkills();
  }

  private async changePillar(obj: PillarPillar) {
    this.currentPillarTab = obj;

    const index = this.filters.findIndex(f => f === "pillar");
    if (index === -1) {
      if (obj.id !== this.pillarsTabs[0].id) {
        this.filters.push("pillar");
      }
    } else {
      if (obj.id === this.pillarsTabs[0].id) {
        this.filters.splice(index, 1);
      }
    }

    await this.clearSkills();
  }

  private async changeCompulsory() {
    this.currentCompulsoryTab =
      this.currentCompulsoryTab ?? this.compulsoryTabs[0];

    const index = this.filters.findIndex(f => f === "compulsory");
    if (index === -1) {
      if (this.currentCompulsoryTab!.value !== this.compulsoryTabs[0].value) {
        this.filters.push("compulsory");
      }
    } else {
      if (this.currentCompulsoryTab!.value === this.compulsoryTabs[0].value) {
        this.filters.splice(index, 1);
      }
    }

    await this.clearSkills();
  }

  private async changeBlock(obj: BlockBlock) {
    this.currentBlockTab = obj;

    const index = this.filters.findIndex(f => f === "block");
    if (index === -1) {
      if (obj.id !== this.blocksTabs[0].id) {
        this.filters.push("block");
      }
    } else {
      if (obj.id === this.blocksTabs[0].id) {
        this.filters.splice(index, 1);
      }
    }

    await this.clearSkills();
  }

  private async clearFilters() {
    this.currentBlockTab = this.blocksTabs[0];
    this.currentPillarTab = this.pillarsTabs[0];
    this.currentCategoryTab = this.categoriesTabs[0];
    this.currentCompulsoryTab = this.compulsoryTabs[0];
    this.filters = [];
    await this.clearSkills();
  }

  private showModal() {
    this.$v.$reset();
    this.currentTab = 0;
    this.$modal.show("editSkill");
  }

  private async hideModal() {
    this.clearSelected();
    this.$modal.hide("editSkill");
    this.editSkill.is_compulsory_education = false;
    await this.changeCompulsoryEducation();
  }

  private changeFactory(obj: StructureFactory) {
    this.factory_id = obj.id!;
    this.factory_uec = !!obj.uec;
  }

  private clearSelected() {
    this.editSkillPillar = null;
    this.editSkillCategory = null;
    this.editSkillFactory = null;
    this.factory_id = null;
    this.selectedSigns = [];

    this.structure = {
      workshop_id: this.editSkill?.workshop_id!,
      workline_id: this.editSkill?.workline_id!,
      equipment_id: this.editSkill?.equipment_id!,
      infinity_id: this.editSkill?.infinity_id!
    };
  }

  private async onClickAppointment(skill: SkillLocal) {
    this.appointmentWorkplaces = [];

    return this.$api
      .get(skillRoutes.skill(skill.id), {
        params: {
          include: true
        }
      })
      .then(({ data: res }: { data: SkillLocal }) => {
        this.appointmentBlocks = res
          .blocks!.filter(block => block.include_skill)
          .map(b => {
            const block = classToClass<BlockBlock>(b);
            block.skills = [{ ...res, level: 0 }];
            return block;
          });

        if (!this.appointmentBlocks.length) {
          this.$notify({
            group: "notifications",
            type: "warning",
            text: "Назначьте навыку хотя бы одну группу",
            speed: 500
          });
        }

        this.$modal.show("massAppointment");
      });
  }

  protected setTab(tab_index: number) {
    this.currentTab = tab_index;

    if (tab_index === 1) {
      this.loadSkill(this.editSkill.id || "new", true);
    }
  }

  private addAppointmentWorkspace() {
    this.appointmentWorkplaces.push(new UserWorkplace());
  }

  private async onMakeAppointment() {
    if (
      !this.appointmentWorkplaces ||
      !this.appointmentWorkplaces.length ||
      !this.appointmentWorkplaces.some(wp => wp.factory_id)
    ) {
      return (this.appointmentError = "Выберите хотя бы один завод");
    }

    this.appointmentWorkplaces = this.appointmentWorkplaces.filter(
      wp => wp.factory_id
    );

    await this.$api
      .post(skillRoutes.appointments, {
        blocks: this.appointmentBlocks,
        workplaces: this.appointmentWorkplaces
      })
      .then(() => {
        this.$modal.hide("massAppointment");
      })
      .catch(res => {
        this.appointmentError = res.data.error;
      });
  }

  private async beforeCreateSkill() {
    await this.loadSkill("new").then(_r => {
      this.editSkillIndex = null;
      this.editSkillErrors = null;

      this.clearSelected();
      this.changeSkillCategory();

      this.showModal();
    });
  }

  private async beforeUpdateSkill(
    currentSkill: SkillLocal,
    index: number = 0,
    NullFactory: boolean = false
  ) {
    this.NullFactory = NullFactory;
    let skill;
    if (
      this.currentCategoryTab.id === ESkillCategory.FUNCTIONAL &&
      !NullFactory
    ) {
      skill = currentSkill;
    } else {
      skill = this.skills[index];
    }

    if (skill.origin_id) {
      return;
    }

    await this.loadSkill(skill.id).then(() => {
      this.currentCategoryTab.id === ESkillCategory.FUNCTIONAL
        ? (this.editEquipmentSkillIndex = index)
        : (this.editSkillIndex = index);

      this.editSkillErrors = null;

      this.clearSelected();

      if (this.editSkill.pillar_id) {
        const pillar = this.pillars.find(
          p => this.editSkill.pillar_id === p.id
        );
        if (pillar) {
          this.editSkillPillar = pillar;
        }
      }

      const category = this.categories.find(
        c => this.editSkill.category_id === c.id
      );
      if (category) {
        this.editSkillCategory = category;
      }

      const factory = this.factories.find(
        f => this.editSkill.factory_id === f.id
      );
      if (factory) {
        this.editSkillFactory = factory;
        this.factory_id = factory?.id!;
        this.factory_uec = !!factory?.uec;
      }

      this.changeSkillCategory();

      this.showModal();
    });
  }

  private setSelected() {
    this.editSkill.category_id = this.editSkillCategory?.id!;
    this.editSkill.pillar_id = this.editSkillPillar?.id!;
    this.editSkill.factory_id = this.editSkillFactory?.id!;
    this.editSkill.signs = this.selectedSigns;
  }

  private changeStructure(obj: IStructureProp) {
    this.editSkill.workshop_id = obj.workshop_id;
    this.editSkill.workline_id = obj.workline_id;
    this.editSkill.equipment_id = obj.equipment_id;
    this.editSkill.infinity_id = obj.infinity_id;
  }

  private async createSkill() {
    this.$v.$touch();
    if (this.$v.$anyError) {
      return;
    }
    this.setSelected();

    this.editSkill.full_name = this.editSkill.full_name?.trim();

    if (!this.editSkill.full_name) {
      this.editSkillErrors = "Введите название";
      return;
    }

    if (!this.editSkill.category_id) {
      this.editSkillErrors = "Укажите тип";
      return;
    }

    if (this.editSkill.is_compulsory_education) {
      if (!this.editSkill.application_type) {
        this.editSkillErrors = "Укажите тип обучения";
        return;
      }

      if (!this.editSkill.education_type) {
        this.editSkillErrors = "Укажите вид обучения";
        return;
      }

      if (this.editSkill.documentary_evidence) {
        if (!this.editSkill.document_type) {
          this.editSkillErrors = "Укажите тип документа";
          return;
        }

        if (this.editSkill.first_notification <= 0) {
          this.editSkillErrors = "Укажите кол-во месяцев до 1 уведомления";
          return;
        }

        if (this.editSkill.deadline <= 0) {
          this.editSkillErrors = "Укажите срок действия удостоверения";
          return;
        }
      }
    }

    await this.$api
      .post(skillRoutes.skills, this.editSkill)
      .then(({ data: res }: { data: SkillLocal }) => {
        if (
          this.currentCategoryTab.id === res.category_id ||
          this.currentCategoryTab.id === 0
        ) {
          if (
            this.currentCategoryTab.id === ESkillCategory.FUNCTIONAL &&
            !this.NullFactory
          ) {
            this.$store.commit("editedTreeSkill", res);
          } else {
            this.skills.unshift(res);
          }
        }

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.editSkillErrors = res.data.error;
      });
  }

  private async updateSkill() {
    this.$v.$touch();
    if (this.$v.$anyError) {
      return;
    }
    this.setSelected();

    this.editSkill.full_name = this.editSkill.full_name?.trim();

    if (this.editSkill.is_compulsory_education) {
      if (!this.editSkill.application_type) {
        this.editSkillErrors = "Укажите тип обучения";
        return;
      }

      if (!this.editSkill.education_type) {
        this.editSkillErrors = "Укажите вид обучения";
        return;
      }

      if (this.editSkill.documentary_evidence) {
        if (!this.editSkill.document_type) {
          this.editSkillErrors = "Укажите тип документа";
          return;
        }

        if (this.editSkill.first_notification <= 0) {
          this.editSkillErrors = "Укажите кол-во месяцев до 1 уведомления";
          return;
        }

        if (this.editSkill.deadline <= 0) {
          this.editSkillErrors = "Укажите срок действия удостоверения";
          return;
        }
      }
    }

    await this.$api
      .put(skillRoutes.skill(this.editSkill.id), this.editSkill)
      .then(({ data: res }: { data: SkillLocal }) => {
        if (
          this.currentCategoryTab.id === ESkillCategory.FUNCTIONAL &&
          !this.NullFactory
        ) {
          this.$store.commit("editedTreeSkill", res);
        } else {
          this.$set(this.skills, this.editSkillIndex!, res);
        }

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.editSkillErrors = res.data.error;
      });
  }

  private async destroySkill() {
    await this.$api
      .destroy(skillRoutes.skill(this.editSkill.id))
      .then(({ data: res }: { data: SkillLocal }) => {
        const skill = plainToClass(SkillLocal, res);
        if (
          this.currentCategoryTab.id === ESkillCategory.FUNCTIONAL &&
          !this.NullFactory
        ) {
          skill.deleted = true;
          this.$store.commit("editedTreeSkill", skill);
        } else {
          this.$delete(this.skills, this.editSkillIndex!);
        }
        this.$modal.hide("destroySkillModal");
        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.editSkillErrors = res.data.error;
      });
  }

  private changeCompulsoryEducation() {
    if (!this.editSkill.is_compulsory_education) {
      this.editSkill.application_type = null;
      this.editSkill.education_type = null;
      this.editSkill.documentary_evidence = false;
      this.editSkillCategory = this.categoriesTabs[1];

      this.changeDocumentaryEvidence();
    } else {
      this.editSkillCategory = this.categoriesTabs[4];
    }
  }

  private changeDocumentaryEvidence() {
    this.editSkill.deadline = 10;
    this.editSkill.first_notification = 3;
    this.editSkill.next_notification = 1;
    this.editSkill.document_type = null;
  }

  protected async downloadMentorReport() {
    await this.$api.get(skillRoutes.mentor_report).then(res => {
      const fileLink = document.createElement("a");

      fileLink.href = res.data;
      fileLink.setAttribute("download", `mentor_report.csv`);

      fileLink.click();

      fileLink.remove();
    });
  }

  private allowWrite() {
    return this.$api.allowWrite();
  }
}
