
import { Component, Vue, Prop } from "vue-property-decorator";

import programs_routes from "@/api/routes/programs";
import users_routes from "@/api/routes/users";

import { ESkillCategory } from "@/models/skill/category";
import UserUser from "@/models/user/user";
import { EProgramVersionStatus } from "@/models/program/version";
import { EAdmissionPermission } from "@/models/admission/permission";

@Component({
  name: "CProgramApproval"
})
export default class CProgramApproval extends Vue {
  @Prop({ required: true }) private program_id!: string;

  private watchers: Function[] = [];

  private skill_category_id: ESkillCategory | null = this.$store.state
    .programSkillCategoryId;

  public need_responsibles: boolean = false;
  public responsibles: UserUser[] = [];
  public selected_responsible_id: string | null = null;
  public selected_responsible_errors: string | null = null;

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async created() {
    this.changeNeedResponsibles();

    this.watchers.push(
      this.$store.watch(
        state => state.programSkillCategoryId,
        programSkillCategoryId => {
          this.skill_category_id = programSkillCategoryId;

          this.changeNeedResponsibles();
        }
      )
    );

    await this.loadResponsibles();
  }

  private changeNeedResponsibles() {
    this.need_responsibles =
      this.skill_category_id !== ESkillCategory.FUNCTIONAL_ID;
  }

  public async showApprovalModal() {
    if (this.need_responsibles) {
      this.$modal.show("approval_program_modal");
    } else {
      await this.sendToApproval();
    }
  }

  public hideApprovalModal() {
    this.$modal.hide("approval_program_modal");
  }

  private async loadResponsibles() {
    return this.$api
      .get(users_routes.users, {
        params: {
          paginate: false,
          only_my: false,
          permission_id: EAdmissionPermission.EDUCATION_PROGRAMS
        }
      })
      .then(({ data: res }: { data: { users: UserUser[] } }) => {
        this.responsibles = res.users;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public async sendToApproval() {
    this.selected_responsible_errors = null;

    if (this.need_responsibles && !this.selected_responsible_id) {
      this.selected_responsible_errors =
        "Выберите сотрудников для согласования программы";

      return;
    }

    return this.$api
      .post(programs_routes.send_to_approval(this.program_id), {
        responsible_ids:
          this.selected_responsible_id != null
            ? [this.selected_responsible_id]
            : []
      })
      .then(({ data: res }: { data: { status_id: EProgramVersionStatus } }) => {
        this.$store.commit("programStatusId", res.status_id);

        this.hideApprovalModal();

        this.$notify({
          group: "notifications",
          type: "success",
          text: this.need_responsibles
            ? "Программа обучения направлена на согласование, вы получите уведомление по результату"
            : "Программа обучения отправлена на согласование Руководителю",
          speed: 500
        });
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }
}
