
import { Component, Vue, Prop } from "vue-property-decorator";

import program_routes from "@/api/routes/programs";

import { IProgramAuthor, IProgramSettings } from "@/models/program/program";

import CProgramCopy from "./Copy.vue";
import CProgramApproval from "./Approval.vue";
import CConstructorProgramsSettings from "./Settings.vue";
import CProgramAgreementHistory from "./AgreementHistory.vue";
import CProgramAgreement from "./Agreement.vue";
import {
  IProgramAgreementBase,
  IProgramAgreementHistory
} from "@/models/program/agreement";

@Component({
  name: "CConstructorProgramsHeader",
  components: {
    CConstructorProgramsSettings,
    CProgramAgreementHistory,
    CProgramAgreement,
    CProgramCopy,
    CProgramApproval
  }
})
export default class CConstructorProgramsHeader extends Vue {
  @Prop({ required: true }) public factory_id!: string;
  @Prop({ required: false, default: false }) public disabled!: boolean;
  @Prop({ required: false, default: false }) public changes_exist!: boolean;

  @Prop({ required: false, default: null })
  public program_id!: string | null;

  @Prop({ required: false, default: null })
  public program_version_id!: string | null;

  @Prop({ required: false, default: null })
  public program_version_days_to_study!: number | null;

  @Prop({ required: false, default: 1 })
  public version!: number;

  @Prop({ required: false, default: null })
  public settings!: IProgramSettings | null;

  @Prop({ required: false, default: null })
  public authors!: IProgramAuthor[] | null;

  @Prop({ required: false, default: null })
  public agreement!: IProgramAgreementBase | null;

  @Prop({ required: false, default: null })
  public agreement_histories!: IProgramAgreementHistory[] | null;

  public publish_errors: string | null = null;
  public edit_message: string = this.$store.state.programEditMessage;
  public is_open_settings: boolean = true;

  public current_author: IProgramAuthor | null = null;

  private watchers: Function[] = [];

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected created() {
    if (this.authors?.length) {
      this.current_author = this.authors[0];
    }

    this.watchers.push(
      this.$store.watch(
        state => state.programEditMessage,
        programEditMessage => {
          this.edit_message = programEditMessage;
        }
      )
    );
  }

  public showDeleteModal() {
    this.$modal.show("programDeleteModal");
  }

  public hideDeleteModal() {
    this.$modal.hide("programDeleteModal");
  }

  public changedAuthor(author: IProgramAuthor) {
    this.current_author = author;
  }

  public async programDelete() {
    if (this.program_id) {
      return this.$api
        .post(program_routes.archived(this.program_id), {})
        .then(() => {
          this.$router.push({ name: "constructor" });
        })
        .catch(({ response: res }) => {
          this.$notify({
            group: "notifications",
            type: "error",
            text: res.data.error,
            speed: 500
          });
        });
    }
  }

  public changesExist() {
    this.$emit("changesExist", true);
  }

  public allowWrite() {
    return this.$api.allowWrite();
  }
}
