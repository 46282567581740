
import { Component, Vue } from "vue-property-decorator";

import { kpi_settings_child_routes } from "@/router/admin/kpi";

import Preloader from "@/components/Preloader.vue";

import kpiRoutes from "@/api/routes/kpi";

interface IUser {
  id: string;
  full_name: string;
  email: string;
  identification_number: string;
}

interface ILeader extends IUser {
  is_show: boolean;
  subordinates_count: number;
  subordinates: IUser[];
}

@Component({
  components: {
    Preloader
  }
})
export default class VKpiSettingUser extends Vue {
  protected preload: boolean = false;

  protected leaderUsers: IUser[] = [];
  protected filteredLeaderUsers: IUser[] = [];
  protected selectedLeaders: Set<string> = new Set();
  protected searchLeader: string = "";

  protected subordinateUsers: IUser[] = [];
  protected filteredSubordinateUsers: IUser[] = [];
  protected selectedSubordinates: Set<string> = new Set();
  protected searchSubordinate: string = "";

  protected leaders: ILeader[] = [];
  protected openedLeaders: Set<string> = new Set();
  protected factory_id: string | null = null;

  protected changeSearchLeader() {
    this.filteredLeaderUsers = this.leaderUsers.filter(
      u =>
        u.full_name.toLowerCase().indexOf(this.searchLeader.toLowerCase()) !==
        -1
    );
  }

  protected changeSearchSubordinate() {
    this.filteredSubordinateUsers = this.subordinateUsers.filter(
      u =>
        u.full_name
          .toLowerCase()
          .indexOf(this.searchSubordinate.toLowerCase()) !== -1
    );
  }

  protected changeLeaderChecked(id: string) {
    if (this.selectedLeaders.has(id)) {
      this.selectedLeaders.delete(id);
    } else {
      this.selectedLeaders.add(id);
    }

    // Set не реактивен во 2-рой версии вью.
    this.selectedLeaders = new Set(this.selectedLeaders);
  }

  protected changeSubordinateChecked(id: string) {
    if (this.selectedSubordinates.has(id)) {
      this.selectedSubordinates.delete(id);
    } else {
      this.selectedSubordinates.add(id);
    }

    // Set не реактивен во 2-рой версии вью.
    this.selectedSubordinates = new Set(this.selectedSubordinates);
  }

  protected changeLeaderOpen(id: string) {
    if (this.openedLeaders.has(id)) {
      this.openedLeaders.delete(id);
    } else {
      this.openedLeaders.add(id);
    }

    // Set не реактивен во 2-рой версии вью.
    this.openedLeaders = new Set(this.openedLeaders);
  }

  protected links = kpi_settings_child_routes.map(r => {
    return {
      title: r.meta!.name,
      to: {
        name: r.name
      }
    };
  });

  protected async created() {
    this.preload = true;

    await Promise.all([this.loadUsers(), this.loadParams()]);

    this.preload = false;
  }

  protected async loadUsers() {
    return this.$api
      .get(kpiRoutes.get_setting_users)
      .then(
        ({ data: res }: { data: { factory_id: string; users: IUser[] } }) => {
          this.leaderUsers = Array.from(res.users);
          this.filteredLeaderUsers = Array.from(res.users);

          this.subordinateUsers = Array.from(res.users);
          this.filteredSubordinateUsers = Array.from(res.users);

          this.factory_id = res.factory_id;
        }
      );
  }

  protected async loadParams() {
    return this.$api
      .get(kpiRoutes.setting_users)
      .then(({ data: res }: { data: ILeader[] }) => {
        this.leaders = res;
      });
  }

  protected async saveSettings() {
    if (!this.allowWrite()) {
      return;
    }

    const leader_ids = Array.from(this.selectedLeaders);
    const subordinate_ids = Array.from(this.selectedSubordinates);

    if (!leader_ids.length) {
      this.$notify({
        group: "notifications",
        type: "error",
        text: "Укажите назначающих",
        speed: 500
      });

      return;
    }

    if (!subordinate_ids.length) {
      this.$notify({
        group: "notifications",
        type: "error",
        text: "Укажите участников",
        speed: 500
      });

      return;
    }

    return this.$api
      .post(kpiRoutes.setting_users, {
        factory_id: this.factory_id,
        leader_ids,
        subordinate_ids
      })
      .then(({ data: res }: { data: ILeader[] }) => {
        this.leaders = res;
      });
  }

  protected async deleteLeader(leader_id: string, leader_index: number) {
    if (!this.allowWrite()) {
      return;
    }

    return this.$api
      .post(kpiRoutes.post_setting_users_delete, {
        leader_id,
        factory_id: this.factory_id
      })
      .then(() => {
        this.$delete(this.leaders, leader_index);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async deleteSubordinate(
    leader_id: string,
    subordinate_id: string,
    leader_index: number,
    subordinate_index: number
  ) {
    if (!this.allowWrite()) {
      return;
    }

    return this.$api
      .post(kpiRoutes.post_setting_users_delete, {
        leader_id,
        subordinate_id,
        factory_id: this.factory_id
      })
      .then(() => {
        this.leaders[leader_index].subordinates_count--;

        this.$delete(
          this.leaders[leader_index].subordinates,
          subordinate_index
        );
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected allowWrite() {
    return this.$api.allowWrite();
  }
}
