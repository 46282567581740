
import { Component, Vue } from "vue-property-decorator";

import Search from "@/components/Search.vue";

import structureRoutes from "@/api/routes/structures";
import positionRoutes from "@/api/routes/positions";

import CPosition from "./components/Position.vue";

import StructureFactory from "@/models/structure/factory";
import PositionOrigin from "@/models/position/origin";
import { EImportType } from "../../enums/import_type";
import CImportModal from "../../components/ImportModal.vue";
import CFileDownload from "../../components/FileDownload.vue";
import { IRouteMeta } from "@/router/interface";

@Component({
  name: "VPositionPositions",
  components: {
    CPosition,
    Search,
    CImportModal,
    CFileDownload
  }
})
export default class VPositionPositions extends Vue {
  public EImportType = EImportType;

  protected factories: StructureFactory[] = [];
  protected origins: PositionOrigin[] = [{ id: null, name: "Без профиля" }];

  protected currentFactory: StructureFactory | null = null;

  private currentUser: IJWTUser | null = this.$store.state.currentUser;

  $refs!: {
    tabs: HTMLFormElement;
  };

  protected watchers: Function[] = [];

  protected async created() {
    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.currentUser = currentUser;
        }
      )
    );

    const promises = [this.loadOrigins()];

    if (this.$api.allowOnlyUserWorkplaces()) {
      promises.push(this.loadFactory());
    } else {
      promises.push(this.loadFactories());
    }

    await Promise.all(promises);
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async loadFactories() {
    return this.$api
      .get(structureRoutes.factories, {
        params: {
          only_my: true,
          section_id: (this.$router.currentRoute.meta! as IRouteMeta)
            .permissions?.[0]
        }
      })
      .then(({ data: res }: { data: StructureFactory[] }) => {
        this.factories = res;

        if (this.factories.length) {
          this.currentFactory = { ...this.factories[0] };
        }
      });
  }

  private async loadFactory() {
    return this.$api
      .get(structureRoutes.me_factory)
      .then(({ data: res }: { data: StructureFactory }) => {
        this.factories = [res];

        this.currentFactory = { ...this.factories[0] };
      });
  }

  protected async loadOrigins() {
    return this.$api
      .get(positionRoutes.origins)
      .then(({ data: res }: { data: PositionOrigin[] }) => {
        this.origins = this.origins.concat(res);
      });
  }

  protected showFactory(factory: StructureFactory) {
    this.currentFactory = { ...factory };
  }

  protected tabScrollRight() {
    this.$refs.tabs.scrollLeft += 100;
  }

  protected tabScrollLeft() {
    this.$refs.tabs.scrollLeft -= 100;
  }

  public allowWrite() {
    return this.$api.allowWrite();
  }
}
