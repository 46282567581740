
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import pdf from "vue-pdf";

// https://github.com/FranckFreiburger/vue-pdf

@Component
export default class PdfView extends Vue {
  @Prop({ required: true }) protected url!: string;
  @Prop({ default: () => true }) protected preview!: boolean;

  protected pdfSrc = this.$api.cdn_url + this.url;

  protected currentPage: number = 0;
  protected numPages: number = 0;
  protected loadPdf = pdf.createLoadingTask(this.pdfSrc);

  protected mounted() {
    this.loadPdf.promise.then((res: any) => {
      this.numPages = res.numPages;
    });
  }

  protected created() {
    this.changeUrl();
  }

  @Watch("url")
  protected changeUrl() {
    this.pdfSrc = RegExp(this.$api.regex_url).test(this.url)
      ? this.url
      : this.$api.cdn_url + this.url;
    this.currentPage = 0;
    this.numPages = 0;
  }
}
