var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"c-constructor-programs-index-content tree-item tree-item--big"},[(_vm.preload)?[_c('Preloader')]:(_vm.programs.length)?[_c('div',{staticClass:"tree-item"},[_c('div',{staticClass:"row"},_vm._l((_vm.programs),function(program){return _c('div',{key:program.id,staticClass:"col-12 col-sm-6 col-md-4 b-24 d-flex flex-column"},[_c('router-link',{staticClass:"program-item d-flex flex-column flex-fill",style:({
              color: program.color,
              backgroundColor: `${program.color}`
            }),attrs:{"to":{
              name: 'constructor_programs_edit',
              params: { id: program.id, factory: _vm.factory_id }
            },"tag":"div"}},[(program.equipment_name || program.workline_name)?_c('div',{staticClass:"font-10 b-8",class:{ 'font-white-opacity': program.color }},[_c('span',[_vm._v(_vm._s(program.equipment_name))]),_c('span',[_vm._v(_vm._s(program.workline_name))])]):_vm._e(),_c('h5',{staticClass:"font-superbold font-16 font-title font-wrapall",class:{ 'font-white': program.color }},[_vm._v(" "+_vm._s(program.title)+" ")])])],1)}),0)])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }