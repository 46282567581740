
import { Component, Vue, Prop } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import { minLength, required } from "vuelidate/lib/validators";
import { phases } from "@/consts";
import { ru } from "vuejs-datepicker/dist/locale";
import { plainToClass } from "class-transformer";

import userRoutes from "@/api/routes/users";
import structureRoutes from "@/api/routes/structures";
import skillRoutes from "@/api/routes/skills";
import positionRoutes from "@/api/routes/positions";
import signRoutes from "@/api/routes/signs";

import admission_routes from "@/api/routes/admission";

import { EDeviceTypes } from "@/enums/deviceTypes";

import CWorkplace from "@/views/staffs_and_groups/staffs/components/Workplace.vue";
import Preloader from "@/components/Preloader.vue";
import CPaginatedSelect from "@/components/PaginatedSelect.vue";
import CFileDownload from "@/components/FileDownload.vue";
import Upload from "@/components/Upload.vue";
import CSkillFilter from "@/components/SkillFilter.vue";
import CSkillRadio from "@/components/SkillRadio.vue";
import CStructure, { IStructureProp } from "@/components/Structure.vue";

import BlockBlock, { IBlockSkill } from "@/models/block/block";
import StructureFactory from "@/models/structure/factory";
import UserWorkplace, { IUserWorkplace } from "@/models/user/workplace";
import UserUser from "@/models/user/user";
import SignUser from "@/models/sign/user";
import SkillLocal from "@/models/skill/local";
import UserDocument from "@/models/user/document";
import SkillCategory from "@/models/skill/category";
import { ERole } from "@/enums/roles";
import { ILevelForFilter } from "@/models/position/level";
import users from "@/api/routes/users";
import {
  updateSkillCheckboxesState,
  onSelectAllClicked,
  defaultBlocks
} from "../../helpers/update_skill_checkboxes_state";
import CLevelSelector from "../LevelSelector.vue";
import { EThreeStateCheckbox } from "../../enums/three_state_checkbox";
import { onDisplayZeroLevelSkillsChanged } from "../../helpers/matrix_helper";
import { CurrencyInput } from "vue-currency-input";
import { AdmissionRole } from "@/models/admission/role";
import { AdmissionPermissionText } from "@/models/admission/permission";
import { IRouteMeta } from "@/router/interface";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
  readonly users: UserUser[];
}

interface IDataSign {
  readonly current_page: number;
  readonly next_page: boolean;
  readonly signUsers: SignUser[];
}

@Component({
  components: {
    CurrencyInput,
    Preloader,
    CWorkplace,
    CStructure,
    CPaginatedSelect,
    CSkillFilter,
    CFileDownload,
    Upload,
    CSkillRadio,
    CLevelSelector
  },
  methods: {
    updateSkillCheckboxesState,
    onSelectAllClicked,
    onDisplayZeroLevelSkillsChanged
  },
  data: () => {
    return {
      ru,
      EDeviceTypes,
      defaultBlocks,
      EThreeStateCheckbox
    };
  }
})
export default class CUserEdit extends Vue {
  @Prop({ required: false }) protected user_id?: string;
  @Prop({ default: false }) protected canDelete!: boolean;

  @Validations()
  protected validations = {
    editUser: {
      password: {
        required,
        minLength: minLength(4)
      },
      identification_number: {
        required
      }
    }
  };

  /** Состояние чекбокса выбора всех навыков на вкладке Целевые навыки*/
  private targetSkillSelectAllSkillsCheckboxState: EThreeStateCheckbox =
    EThreeStateCheckbox.UNCHECK;
  /** Состояние чекбокса выбора всех навыков на вкладке Фактические навыки*/
  private currentSkillSelectAllSkillsCheckboxState: EThreeStateCheckbox =
    EThreeStateCheckbox.UNCHECK;
  /** Показать целевые навыки с целевым уровнем "0" */
  private displayZeroLevelTargetSkills = false;
  /** Показать фактические навыки с целевым уровнем "0" */
  private displayZeroLevelCurrentSkills = false;

  protected displayAnotherLevelTargetSkills = false;
  protected displayAnotherLevelCurrentSkills = false;

  protected email_taken: boolean = false;
  protected identification_number_taken: boolean = false;

  protected toggleSkillTarget: boolean = false;
  protected toggleSkillCurrent: boolean = false;

  private currentUser: IJWTUser | null = this.$store.state.currentUser;

  protected currentTab: number = 0;
  protected tabs = [
    "Данные",
    "Роли",
    "Доступы",
    "Внешнее обучение",
    "Места",
    "Целевые навыки",
    "Фактические навыки"
  ];

  protected editUser = new UserUser();
  protected editUserSameFactoryError: boolean | null = null;
  protected editUserErrors: string | null = null;
  protected editUserSkillCurrent: BlockBlock[] = [];
  protected editUserSkillTarget: BlockBlock[] = [];
  protected editUserPositions: Map<string, string> = new Map();

  protected nextUserPositionLevels: Map<string, string> = new Map();
  protected editUserPositionLevels: Map<string, string> = new Map();

  protected editUserWorkplaces: IUserWorkplace[] | any[] = [];
  protected selectedUserWorkplaceCurrent?: IUserWorkplace | null = null;

  protected selectedUserWorkplaceTarget?: IUserWorkplace | null = null;

  protected skills: SkillLocal[] = [];

  private factoryFilter: StructureFactory | null = null;
  private factory_id: string | null = null;
  private factory_uec: boolean = false;
  private structure: IStructureProp = {};

  protected signs: SignUser[] = [];
  protected selectedSigns: SignUser[] = [];

  // Линейный руководитель
  public selected_line_supervisors: UserUser[] = [];
  // Функциональный руководитель
  public selected_functional_supervisors: UserUser[] = [];
  // per_page для руководителей
  public supervisors_per_page: number = 50;

  protected factories: StructureFactory[] = [];
  protected levelsForFilter: ILevelForFilter = {};

  protected acceptFiles: string[] = ["application/pdf"];

  protected preload: boolean = false;
  protected phases = phases;

  protected filters: Map<string, boolean> = new Map();

  private targetCurrentBlockFilter: BlockBlock = defaultBlocks[0];
  private currentCurrentBlockFilter: BlockBlock = defaultBlocks[0];
  protected targetBlocksFilter: BlockBlock[] | null = null;
  protected currentBlocksFilter: BlockBlock[] | null = null;

  protected skillCategoriesFilter: SkillCategory[] = [{ id: 0, value: "Все" }];
  protected currentSkillCategoryFilter: SkillCategory = this
    .skillCategoriesFilter[0];
  private targetSkillCategoryFilter: SkillCategory = this
    .skillCategoriesFilter[0];

  private watchers: Function[] = [];

  protected search: string = this.$store.state.search;
  protected search_target: string = "";
  protected search_current: string = "";

  protected removeUserWorkplaceModal: boolean = false;
  protected removeUserWorkplaceIndex: number = 0;

  public roles: AdmissionRole[] = [];
  public AdmissionPermissionText = AdmissionPermissionText;

  $refs!: {
    target: HTMLFormElement[];
    current: HTMLFormElement[];
  };

  protected async created() {
    this.displayZeroLevelTargetSkills = false;
    this.displayZeroLevelCurrentSkills = false;
    this.preload = true;

    this.watchers.push(
      this.$store.watch(
        state => state.search,
        search => {
          this.search = search;
          this.clearCurrentSkills();
          this.clearTargetSkills();
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.currentUser = currentUser;
        }
      )
    );

    if (this.user_id) {
      await Promise.all([
        this.loadSigns(),
        this.loadSelectedSigns(),
        this.loadLevelFilters(),
        this.loadSelectedLineSupervisors(),
        this.loadSelectedFunctionalSupervisors(),
        this.loadUser(this.user_id),
        this.loadSkillCategories(),
        this.loadEditUserWorkplaces(),
        this.loadUserRoles()
      ]);

      (this.editUser.workplaces || []).forEach(wp => {
        this.editUserPositions.set(wp.id, wp.position_id!);

        if (wp.next_position_level_id) {
          this.nextUserPositionLevels.set(wp.id, wp.next_position_level_id);
        }

        if (wp.target_position_level_id) {
          this.editUserPositionLevels.set(wp.id, wp.target_position_level_id);
        }
      });

      await Promise.all([
        this.loadFactories(),
        this.loadSkillCurrent(),
        this.loadSkillTarget()
      ]);
    } else {
      await Promise.all([
        this.loadFactories(),
        this.loadSkillCurrent(),
        this.loadSkillTarget(),
        this.loadSigns(),
        this.loadLevelFilters(),
        this.loadSkillCategories()
      ]);
    }

    await this.loadLocalSkills();

    this.preload = false;
  }

  protected mounted() {
    this.showEditModal();
  }

  private changeFactory() {
    this.factoryFilter = this.factoryFilter ?? this.factories[0];
    this.factory_id = this.factoryFilter?.id!;
    this.factory_uec = this.factoryFilter?.uec!;
    this.loadLocalSkills();
  }

  private changeStructureFilter(structure: IStructureProp) {
    this.structure = structure;
    this.loadLocalSkills();
  }

  protected async loadUser(id: string) {
    this.editUserPositions.clear();
    this.editUserPositionLevels.clear();
    this.nextUserPositionLevels.clear();

    return this.$api
      .get(userRoutes.user(id), {
        params: {
          workplaces: true
        }
      })
      .then(({ data: res }: { data: UserUser }) => {
        this.editUser = plainToClass(UserUser, res);
      });
  }

  private async loadUserRoles() {
    if (!this.user_id) {
      return;
    }

    return this.$api
      .get(admission_routes.user_roles(this.user_id))
      .then(({ data: res }: { data: AdmissionRole[] }) => {
        this.roles = plainToClass(AdmissionRole, res);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async loadLevelFilters() {
    return this.$api
      .get(positionRoutes.levels_filter)
      .then(({ data: res }: { data: ILevelForFilter }) => {
        this.levelsForFilter = res;
      });
  }

  /**
   * Линейные руководители
   */
  public async loadLineSupervisors(
    page: number = 0,
    search_query: string
  ): Promise<UserUser[]> {
    let exclude_current_user: boolean = false;
    let exclude_user_ids: string[] = [];

    if (this.editUser.id != null) {
      exclude_user_ids = [this.editUser.id];

      if (this.editUser.id === this.currentUser!.id) {
        exclude_current_user = true;
      }
    }

    return this.$api
      .get(userRoutes.users, {
        params: {
          page,
          per_page: this.supervisors_per_page,
          paginate: true,
          role: ERole.supervisor,
          search: search_query,
          exclude_current_user,
          exclude_user_ids
        }
      })
      .then(({ data: res }: { data: IData }) => {
        if (res.users.length) {
          return res.users;
        }

        return [];
      });
  }

  /**
   * Выбранные линейные руководители
   */
  private async loadSelectedLineSupervisors() {
    return this.$api
      .get(userRoutes.supervisors_line(this.user_id!))
      .then(({ data: res }: { data: UserUser[] }) => {
        this.selected_line_supervisors.push(...res);
      });
  }

  public changeLineSupervisors(users: UserUser[]) {
    this.selected_line_supervisors = users;
  }

  /**
   * Функциональные руководители
   */
  public async loadFunctionalSupervisors(
    page: number = 0,
    search_query: string
  ): Promise<UserUser[]> {
    let exclude_current_user: boolean = false;
    let exclude_user_ids: string[] = [];

    if (this.editUser.id != null) {
      exclude_user_ids = [this.editUser.id];

      if (this.editUser.id === this.currentUser!.id) {
        exclude_current_user = true;
      }
    }

    return this.$api
      .get(userRoutes.users, {
        params: {
          page,
          per_page: this.supervisors_per_page,
          paginate: true,
          role: ERole.supervisor,
          search: search_query,
          exclude_current_user,
          exclude_user_ids
        }
      })
      .then(({ data: res }: { data: IData }) => {
        if (res.users.length) {
          return res.users;
        }

        return [];
      });
  }

  /**
   * Выбранные функциональные руководители
   */
  private async loadSelectedFunctionalSupervisors() {
    return this.$api
      .get(userRoutes.supervisors_functional(this.user_id!))
      .then(({ data: res }: { data: UserUser[] }) => {
        this.selected_functional_supervisors.push(...res);
      });
  }

  public changeFunctionalSupervisors(users: UserUser[]) {
    this.selected_functional_supervisors = users;
  }

  protected async loadLocalSkills() {
    return this.$api
      .get(skillRoutes.skills, {
        params: {
          factory_id: this.factory_id,
          infinity_id: this.structure.infinity_id
        }
      })
      .then(res => {
        this.skills = res.data.skills;
      });
  }

  protected async loadSigns() {
    return this.$api
      .get(signRoutes.sign_users)
      .then(({ data: res }: { data: IDataSign }) => {
        this.signs = res.signUsers;
      });
  }

  protected async loadSelectedSigns() {
    return this.$api
      .get(userRoutes.signs(this.user_id!))
      .then(({ data: res }: { data: SignUser[] }) => {
        this.selectedSigns.push(...res);
      });
  }

  protected async loadSkillCategories() {
    return this.$api
      .get(skillRoutes.categories)
      .then(({ data: res }: { data: SkillCategory[] }) => {
        this.skillCategoriesFilter.push(...res.slice(0, 3));
      });
  }

  protected async loadEditUserWorkplaces() {
    return this.$api
      .get(users.workplacesMe, { params: { id: this.user_id } })
      .then(({ data: res }: { data: IUserWorkplace[] }) => {
        this.editUserWorkplaces = res;

        this.selectedUserWorkplaceTarget =
          this.editUserWorkplaces.find(w => w.is_main) ||
          this.editUserWorkplaces[0];
        this.selectedUserWorkplaceCurrent =
          this.editUserWorkplaces.find(w => w.is_main) ||
          this.editUserWorkplaces[0];
      });
  }

  protected showEditModal() {
    this.clearErrors();
    this.clearFilters();
    this.$modal.show("editUser");
  }

  protected hideEditModal() {
    this.$emit("closeModal", { user: this.editUser });
    this.$modal.hide("editUser");
  }

  protected clearErrors() {
    this.identification_number_taken = false;
    this.email_taken = false;
    this.$v.$reset();
  }

  protected changeCurrentLevel(
    skill: IBlockSkill,
    blockIndex: number,
    skillIndex: number
  ) {
    this.$set(
      this.editUserSkillCurrent[blockIndex]!.skills!,
      skillIndex,
      skill
    );
  }

  protected changeTargetLevel(
    skill: IBlockSkill,
    blockIndex: number,
    skillIndex: number
  ) {
    this.$set(this.editUserSkillTarget[blockIndex]!.skills!, skillIndex, skill);
  }

  protected async clearFilters() {
    this.currentSkillCategoryFilter = this.skillCategoriesFilter[0];
    this.filters.clear();
    await this.clearCurrentSkills();
    await this.clearTargetSkills();
  }

  protected async clearCurrentSkills() {
    this.editUserSkillCurrent = [];
    await this.loadSkillCurrent();
  }

  private async clearTargetSkills() {
    this.editUserSkillTarget = [];
    await this.loadSkillTarget();
  }

  protected async changeTargetSearch() {
    await this.clearTargetSkills();
  }

  protected async changeCurrentSearch() {
    await this.clearCurrentSkills();
  }

  protected async changeCurrentCategory() {
    if (this.skillCategoriesFilter[0] !== this.currentSkillCategoryFilter) {
      this.filters.set("category", true);
    } else {
      this.filters.delete("category");
    }

    await this.clearCurrentSkills();
  }

  protected async changeTargetCategory() {
    if (this.skillCategoriesFilter[0] !== this.targetSkillCategoryFilter) {
      this.filters.set("category", true);
    } else {
      this.filters.delete("category");
    }

    await this.clearTargetSkills();
  }

  protected async changeWorkplaceFilterTarget() {
    await this.clearTargetSkills();
  }

  protected async changeWorkplaceFilterCurrent() {
    await this.clearCurrentSkills();
  }

  protected async destroyUser() {
    return this.$api
      .destroy(userRoutes.user(this.editUser.id!))
      .then(({ data: res }: { data: UserUser }) => {
        this.$emit("destroyUser", { user: res });
        this.$modal.hide("removeUserModal");
      })
      .catch(({ response: res }) => {
        this.editUserErrors = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected uploadDocumentFile(url: string, index: number) {
    this.editUser.documents[index]!.document_files!.push({
      id: "",
      url
    });
  }

  protected removeDocumentFile(doc_index: number, file_index: number) {
    this.editUser?.documents![doc_index].document_files.splice(file_index, 1);
  }

  protected async createUser(hide: boolean = true) {
    this.editUserErrors = null;

    this.editUser.phone = this.editUser.phone?.trim();
    this.editUser.full_name = this.editUser.full_name?.trim();

    this.$v.$touch();

    if (this.$v.$anyError) {
      this.editUserErrors = "Заполните данные";

      return;
    }

    this.editUserSameFactoryError = false;
    let factory_id: string | null = null;
    (this.editUser.workplaces || []).forEach((wp, i) => {
      if (i === 0) {
        factory_id = wp.factory_id!;
      } else if (wp.factory_id !== factory_id) {
        this.editUserSameFactoryError = true;
        this.editUserErrors = "Сотрудник может работать только на одном заводе";
      }
    });

    if (this.editUserSameFactoryError) {
      return;
    }

    this.editUser.skill_currents = this.editUserSkillCurrent;
    this.editUser.skill_targets = this.editUserSkillTarget;
    this.editUser.signs = this.selectedSigns;

    this.editUser.supervisors = this.selected_line_supervisors;
    this.editUser.functional_supervisors = this.selected_functional_supervisors;

    const functional_supervisor_ids = this.editUser.functional_supervisors.reduce<
      Set<string>
    >((acc, curr) => {
      acc.add(curr.id);

      return acc;
    }, new Set());

    if (
      this.editUser.supervisors.findIndex(s =>
        functional_supervisor_ids.has(s.id)
      ) !== -1
    ) {
      this.editUserErrors =
        "Руководитель не может быть одновременно функциональным и линейным";

      return;
    }

    this.preload = true;

    return this.$api
      .post(userRoutes.users, this.editUser)
      .then(({ data: res }: { data: UserUser }) => {
        this.editUser = plainToClass(UserUser, res);

        this.$emit("createUser", { user: this.editUser });
        this.preload = false;

        if (hide) {
          this.hideEditModal();
        }
      })
      .catch(({ response: res }) => {
        this.preload = false;
        this.clearErrors();
        this.editUserErrors = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async updateUser(hide: boolean = true) {
    this.editUserErrors = null;

    this.editUser.phone = this.editUser.phone?.trim();
    this.editUser.full_name = this.editUser.full_name?.trim();

    this.$v.$touch();

    if (this.$v.$anyError && this.$v.editUser?.password?.required) {
      this.editUserErrors = "Заполните данные";

      return;
    }

    this.editUserSameFactoryError = false;
    let factory_id: string | null = null;
    (this.editUser.workplaces || []).forEach((wp, i) => {
      if (i === 0) {
        factory_id = wp.factory_id!;
      } else if (wp.factory_id !== factory_id) {
        this.editUserSameFactoryError = true;
        this.editUserErrors = "Сотрудник может работать только на одном заводе";
      }
    });

    if (this.editUserSameFactoryError) {
      return;
    }

    this.editUser.skill_currents = this.editUserSkillCurrent;
    this.editUser.skill_targets = this.editUserSkillTarget;
    this.editUser.signs = this.selectedSigns;

    this.editUser.supervisors = this.selected_line_supervisors;
    this.editUser.functional_supervisors = this.selected_functional_supervisors;

    const functional_supervisor_ids = this.editUser.functional_supervisors.reduce<
      Set<string>
    >((acc, curr) => {
      acc.add(curr.id);

      return acc;
    }, new Set());

    if (
      this.editUser.supervisors.findIndex(s =>
        functional_supervisor_ids.has(s.id)
      ) !== -1
    ) {
      this.editUserErrors =
        "Руководитель не может быть одновременно функциональным и линейным";

      return;
    }

    this.preload = true;
    return this.$api
      .put(userRoutes.user(this.editUser?.id!), this.editUser)
      .then(({ data: res }: { data: UserUser }) => {
        this.editUser = plainToClass(UserUser, res);

        this.$emit("updateUser", { user: this.editUser });
        this.preload = false;

        this.$notify({
          group: "notifications",
          type: "success",
          text: "Изменения сохранены",
          speed: 500
        });

        if (hide) {
          this.hideEditModal();
        }
      })
      .catch(({ response: res }) => {
        this.preload = false;
        this.clearErrors();
        this.editUserErrors = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected addUserDocument() {
    this.editUser?.documents!.push(new UserDocument());
  }

  protected removeUserDocument(index: number) {
    this.editUser?.documents.splice(index, 1);
  }

  protected addUserWorkplace() {
    this.editUser?.workplaces!.push(new UserWorkplace());
  }

  protected async removeUserWorkplace(index: number) {
    if (this.editUser) {
      const wp = this.editUser.workplaces![index];

      this.$delete(this.editUser.workplaces!, index);

      if (this.editUserPositions.has(wp.id)) {
        this.editUserPositions.delete(wp.id);
      }

      if (this.nextUserPositionLevels.has(wp.id)) {
        this.nextUserPositionLevels.delete(wp.id);
      }

      this.$modal.hide("removeUserWorkplaceModal");

      await this.loadSkillTarget();
    }
  }

  protected async changeWorkplace(obj: {
    index: number;
    workplace: UserWorkplace;
  }) {
    let wp = this.editUser?.workplaces![obj.index];
    wp = { ...wp, ...obj.workplace };

    if (wp.position_id) {
      this.editUserPositions.set(wp.id, wp.position_id);
    } else {
      this.editUserPositions.delete(wp.id);
    }

    if (wp.next_position_level_id) {
      this.nextUserPositionLevels.set(wp.id, wp.next_position_level_id);
    } else {
      this.nextUserPositionLevels.delete(wp.id);
    }

    if (wp.target_position_level_id) {
      this.editUserPositionLevels.set(wp.id, wp.target_position_level_id);
    } else {
      this.editUserPositionLevels.delete(wp.id);
    }

    await this.loadSkillTarget();
  }

  protected async loadSkillTarget() {
    return this.$api
      .get(userRoutes.skills_target(this.user_id || "new"), {
        params: {
          position_ids: Array.from(this.editUserPositions.values()),
          position_level_ids: Array.from(this.nextUserPositionLevels.values()),
          search: this.search_target,
          category_id: this.targetSkillCategoryFilter?.id,
          workplace_id: this.selectedUserWorkplaceTarget?.id,
          block_id: this.targetCurrentBlockFilter.id
        }
      })
      .then(({ data: res }: { data: BlockBlock[] }) => {
        this.editUserSkillTarget = res;

        if (this.targetBlocksFilter === null) {
          this.targetBlocksFilter = plainToClass(BlockBlock, res);
        } else {
          this.targetSkillSelectAllSkillsCheckboxState = updateSkillCheckboxesState(
            this.$refs,
            this.editUserSkillTarget,
            this.targetSkillSelectAllSkillsCheckboxState,
            "target_"
          );
        }

        this.editUserSkillTarget = onDisplayZeroLevelSkillsChanged(
          this.editUserSkillTarget,
          this.displayZeroLevelTargetSkills
        );
      })
      .catch(({ response: res }) => {
        this.clearErrors();
        this.editUserErrors = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async loadSkillCurrent() {
    return this.$api
      .get(userRoutes.skills_current(this.user_id || "new"), {
        params: {
          search: this.search_current,
          category_id: this.currentSkillCategoryFilter?.id,
          workplace_id: this.selectedUserWorkplaceCurrent?.id,
          block_id: this.currentCurrentBlockFilter.id
        }
      })
      .then(({ data: res }: { data: BlockBlock[] }) => {
        this.editUserSkillCurrent = res;

        if (this.currentBlocksFilter === null) {
          this.currentBlocksFilter = plainToClass(BlockBlock, res);
        } else {
          this.currentSkillSelectAllSkillsCheckboxState = updateSkillCheckboxesState(
            this.$refs,
            this.editUserSkillCurrent,
            this.currentSkillSelectAllSkillsCheckboxState,
            "current_"
          );
        }

        this.editUserSkillCurrent = onDisplayZeroLevelSkillsChanged(
          this.editUserSkillCurrent,
          this.displayZeroLevelCurrentSkills
        );
      })
      .catch(({ response: res }) => {
        this.clearErrors();
        this.editUserErrors = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async loadFactories() {
    return this.$api
      .get(structureRoutes.factories, {
        params: {
          factory_id: this.editUser.first_workplace_factory_id,
          only_my: true,
          section_id: (this.$router.currentRoute.meta! as IRouteMeta)
            .permissions?.[0]
        }
      })
      .then(({ data: res }: { data: StructureFactory[] }) => {
        this.factories = res;

        this.changeFactory();
      })
      .catch(({ response: res }) => {
        this.clearErrors();
        this.editUserErrors = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected changeMainWorkplace(checked: boolean, index: number) {
    if (checked) {
      this.editUser.workplaces?.forEach((wp, idx) => {
        if (index !== idx) {
          wp.is_main = false;
        }
      });
    }
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  public allowWrite() {
    return this.$api.allowWrite();
  }
}
