
import { Component, Vue } from "vue-property-decorator";

import Preloader from "@/components/Preloader.vue";
import Search from "@/components/Search.vue";
import CUserEdit from "@/components/user/UserEdit.vue";
import CGroupEdit from "@/components/user/GroupEdit.vue";
import CWorkplacePlaceholder from "@/components/WorkplacePlaceholder.vue";

import { staffs_and_groups_child_routes } from "@/router/employees/users";

import structure_routes from "@/api/routes/structures";
import userRoutes from "@/api/routes/users";

import UserUser from "@/models/user/user";

import { plainToClass } from "class-transformer";
import { IRouteMeta } from "@/router/interface";
import StructureFactory from "@/models/structure/factory";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
  readonly users: UserUser[];
}

@Component({
  components: {
    Preloader,
    Search,
    CUserEdit,
    CWorkplacePlaceholder,
    CGroupEdit
  }
})
export default class AllStaff extends Vue {
  protected preload: boolean = false;
  protected preload_infinite: boolean = false;

  protected per_page: number = 100;
  protected current_page: number = 0;
  protected next_page: boolean = false;

  protected users: UserUser[] = [];

  protected search: string = this.$store.state.search;

  protected links = staffs_and_groups_child_routes.map(r => {
    return {
      title: r.meta!.name,
      to: {
        name: r.name
      }
    };
  });

  private currentUser: IJWTUser | null = this.$store.state.currentUser;

  protected editUser = new UserUser();
  protected editUserId: string | null = null;
  protected editUserIndex: number | null = null;
  protected editUserErrors: string | string[] | null = null;
  protected workplaceExist: boolean = true;

  protected showModal: boolean = false;
  // protected showGroupModal: boolean = false;

  protected watchers: Function[] = [];

  private current_user_factory: StructureFactory | null = null;
  private only_current_user_factory = this.$api.allowOnlyUserWorkplaces();

  protected async created() {
    this.watchers.push(
      this.$store.watch(
        state => state.search,
        async search => {
          this.search = search;

          await this.clearUsers();
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.currentUser = currentUser;
        }
      )
    );

    await Promise.all([this.checkWorkplaceExist(), this.loadFactory()]).then(
      async () => {
        await this.clearUsers();
      }
    );
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async checkWorkplaceExist() {
    return this.$api
      .get(userRoutes.workplace_exists(this.currentUser?.id!))
      .then(({ data: res }: { data: { is_exist: boolean } }) => {
        this.workplaceExist = res.is_exist;
      });
  }

  protected async clearUsers() {
    this.preload = true;

    this.current_page = 0;
    this.users = [];

    await this.loadUsers();

    this.preload = false;
  }

  private async loadFactory() {
    return this.$api
      .get(structure_routes.me_factory)
      .then(({ data: res }: { data: StructureFactory }) => {
        this.current_user_factory = res;
      });
  }

  private async loadUsers(page: number = 0) {
    this.preload_infinite = true;

    let params;

    if (this.only_current_user_factory && this.current_user_factory) {
      params = {
        only_my: false,
        factory_id: this.current_user_factory.id
      };
    } else {
      params = {
        only_my: true
      };
    }

    return this.$api
      .get(userRoutes.users, {
        params: {
          page,
          per_page: this.per_page,
          search: this.search,
          paginate: true,
          include_position: true,
          section_id: (this.$router.currentRoute.meta! as IRouteMeta)
            .permissions?.[0],
          ...params
        }
      })
      .then(({ data: res }: { data: IData }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;
        this.users.push(...res.users);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      })
      .finally(() => {
        this.preload_infinite = false;
      });
  }

  protected async infiniteHandler() {
    if (this.next_page && !this.preload_infinite) {
      await this.loadUsers(this.current_page + 1);
    }
  }

  protected createUser(data: { user: UserUser }) {
    this.users.push(plainToClass(UserUser, data.user));
  }

  protected updateUser(data: { user: UserUser }) {
    this.$set(
      this.users,
      this.editUserIndex!,
      plainToClass(UserUser, data.user)
    );
  }

  protected closeEditModal() {
    this.editUserIndex = null;
    this.showModal = false;
    this.editUserId = null;
  }

  protected removeUser() {
    this.$delete(this.users, this.editUserIndex!);
    this.showModal = false;
    this.editUserId = null;
  }

  protected beforeCreateUser() {
    this.editUser = new UserUser();
    this.editUserIndex = null;
    this.editUserErrors = null;
    this.showModal = true;
  }

  protected beforeUpdateUser(index: number) {
    this.editUserIndex = index;
    this.editUserErrors = null;
    this.editUserId = this.users[index].id;
    this.showModal = true;
  }

  // protected async beforeAddInGroup(index: number) {
  //   this.editUser = this.users[index];
  //   this.editUserId = this.editUser.id;
  //   this.editUserIndex = index;
  //   this.editUserErrors = null;

  //   this.showGroupModal = true;
  // }

  // protected closeGroupModal() {
  //   this.showGroupModal = false;
  //   this.editUserIndex = null;
  //   this.editUserId = null;
  // }

  protected allowWrite() {
    return this.$api.allowWrite();
  }
}
