var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"c-workplace"}},[(_vm.preload)?_c('Preloader'):_vm._e(),(!_vm.preload)?[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"select",class:{
            active: _vm.factory,
            error: !!_vm.factoryError
          }},[_c('v-select',{attrs:{"options":_vm.factories,"label":"full_name","value":"id","appendToBody":true,"disabled":_vm.disabled},on:{"input":_vm.changeFactory},model:{value:(_vm.factory),callback:function ($$v) {_vm.factory=$$v},expression:"factory"}}),(_vm.factory && !_vm.factory.uec)?_c('label',[_vm._v(_vm._s(_vm.factory.level_name ? _vm.factory.level_name : "Уровень"))]):_c('label',[_vm._v("Завод")]),(_vm.factoryError)?[_c('div',{staticClass:"error-alert"},[_vm._v(" "+_vm._s(_vm.factoryError)+" ")])]:_vm._e()],2)]),_c('div',{staticClass:"col-12"},[_c('CStructure',{attrs:{"factory_uec":_vm.factory_uec,"factory_id":_vm.factory_id,"structure":_vm.workplace,"disabled":_vm.disabled},on:{"changeStructure":_vm.changeStructure}})],1),_c('div',{staticClass:"col-12 t-24"},[_c('div',{staticClass:"select",class:{
            active: _vm.position,
            error: !!_vm.positionError
          }},[_c('v-select',{attrs:{"options":_vm.positions,"label":"name","value":"id","appendToBody":true,"disabled":_vm.disabled},on:{"input":_vm.changePosition},model:{value:(_vm.position),callback:function ($$v) {_vm.position=$$v},expression:"position"}}),_c('label',[_vm._v("Должность")]),(_vm.positionError)?[_c('p',{staticClass:"error-alert"},[_vm._v(" "+_vm._s(_vm.positionError)+" ")])]:_vm._e()],2)]),_c('div',{staticClass:"col-6 col-sm-6 col-xs-12 t-24"},[_c('div',{staticClass:"select",class:{
            active: _vm.current_level_id
          }},[_c('v-select',{attrs:{"options":_vm.levels,"disabled":true,"label":"name","value":"id","appendToBody":true,"reduce":c => c.id},model:{value:(_vm.current_level_id),callback:function ($$v) {_vm.current_level_id=$$v},expression:"current_level_id"}}),_c('label',[_vm._v("Фактический уровень развития")])],1)]),_c('div',{staticClass:"col-6 col-sm-6 col-xs-12 t-24"},[_c('div',{staticClass:"select",class:{
            active: _vm.target_level_id
          }},[_c('v-select',{attrs:{"options":_vm.levels,"label":"name","value":"id","appendToBody":true,"reduce":c => c.id,"disabled":_vm.disabled},on:{"input":_vm.changeTargetLevelId},model:{value:(_vm.target_level_id),callback:function ($$v) {_vm.target_level_id=$$v},expression:"target_level_id"}}),_c('label',[_vm._v("Целевой уровень развития")])],1)])])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }