
import { Component, Vue, Prop } from "vue-property-decorator";

import CUpload from "@/components/Upload.vue";
import CFileDownload from "@/components/FileDownload.vue";

import TaskTask from "@/models/task/task";

@Component({
  name: "CTaskChangeMaterials",
  components: {
    CUpload,
    CFileDownload
  }
})
export default class CTaskChangeMaterials extends Vue {
  @Prop({ required: true }) public task!: TaskTask;

  public accept_files: string[] = [
    "application/pdf",
    "image/jpeg",
    "image/bmp",
    "image/png",
    "video/mp4",
    "audio/mpeg",
    "audio/wav",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // docx
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // xlsx
    "application/vnd.ms-excel.sheet.macroenabled.12", // xlsm MacOS|Linux
    "application/vnd.ms-excel.sheet.macroEnabled.12", //xlsm Win
    "application/vnd.openxmlformats-officedocument.presentationml.presentation", // pptx
    "application/msword", // doc
    "application/vnd.ms-excel", // xlx
    "application/vnd.ms-powerpoint", // ppt
    "application/vnd.oasis.opendocument.text", // odt
    "application/vnd.oasis.opendocument.spreadsheet", // ods
    "application/vnd.oasis.opendocument.presentation", // odp
    "text/plain", // txt
    "application/rtf" // rtf
  ];

  public uploadFile(url: string) {
    this.task.files.push({ url: url });
  }

  public attachUrl(event: FocusEvent) {
    const target = event.target as HTMLInputElement;

    const url = target.value;
    target.value = "";

    if (!url || !url.startsWith("http")) {
      return;
    }

    this.uploadFile(url);
  }

  public removeFile(index: number) {
    this.$delete(this.task.files, index);
  }

  public allowWrite() {
    return this.$api.allowWrite();
  }
}
