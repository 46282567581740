import "reflect-metadata";
import {
  IsBoolean,
  IsDate,
  IsEnum,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID
} from "class-validator";

import { ESubscriptionProgressStatus } from "@/enums/subscription_progress_statuses";
import { ESubscriptionStatus } from "@/models/subscriptions/program";
import { ETestingAssignmentStatus } from "@/models/testing/assignment";
import { ETaskCategory, ETaskStatus } from "@/models/task/task";

export interface IAnalytics {
  readonly id: string;
  readonly workplace_id?: string;
  readonly skill_user_current_id?: string;
  readonly identification_number?: string;
  readonly full_name?: string;
  readonly position_name?: string;
  readonly factory_name?: string;
  readonly workshop_name?: string;
  readonly workline_name?: string;
  readonly equipment_name?: string;
  readonly infinity_name?: string;
  readonly user_signs?: string;
  readonly user_roles?: string;
  readonly supervisor_name?: string;
  readonly skill_name?: string;
  readonly pillar_name?: string;
  readonly category_name?: string;
  readonly block_name?: string;
  readonly target_level?: number;
  readonly current_level?: number;
  readonly program_title?: string;
  readonly inspectors_names?: string;
  readonly is_self_education?: string;
  readonly program_days_to_study?: number;
  readonly subscription_status?: string;
  readonly testing_name?: string;
  readonly testing_assignment_status?: string;
  readonly testing_assignment_real_ends_at?: Date;
  readonly description?: string;
  readonly task_status?: string;
  readonly task_category?: string;
  readonly task_created_at?: Date;
  readonly task_ends_at?: Date;
  readonly responsible_name?: string;
  readonly task_comment?: string;
}

export default class AnalyticsFilter {
  @IsUUID("4")
  public id?: string;

  @IsUUID("4")
  @IsOptional()
  public user_id?: string;

  @IsUUID("4")
  @IsOptional()
  public template_id?: string;

  @IsBoolean()
  public show_identification_number: boolean = true;

  @IsOptional()
  @IsString()
  public identification_number?: string | null = null;

  @IsBoolean()
  public show_full_name: boolean = true;

  @IsOptional()
  @IsString()
  public full_name?: string | null = null;

  @IsBoolean()
  public show_position: boolean = false;

  @IsOptional()
  @IsUUID("4")
  public position_id?: string | null = null;

  @IsBoolean()
  public show_mentor: boolean = false;

  @IsOptional()
  @IsUUID("4")
  public mentor_id?: string | null = null;

  public position_name?: string | null = null;

  @IsBoolean()
  public show_user_sign: boolean = false;

  @IsOptional()
  @IsUUID("4")
  public sign_id?: string | null = null;

  @IsOptional()
  public sign_name?: string | null = null;

  @IsBoolean()
  public show_role: boolean = false;

  @IsOptional()
  @IsUUID("4")
  public role_id?: string | null = null;

  @IsOptional()
  public role_name?: string | null = null;

  @IsBoolean()
  public show_supervisor: boolean = false;

  @IsOptional()
  @IsUUID("4")
  public supervisor_id?: string | null = null;

  @IsOptional()
  public supervisor_name?: string | null = null;

  @IsBoolean()
  public show_skill: boolean = false;

  @IsOptional()
  @IsUUID("4")
  public skill_id?: string | null = null;

  @IsOptional()
  public skill_name?: string | null = null;

  @IsBoolean()
  public show_block: boolean = false;

  @IsOptional()
  @IsUUID("4")
  public block_id?: string | null = null;

  @IsOptional()
  public block_name?: string | null = null;

  @IsBoolean()
  public show_pillar: boolean = false;

  @IsOptional()
  @IsUUID("4")
  public pillar_id?: string | null = null;

  @IsOptional()
  public pillar_name?: string | null = null;

  @IsBoolean()
  public show_skill_category: boolean = false;

  @IsOptional()
  @IsUUID("4")
  public category_id?: string | null = null;

  @IsOptional()
  public category_name?: string | null = null;

  @IsBoolean()
  public show_subscription_program: boolean = false;

  @IsBoolean()
  public show_subscription_program_inspector: boolean = false;

  @IsOptional()
  public inspector_id?: string | null = null;

  @IsOptional()
  public inspector_name?: string | null = null;

  @IsOptional()
  @IsUUID("4")
  public program_id?: string | null = null;

  @IsOptional()
  public program_name?: string | null = null;

  @IsBoolean()
  public show_education_count_days: boolean = false;

  @IsOptional()
  @IsNumber()
  public education_count_days?: number | null = null;

  @IsOptional()
  @IsEnum(ESubscriptionProgressStatus)
  public progress_status?: ESubscriptionProgressStatus | null = null;

  @IsOptional()
  @IsEnum(ESubscriptionProgressStatus)
  public user_progress_status?: ESubscriptionProgressStatus | null = null;

  @IsOptional()
  @IsEnum(ETaskCategory)
  public task_category?: ETaskCategory | null = null;

  @IsOptional()
  @IsString()
  public responsible_name?: string | null = null;

  @IsBoolean()
  public show_subscription_status: boolean = false;

  @IsOptional()
  @IsEnum(ESubscriptionStatus)
  public subscription_status?: ESubscriptionStatus | null = null;

  @IsBoolean()
  public show_testing: boolean = false;

  @IsBoolean()
  public only_problem_skills: boolean = false;

  @IsOptional()
  @IsUUID("4")
  public testing_id?: string | null = null;

  @IsOptional()
  public testing_name?: string | null = null;

  @IsBoolean()
  public show_testing_assignment_status: boolean = false;

  @IsBoolean()
  public show_task_category: boolean = false;

  @IsBoolean()
  public show_task_ends_at: boolean = false;

  @IsBoolean()
  public show_task_created_at: boolean = false;

  @IsBoolean()
  public show_task_responsible: boolean = false;

  @IsBoolean()
  public show_task_comment: boolean = false;

  @IsBoolean()
  public show_testing_assignment_starts_at: boolean = false;

  @IsBoolean()
  public show_testing_assignment_ends_at: boolean = false;

  @IsBoolean()
  public show_testing_assignment_real_ends_at: boolean = false;

  @IsOptional()
  @IsEnum(ETestingAssignmentStatus)
  public testing_assignment_status?: ETestingAssignmentStatus | null = null;

  @IsBoolean()
  public show_program_interval: boolean = false;

  @IsBoolean()
  public show_is_self_education: boolean = false;

  @IsOptional()
  @IsBoolean()
  public is_self_education_filter?: boolean | null = null;

  @IsOptional()
  @IsDate()
  public program_starts_at?: Date | null = null;

  @IsOptional()
  @IsDate()
  public program_ends_at?: Date | null = null;

  @IsBoolean()
  public show_current_skill_level: boolean = false;

  @IsBoolean()
  public show_target_skill_level: boolean = false;

  @IsBoolean()
  public show_task_description: boolean = false;

  @IsBoolean()
  public show_task_status: boolean = false;

  @IsBoolean()
  public show_workplace: boolean = false;

  @IsOptional()
  @IsUUID("4")
  public factory_id?: string | null = null;

  @IsOptional()
  public factory_name?: string | null = null;

  @IsOptional()
  @IsUUID("4")
  public workshop_id?: string | null = null;

  @IsOptional()
  public workshop_name?: string | null = null;

  @IsOptional()
  @IsUUID("4")
  public workline_id?: string | null = null;

  @IsOptional()
  public workline_name?: string | null = null;

  @IsOptional()
  @IsUUID("4")
  public equipment_id?: string | null = null;

  @IsOptional()
  public equipment_name?: string | null = null;

  @IsOptional()
  @IsUUID("4")
  public infinity_id?: string | null = null;

  @IsOptional()
  public infinity_name?: string | null = null;

  @IsOptional()
  public name?: string | null = null;

  @IsOptional()
  @IsEnum(ETaskStatus)
  public task_status?: ETaskStatus | null = null;

  public get disableShowProblemSkills() {
    return !(
      this.show_skill &&
      this.show_current_skill_level &&
      this.show_target_skill_level
    );
  }

  private get showProgramParams() {
    return (
      this.show_education_count_days ||
      this.show_subscription_program ||
      this.show_subscription_program_inspector ||
      this.show_program_interval ||
      this.show_mentor ||
      this.show_subscription_status
    );
  }

  private get showTestingParams() {
    return (
      this.show_testing ||
      this.show_testing_assignment_status ||
      this.show_testing_assignment_starts_at ||
      this.show_testing_assignment_ends_at ||
      this.show_testing_assignment_real_ends_at
    );
  }

  private get showTaskParams() {
    return this.show_task_status || this.show_task_description;
  }

  public get blockProgramSelected() {
    return this.showTestingParams || this.showTaskParams;
  }

  public get blockTestingSelected() {
    return this.showTaskParams || this.showProgramParams;
  }

  public get blockTaskSelected() {
    return this.showTestingParams || this.showProgramParams;
  }
}
