
import { Component, Vue } from "vue-property-decorator";

import { examinations_child_list_routes } from "@/router/users/examinations";
import task_routes from "@/api/routes/tasks";

import Preloader from "@/components/Preloader.vue";
import CInfiniteScroll from "@/components/InfiniteScroll.vue";

import TaskTask from "@/models/task/task";

export interface ITaskData {
  readonly current_page: number;
  readonly next_page: boolean;
  readonly tasks: TaskTask[];
}

@Component({
  name: "VExaminationTasks",
  components: {
    Preloader,
    CInfiniteScroll
  }
})
export default class VExaminationTasks extends Vue {
  public preload: boolean = false;
  private preload_infinite: boolean = false;

  private per_page: number = 100;
  private current_page: number = 0;
  private next_page: boolean = false;

  public tasks: TaskTask[] = [];

  private current_user: IJWTUser | null = this.$store.state.currentUser;

  public links = examinations_child_list_routes.map((r, idx) => {
    return {
      id: idx,
      title: r.meta!.name,
      to: {
        name: r.name
      }
    };
  });

  private watchers: Function[] = [];

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async created() {
    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.current_user = currentUser;
        }
      )
    );

    await this.clearUserTasks();
  }

  private async clearUserTasks() {
    this.preload = true;

    this.tasks = [];

    await this.getUserTasks();

    this.preload = false;
  }

  public async infiniteHandler() {
    if (this.next_page && !this.preload_infinite) {
      await this.getUserTasks(this.current_page + 1);
    }
  }

  private async getUserTasks(page: number = 0) {
    this.preload_infinite = true;

    return this.$api
      .get(task_routes.tasks, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true,
          user_id: this.current_user!.id
        }
      })
      .then(({ data: res }: { data: ITaskData }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;

        this.tasks.push(...res.tasks);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      })
      .finally(() => {
        this.preload_infinite = false;
      });
  }
}
