
import { Component, Vue, Prop } from "vue-property-decorator";

import program_routes from "@/api/routes/programs";

import Preloader from "@/components/Preloader.vue";

import CConstructorProgramsHeader from "./components/Header.vue";
import CConstructorProgramsTitle from "./components/Title.vue";
import ConstructorProgramsRelations from "./components/Relations.vue";

import ProgramProgram from "@/models/program/program";
import { EProgramVersionStatus, ProgressLevel } from "@/models/program/version";

@Component({
  name: "VConstructorProgramsEdit",
  components: {
    Preloader,
    CConstructorProgramsHeader,
    CConstructorProgramsTitle,
    ConstructorProgramsRelations
  }
})
export default class VConstructorProgramsEdit extends Vue {
  @Prop({ required: true }) public id!: string;

  public ProgressLevel = ProgressLevel;

  public preload: boolean = false;

  public program: ProgramProgram | null = null;
  public current_level: number = 1;

  public disabled: boolean = !this.allowWrite();

  private watchers: Function[] = [];

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async created() {
    this.watchers.push(
      this.$store.watch(
        state => state.programStatusId,
        programStatusId => {
          if (this.program?.draft_version) {
            this.program.draft_version.status_id = programStatusId;
          }

          this.changeDisabled();
        }
      )
    );

    await this.loadProgram();
  }

  private async loadProgram() {
    this.preload = true;

    return this.$api
      .get(program_routes.edit(this.id))
      .then(({ data: res }: { data: ProgramProgram }) => {
        this.program = res;

        this.changeDisabled();
      })
      .finally(() => {
        this.preload = false;
      });
  }

  private changeDisabled() {
    if (!this.allowWrite()) {
      this.disabled = true;

      return;
    }

    if (this.program?.draft_version) {
      if (
        [
          EProgramVersionStatus.SEND_FOR_APPROVAL,
          EProgramVersionStatus.APPROVED
        ].includes(this.program.draft_version.status_id)
      ) {
        this.disabled = true;
      }
    }
  }

  public changesExist(set_level: boolean = false) {
    if (this.program) {
      if (this.program.draft_version) {
        this.program.draft_version.changes_exist = true;
      }

      if (this.program.settings.is_compulsory_education && set_level) {
        this.current_level = ProgressLevel[0].id;
      }
    }
  }

  public allowWrite() {
    return this.$api.allowWrite();
  }
}
