
import { Component, Vue } from "vue-property-decorator";

import task_routes from "@/api/routes/tasks";

export interface ITaskDelete {
  user_id: string;
  task_id: string;
  task_idx: number;
}

@Component({
  name: "CTaskDeleteIndividual"
})
export default class CTaskDeleteIndividual extends Vue {
  public task: ITaskDelete | null = null;

  private watchers: Function[] = [];

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected created() {
    this.watchers.push(
      this.$store.watch(
        state => state.deletingIndividualTask,
        (deletingIndividualTask: ITaskDelete | null) => {
          this.task = deletingIndividualTask;

          if (this.task) {
            this.showModal();
          }
        }
      )
    );
  }

  public async deleteTask() {
    return this.$api
      .destroy(task_routes.task(this.task!.task_id))
      .then(() => {
        this.$store.commit("deletedIndividualTask", this.task);

        this.hideModal();

        this.$notify({
          group: "notifications",
          type: "success",
          text: "Задача удалена",
          speed: 500
        });
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public showModal() {
    this.$modal.show("delete_task_modal");
  }

  public hideModal() {
    this.$modal.hide("delete_task_modal");
    this.$store.commit("deletingIndividualTask", null);
  }

  public allowWrite() {
    return this.$api.allowWrite();
  }
}
