
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import testings_routes from "@/api/routes/testings";

import Preloader from "@/components/Preloader.vue";
import CInfiniteScroll from "@/components/InfiniteScroll.vue";

import { ETestingStatus } from "@/models/testing/testing";
import { IDataSearchTesting } from "@/models/testing/testing_version";

import { IFilters } from "./Filters.vue";
import { IChangedTesting, IChangingTesting } from "./TestingChange.vue";
import { IChangeArchiveTesting } from "./ChangeArchiveTesting.vue";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
}

interface IDataPaginationSearch extends IData {
  readonly testings: IDataSearchTesting[];
}

@Component({
  name: "CTestingsList",
  components: {
    Preloader,
    CInfiniteScroll
  }
})
export default class CTestingsList extends Vue {
  @Prop({ required: true }) public filters!: IFilters;

  public ETestingStatus = ETestingStatus;

  public preload: boolean = false;
  private preload_infinite: boolean = false;

  private per_page: number = 100;
  private current_page: number = 0;
  private next_page: boolean = false;

  public testings: IDataSearchTesting[] = [];

  private search: string = this.$store.state.search;

  private watchers: Function[] = [];

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async created() {
    this.watchers.push(
      this.$store.watch(
        state => state.search,
        async search => {
          this.search = search;

          await this.loadTestings(true);
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.changedTesting,
        (changedTesting: IChangedTesting) => {
          const testing_idx = this.testings.findIndex(
            testing => testing.testing_id === changedTesting.id
          );

          if (testing_idx !== -1) {
            const testing = this.testings[testing_idx];

            testing.testing_name = changedTesting.name;
          }
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.movedTestings,
        async (_movedTestings: number) => {
          await this.loadTestings(true);
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.changedArchiveTestings,
        (changedArchiveTestings: IChangeArchiveTesting) => {
          if (changedArchiveTestings.testing_idx != null) {
            this.$set(
              this.testings[changedArchiveTestings.testing_idx],
              "is_select",
              changedArchiveTestings.is_select
            );

            if (changedArchiveTestings.is_archive) {
              if (this.filters.is_archived) {
                this.$set(
                  this.testings[changedArchiveTestings.testing_idx],
                  "is_archive",
                  true
                );
              } else {
                this.$delete(this.testings, changedArchiveTestings.testing_idx);
              }
            } else {
              this.$set(
                this.testings[changedArchiveTestings.testing_idx],
                "is_archive",
                false
              );
            }
          }
        }
      )
    );

    await this.loadTestings(true);
  }

  @Watch("filters")
  protected async changeFilters() {
    await this.loadTestings(true);
  }

  public async infiniteHandler() {
    if (this.next_page && !this.preload_infinite) {
      await this.loadTestings(false, this.current_page + 1);
    }
  }

  public updateTesting(testing: IDataSearchTesting) {
    if (testing.is_archive) {
      return;
    }

    const changing_testing: IChangingTesting = {
      id: testing.testing_id,
      parent_folder_id: testing.folder_id || null
    };

    this.$store.commit("changingTesting", changing_testing);
  }

  private async loadTestings(show_preloader: boolean, page: number = 0) {
    if (show_preloader) {
      this.testings = [];

      this.preload = true;
    }

    this.preload_infinite = true;

    return this.$api
      .get(testings_routes.testings, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true,
          search: this.search,
          join_folders: true,
          ...this.filters
        }
      })
      .then(({ data: res }: { data: IDataPaginationSearch }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;

        if (this.$store.state.selectedTestingIdsSize) {
          res.testings.forEach(t => {
            if (this.$store.state.selectedTestingIds.has(t.testing_id)) {
              t.is_select = true;
            }
          });
        }

        this.testings.push(...res.testings);
      })
      .finally(() => {
        this.preload = false;
        this.preload_infinite = false;
      });
  }

  public toggleSelected(testing: IDataSearchTesting) {
    testing.is_select = !testing.is_select;

    this.$store.commit("toggleTestingId", testing.testing_id);
  }

  public async archiveTesting(index: number) {
    const testing = this.testings[index];

    this.$store.commit("changingArchiveTestings", {
      id: testing.testing_id,
      name: testing.testing_name,
      is_select: testing.is_select,
      is_archive: testing.is_archive,
      testing_idx: index
    });
  }

  public allowWrite() {
    return this.$api.allowWrite();
  }
}
