
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component
export default class ImageView extends Vue {
  @Prop({ required: true }) protected url!: string;
  @Prop({ default: () => "small" }) protected size!:
    | "small"
    | "middle"
    | "large"
    | "responsive";

  protected imageSrc = this.$api.cdn_url + this.url;

  protected created() {
    this.changeUrl();
  }

  @Watch("url")
  protected changeUrl() {
    this.imageSrc = RegExp(this.$api.regex_url).test(this.url)
      ? this.url
      : this.$api.cdn_url + this.url;
  }
}
