
import { Component, Vue, Prop } from "vue-property-decorator";

import Preloader from "@/components/Preloader.vue";

import groupRoutes from "@/api/routes/groups";
import GroupGroup from "@/models/group/group";
import GroupUser from "@/models/group/user";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
  readonly group_users: GroupUser[];
}

@Component({
  components: {
    Preloader
  }
})
export default class OneGroup extends Vue {
  @Prop({ required: true }) private id!: string;

  private currentUser: IJWTUser | null = this.$store.state.currentUser;

  protected preload: boolean = false;

  protected group: GroupGroup | null = null;

  protected per_page: number = 100;
  protected current_page: number = 0;
  protected next_page: boolean = false;

  protected group_users: GroupUser[] = [];

  protected editUser: GroupUser | null = null;
  protected editUserIndex: number | null = null;
  protected editUserErrors: string | string[] | null = null;

  protected watchers: Function[] = [];

  protected async created() {
    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.currentUser = currentUser;
        }
      )
    );

    await Promise.all([this.loadGroup(), this.loadUsers()]);
  }

  protected async infiniteHandler() {
    if (this.next_page) {
      await this.loadUsers(this.current_page + 1);
    }
  }

  protected async loadGroup() {
    return this.$api
      .get(groupRoutes.group(this.id))
      .then(({ data: res }: { data: GroupGroup }) => {
        this.group = res;
      });
  }

  protected async loadUsers(page: number = 0) {
    this.preload = true;

    let only_my = true;

    if (this.currentUser?.is_admin) {
      only_my = false;
    }

    return this.$api
      .get(groupRoutes.users, {
        params: {
          group_id: this.id,
          page,
          per_page: this.per_page,
          paginate: true,
          only_my: only_my
        }
      })
      .then(({ data: res }: { data: IData }) => {
        this.current_page = res.current_page;
        if (res.group_users.length) {
          this.next_page = res.next_page;

          this.group_users.push(...res.group_users);
        } else {
          this.next_page = false;
        }

        this.preload = false;
      });
  }

  protected showModal() {
    this.$modal.show("destroyUser");
  }

  protected hideModal() {
    this.$modal.hide("destroyUser");
  }

  protected beforeDestroyUser(index: number) {
    this.editUser = { ...this.group_users[index] };
    this.editUserIndex = index;
    this.editUserErrors = null;

    this.showModal();
  }

  protected async destroyUser() {
    await this.$api
      .destroy(groupRoutes.user(this.editUser!.id))
      .then(_res => {
        this.$delete(this.group_users, this.editUserIndex!);

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.editUserErrors = res.data.error;
      });
  }

  protected allowWrite() {
    return this.$api.allowWrite();
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }
}
