
import { Component, Vue } from "vue-property-decorator";

import Preloader from "@/components/Preloader.vue";

import userRoutes from "@/api/routes/users";
import { examinations_child_list_routes } from "@/router/users/examinations";

import TestingAssignment from "@/models/testing/assignment";
import { ETestingStatus } from "@/models/testing/testing";
import { ETestingAssignmentStatus } from "@/models/testing/assignment";
import { normalizedTestingAssignmentStatusColor } from "@/filters/testing";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
}

export interface IDataAssignment extends IData {
  readonly assignments: TestingAssignment[];
}

@Component({
  methods: { normalizedTestingAssignmentStatusColor },
  data: () => {
    return {
      ETestingAssignmentStatus
    };
  },
  components: {
    Preloader
  }
})
export default class VTestings extends Vue {
  private links = examinations_child_list_routes.map(r => {
    return {
      title: r.meta!.name,
      to: {
        name: r.name
      }
    };
  });

  private preload: boolean = false;

  private per_page: number = 100;
  private current_page: number = 0;
  private next_page: boolean = false;

  private assignments: TestingAssignment[] = [];

  public created() {
    this.preload = true;
    Promise.all([this.loadAssignments()]).then(() => {
      this.preload = false;
    });
  }

  private infiniteHandler() {
    if (this.next_page) {
      this.loadAssignments(this.current_page + 1);
    }
  }

  private loadAssignments(page: number = 0) {
    this.$api
      .get(userRoutes.testing_assignments(this.$store.state.currentUser.id), {
        params: {
          page,
          per_page: this.per_page,
          paginate: true
        }
      })
      .then(({ data: res }: { data: IDataAssignment }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;
        this.assignments.push(...res.assignments);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }
}
