
import { Component, Vue } from "vue-property-decorator";

import Search from "@/components/Search.vue";
import Preloader from "@/components/Preloader.vue";

import skillRoutes from "@/api/routes/skills";
import pillarRoutes from "@/api/routes/pillars";
import blockRoutes from "@/api/routes/blocks";

import BlockBlock, { IBlockSkill } from "@/models/block/block";
import PillarPillar from "@/models/pillar/pillar";
import SkillCategory from "@/models/skill/category";
import SkillLocal from "@/models/skill/local";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
}

interface IDataSkills extends IData {
  readonly skills: SkillLocal[];
}

interface IDataBlocks extends IData {
  readonly blocks: BlockBlock[];
}

@Component({
  components: { Search, Preloader }
})
export default class LocalBlocks extends Vue {
  private preload = false;

  private currentUser: IJWTUser | null = this.$store.state.currentUser;

  private blockError: string = "";
  private blocks: BlockBlock[] = [];

  private currentBlock: BlockBlock = new BlockBlock();
  private currentBlockIndex?: number;

  private per_page: number = 100;
  private current_page: number = 0;
  private next_page: boolean = false;

  private skills: SkillLocal[] = [];

  private pillars: PillarPillar[] = [{ id: "", full_name: "все" }];
  private currentPillar?: PillarPillar | null = this.pillars[0];

  private skillCategories: SkillCategory[] = [{ id: 0, value: "все" }];
  private currentCategory?: SkillCategory | null = this.skillCategories[0];

  private search: string = this.$store.state.search;

  private watchers: Function[] = [];

  private created() {
    this.preload = true;

    Promise.all([
      this.loadBlocks(),
      this.loadPillar(),
      this.loadSkillCategories(),
      this.loadSkills()
    ]).then(() => {
      this.preload = false;
    });

    this.watchers.push(
      this.$store.watch(
        state => state.search,
        search => {
          this.search = search;
          this.clearBlocks();
        }
      )
    );
  }

  private loadBlocks(page: number = 0) {
    this.$api
      .get(blockRoutes.blocks, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true,
          search: this.search
        }
      })
      .then(({ data: res }: { data: IDataBlocks }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;
        this.blocks.push(...res.blocks);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private showBlockSkills(index: number) {
    const block = this.blocks[index];

    if (!block!.show_skills) {
      this.$api
        .get(blockRoutes.skills, {
          params: {
            block_id: block!.id
          }
        })
        .then(({ data: res }: { data: IBlockSkill }) => {
          this.$set(block, "skills", res);
        })
        .catch(({ response: res }) => {
          this.$notify({
            group: "notifications",
            type: "error",
            text: res.data.error,
            speed: 500
          });
        });
    }

    this.$set(block, "show_skills", !block.show_skills);
  }

  private infiniteHandler() {
    if (this.next_page) {
      this.loadBlocks(this.current_page + 1);
    }
  }

  private loadSkills() {
    this.$api
      .get(skillRoutes.skills, {
        params: {
          paginate: false,
          category_id: this.currentCategory?.id,
          pillar_id: this.currentPillar?.id,
          is_compulsory_education: this.currentBlock!.is_compulsory_education!,
          only_is_local: true,
          exclude_copy: true
        }
      })
      .then(({ data: res }: { data: IDataSkills }) => {
        this.skills = res.skills;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private changeFilter() {
    this.loadSkills();
  }

  private loadPillar() {
    this.$api
      .get(pillarRoutes.pillars)
      .then(({ data: res }: { data: PillarPillar[] }) => {
        this.pillars = res;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private createBlock() {
    this.currentBlock.full_name = this.currentBlock.full_name?.trim();
    if (!this.currentBlock.full_name?.length) {
      this.blockError = "Заполните имя";
      return;
    }

    if (this.currentBlock.skills?.length === 0) {
      this.blockError = "Добавте минимум один навык";
      return;
    }

    this.$api
      .post(blockRoutes.blocks, {
        block: this.currentBlock
      })
      .then(() => {
        this.hideEditBlock();
        this.clearBlocks();
      })
      .catch(({ response: res }) => {
        this.blockError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private updateBlock() {
    this.currentBlock.full_name = this.currentBlock.full_name?.trim();
    if (!this.currentBlock.full_name?.length) {
      this.blockError = "Заполните имя";
      return;
    }

    if (this.currentBlock.skills?.length === 0) {
      this.blockError = "Добавте минимум один навык";
      return;
    }
    this.$api
      .put(blockRoutes.block(this.currentBlock.id), this.currentBlock)
      .then(({ data: res }: { data: BlockBlock }) => {
        this.hideEditBlock();
        this.$set(this.blocks, this.currentBlockIndex!, res);
      })
      .catch(({ response: res }) => {
        this.blockError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private loadSkillCategories() {
    this.$api
      .get(skillRoutes.categories)
      .then(({ data: res }: { data: SkillCategory[] }) => {
        this.skillCategories = res;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private loadCurrentBlock(block_id: string) {
    this.$api
      .get(blockRoutes.block(block_id))
      .then(({ data: res }: { data: BlockBlock }) => {
        this.currentBlock = res;
        this.loadSkills();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private showEditBlock(index: number = -1) {
    const block = this.blocks[index];
    if (block?.is_local || index === -1) {
      this.currentBlockIndex = index;

      if (block) {
        this.loadCurrentBlock(block.id);
      } else {
        this.currentBlock = new BlockBlock();
      }

      this.$modal.show("editBlock");
    }
  }

  private hideEditBlock() {
    this.currentBlock = new BlockBlock();
    this.$modal.hide("editBlock");
  }

  private clearBlocks() {
    this.blocks = [];
    this.loadBlocks();
  }

  private addSkill(skill: SkillLocal) {
    if (!this.currentBlock.skills!.find(i => i.skill_id === skill.id)) {
      this.currentBlock.skills!.push({
        skill_id: skill.id,
        full_name: skill.full_name
      });
    }
  }

  private removeSkill(index: number) {
    this.$delete(this.currentBlock.skills!, index);
  }

  private beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  private formatName(name: string) {
    const splitName = name.split(/\s+/);

    return splitName.length > 1
      ? splitName
          .splice(0, 2)
          .map(i => i.substring(0, 1).toUpperCase())
          .join("")
      : name.substring(0, 2).toUpperCase();
  }

  private deleteBlock(index: number) {
    const block = this.blocks[index];
    this.$api
      .destroy(blockRoutes.block(block.id))
      .then(() => {
        this.$delete(this.blocks, index);
        this.$modal.hide("deleteBlockModal");
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
    this.$modal.hide("editBlock");
  }
}
