
import { Component, Prop, Vue } from "vue-property-decorator";
import { CurrencyInput } from "vue-currency-input";

import CRangeDatePicker from "@/components/RangeDatePicker.vue";
import CLiveLine from "@/views/training_application/components/LiveLine.vue";
import CInformation from "@/views/training_application/components/Information.vue";
import Preloader from "@/components/Preloader.vue";
import Upload from "@/components/Upload.vue";
import CSkillFilter from "@/components/SkillFilter.vue";
import DateTimePicker from "@/components/DateTimePicker.vue";
import PdfView from "@/components/PdfView.vue";
import CFileDownload from "@/components/FileDownload.vue";
import CUploadDocument from "@/components/application/UploadDocument.vue";

import skillRoutes from "@/api/routes/skills";
import userRoutes from "@/api/routes/users";
import applicationRoutes from "@/api/routes/applications";
import ChangeRoutes from "@/api/routes/changes";

import ApplicationApplication, {
  applicationStatuses,
  applicationTypes,
  EApplicationStatuses,
  EApplicationTypes,
  educationTypes,
  EEducationTypes
} from "@/models/application/application";
import SkillLocal, {
  ESkillDocumentTypes,
  skillDocumentTypes
} from "@/models/skill/local";
import ApplicationComment from "@/models/application/comment";
import UserUser from "@/models/user/user";
import ApplicationInterval from "@/models/application/interval";
import ApplicationCommentFile from "@/models/application/comment_file";
import ApplicationStudent from "@/models/application/student";
import { compulsoryTabs, ICompulsoryTabs } from "@/models/tabs";
import { plainToClass } from "class-transformer";
import CGraduates from "@/views/training_application/components/Graduates.vue";
import CCardIntervals from "@/views/training_application/components/CardIntervals.vue";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
}

interface IApplicationData extends IData {
  readonly application: ApplicationApplication[];
}

interface ISkillFilterData {
  readonly id?: string;
  readonly full_name: string;
}

interface IUserData extends IData {
  readonly users: UserUser[];
}

interface IStatusData {
  readonly value?: EApplicationStatuses[];
  readonly title: string;
}

@Component({
  components: {
    PdfView,
    DateTimePicker,
    CRangeDatePicker,
    CUploadDocument,
    CInformation,
    CCardIntervals,
    CLiveLine,
    CGraduates,
    CSkillFilter,
    Preloader,
    Upload,
    CFileDownload,
    CurrencyInput
  },
  data: () => {
    return {
      applicationStatuses,
      applicationTypes,
      educationTypes,
      EApplicationStatuses,
      skillDocumentTypes,
      EApplicationTypes,
      EEducationTypes,
      compulsoryTabs
    };
  }
})
export default class RTrainingApplications extends Vue {
  @Prop() private id?: string;

  private isGeneralPurpose: boolean = false;
  private generalInterval: ApplicationInterval = new ApplicationInterval();

  private intervals: ApplicationInterval[] = [];

  private timeError = "";

  private filters: Map<string, boolean> = new Map();
  private showFilter: boolean = false;

  private filterSkills: ISkillFilterData[] = [{ full_name: "Все" }];
  private currentFilterSkill: ISkillFilterData = this.filterSkills[0];

  private statusFilter: IStatusData[] = [
    { title: "Все" },
    { value: [EApplicationStatuses.WITHDRAWN], title: "Отозвано" },
    {
      value: [EApplicationStatuses.APPROVAL, EApplicationStatuses.EXECUTION],
      title: "На согласовании"
    },
    {
      value: [
        EApplicationStatuses.WORK,
        EApplicationStatuses.PLANNED,
        EApplicationStatuses.LOAD
      ],
      title: "В работе"
    },
    { value: [EApplicationStatuses.CLOSE], title: "Закрыта" },
    { value: [EApplicationStatuses.REJECTED], title: "Отклонена" }
  ];
  private currentFilterStatus: IStatusData = this.statusFilter[0];

  private showCloseFilter: boolean = false;

  private isAuthor: boolean = false;
  private isResponsible: boolean = false;
  private isConciliator: boolean = false;

  private me: UserUser | null = null;
  private has_groups: boolean = false;

  private rangeDate: string[] = this.$store.state.rangeDate;
  protected showNewApplicationModal: boolean = false;

  private allUsersId: string[] = [];

  private preload: boolean = false;

  private oneCommentSend: boolean = false;

  private responsible: UserUser[] = [];
  private currentResponsible: UserUser | null = null;

  private applications: ApplicationApplication[] = [];
  private applicationError: string = "";
  private currentApplication: ApplicationApplication = new ApplicationApplication();
  private currentApplicationIndex?: number | null;

  private per_page: number = 100;
  private current_page: number = 0;
  private next_page: boolean = false;

  private reconciliations: UserUser[] = [];
  private selectedReconciliations: UserUser[] = [];

  private students: UserUser[] = [];
  private selectedStudents: UserUser[] = [];

  private comments: ApplicationComment[] = [];
  private sendComments: ApplicationComment[] = [];
  private commentFiles: File[] = [];
  private commentFileUrls: ApplicationCommentFile[] = [];

  private docsError: string = "";

  private provider: string = "";
  private costWithNDS: number | null = null;
  private costWithoutNDS: number | null = null;

  private compulsoryFilter = compulsoryTabs;
  private currentCompulsoryFilter?: ICompulsoryTabs = this.compulsoryFilter[0];

  private commentError: string = "";
  private commentShowError: string = "";

  private newAppText: ApplicationComment = new ApplicationComment();
  private showText: ApplicationComment = new ApplicationComment();

  private currentApplicationType: EApplicationTypes =
    EApplicationTypes.COLLECTIVE;
  private currentEducationType: EEducationTypes = EEducationTypes.EXTERNAL;
  private currentDocumentType: ESkillDocumentTypes = ESkillDocumentTypes.GROUP;

  private currentSkill: SkillLocal | null = this.$store.state.currentSkill;

  private showApplicationTabs: string[] = [
    "Данные",
    "Комментарии",
    "Загрузка документа",
    "Участники",
    "Даты обучения"
  ];
  private currentShowTab: number = 0;

  private newApplicationTabs: string[] = ["Данные", "Комментарии"];
  private currentNewTab: number = 0;

  private watchers: Function[] = [];

  $refs!: {
    tabs: HTMLFormElement;
    comment_files: HTMLFormElement;
    comment_files_form: HTMLFormElement;
  };

  private created() {
    this.preload = true;

    Promise.all([
      this.loadApplication(),
      this.loadFilterSkills(),
      this.loadUsers(),
      this.loadMe()
    ]).then(() => {
      this.preload = false;
    });

    this.watchers.push(
      this.$store.watch(
        state => state.rangeDate,
        rangeDate => {
          this.rangeDate = rangeDate;
          if (this.rangeDate.length > 0) {
            this.changeRangeDate();
          }
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.currentSkill,
        currentSkill => {
          this.currentSkill = currentSkill;
          this.changeSkill();
        }
      )
    );
  }

  private infiniteHandler() {
    if (this.next_page) {
      this.loadApplication(this.current_page + 1);
    }
  }

  private loadMe() {
    this.$api
      .get(userRoutes.userMe)
      .then(({ data: res }: { data: UserUser }) => {
        this.me = res;
      });
  }

  private loadUsers() {
    this.$api
      .get(userRoutes.users, {
        params: {
          paginate: false
        }
      })
      .then(({ data: res }: { data: IUserData }) => {
        this.reconciliations = res.users;
        this.students = res.users;
        this.responsible = res.users;
      });
  }

  private loadFilterSkills() {
    this.$api
      .get(skillRoutes.skills, {
        params: {
          paginate: false,
          only_is_local: false
        }
      })
      .then(({ data: res }: { data: { skills: ISkillFilterData[] } }) => {
        this.filterSkills.push(...res.skills);
      });
  }

  private loadSelectedStudents() {
    this.$api
      .get(applicationRoutes.students, {
        params: {
          application_id: this.currentApplication.id
        }
      })
      .then(({ data: res }: { data: ApplicationStudent[] }) => {
        this.selectedStudents = res.map(
          s =>
            new UserUser({
              id: s.student_id,
              full_name: s.full_name,
              certificate_id: s.certificate_id,
              protocol_id: s.protocol_id
            })
        );
      });
  }

  private loadComments() {
    this.$api
      .get(applicationRoutes.comment, {
        params: {
          application_id: this.currentApplication.id
        }
      })
      .then(({ data: res }: { data: ApplicationComment[] }) => {
        this.comments = res;
      });
  }

  private loadSelectedReconciliation() {
    this.$api
      .get(applicationRoutes.reconciliations, {
        params: {
          application_id: this.currentApplication.id
        }
      })
      .then(({ data: res }: { data: UserUser[] }) => {
        this.selectedReconciliations = res;
        this.isConciliator = this.selectedReconciliations.some(
          r => r.id === this.me?.id
        );
      });
  }

  private loadIntervals() {
    this.$api
      .get(applicationRoutes.intervals, {
        params: {
          application_id: this.currentApplication.id
        }
      })
      .then(({ data: res }: { data: ApplicationInterval[] }) => {
        this.intervals = res;
        this.$modal.show("showTimeManagement");
      });
  }

  private showGeneral() {
    this.loadIntervals();
    const interval = this.intervals[0];
    interval.start_education_date = interval.start_education_date
      ? new Date(interval.start_education_date)
      : new Date();
    interval.end_education_date = interval.end_education_date
      ? new Date(interval.end_education_date)
      : new Date();
    this.generalInterval = interval;
  }

  private showTimeManagement() {
    this.loadIntervals();
  }

  private closeTimeManagement() {
    this.timeError = "";
    this.intervals = [];
    this.isGeneralPurpose = false;
    this.generalInterval = new ApplicationInterval();
    this.$modal.hide("showTimeManagement");
  }

  private closeApplication() {
    this.showTimeManagement();
  }

  private setGeneralStart(date: Date) {
    this.$set(this.generalInterval, "start_education_date", date);

    if (this.validateGeneralInterval()) {
      return;
    }

    this.updateGeneralInterval();
  }

  private setGeneralEnd(date: Date) {
    this.$set(this.generalInterval, "end_education_date", date);

    if (this.validateGeneralInterval()) {
      return;
    }

    this.updateGeneralInterval();
  }

  protected validateGeneralInterval() {
    if (
      this.generalInterval.start_education_date! >
      this.generalInterval.end_education_date!
    ) {
      this.timeError = "Дата начала не может быть позднее даты завершения";
      return true;
    } else {
      this.timeError = "";
      return false;
    }
  }

  private updateGeneralInterval() {
    if (!this.generalInterval!.id) {
      return;
    }

    this.$api
      .put(
        applicationRoutes.update_all_intervals(this.currentApplication.id!),
        {
          interval: this.generalInterval
        }
      )
      .catch(({ response: res }) => {
        this.timeError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private isClose() {
    return this.currentApplication.status === EApplicationStatuses.CLOSE;
  }

  private setStart(date: Date, index: number) {
    if (this.isClose()) {
      return;
    }

    const interval = this.intervals[index];
    this.$set(interval, "start_education_date", date);
    this.updateInterval(index);
  }

  private setEnd(date: Date, index: number) {
    if (this.isClose()) {
      return;
    }

    const interval = this.intervals[index];
    this.$set(interval, "end_education_date", date);
    this.updateInterval(index);
  }

  private updateInterval(index: number) {
    const interval = this.intervals[index];
    if (!interval.id) {
      return;
    }

    if (interval.start_education_date! > interval.end_education_date!) {
      this.timeError = "Дата начала не может быть позднее даты завершения";
      return;
    } else {
      this.timeError = "";
    }

    this.$api
      .put(applicationRoutes.interval(interval.id!), {
        interval: interval
      })
      .catch(({ response: res }) => {
        this.timeError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private loadApplication(page: number = 0) {
    const endDate = this.rangeDate[1] ? new Date(this.rangeDate[1]) : null;
    endDate?.setHours(23);
    this.$api
      .get(applicationRoutes.applications, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true,
          skill_id: this.currentFilterSkill?.id,
          start_date: this.rangeDate[0],
          end_date: endDate,
          status: this.currentFilterStatus.value,
          show_close: this.showCloseFilter,
          is_compulsory_education: this.currentCompulsoryFilter?.value
        }
      })
      .then(({ data: res }: { data: IApplicationData }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;
        this.applications.push(...res.application);

        if (this.id) {
          this.showApplicationModal(-1, this.id);
        }
      });
  }

  private clearApplication() {
    this.current_page = 0;
    this.applications = [];
    this.loadApplication();
  }

  private changeSkillFilter() {
    this.currentFilterSkill = this.currentFilterSkill ?? this.filterSkills[0];

    if (this.currentFilterSkill!.id !== this.filterSkills[0].id) {
      this.filters.set("skills", true);
    } else {
      this.filters.delete("skills");
    }
    this.clearApplication();
  }

  private changeStatus() {
    this.currentFilterStatus = this.currentFilterStatus ?? this.statusFilter[0];

    if (this.currentFilterStatus!.value !== this.statusFilter[0].value) {
      this.filters.set("status", true);
    } else {
      this.filters.delete("status");
    }
    this.clearApplication();
  }

  private changeShowClose() {
    if (this.showCloseFilter) {
      this.filters.set("close", true);
    } else {
      this.filters.delete("close");
    }
    this.clearApplication();
  }

  private changeRangeDate() {
    this.filters.set("range", true);
    this.clearApplication();
  }

  private clearFilters() {
    this.currentFilterSkill = this.filterSkills[0];
    this.rangeDate = [];
    this.$store.commit("rangeDate", this.rangeDate);
    this.currentFilterStatus = this.statusFilter[0];
    this.showCloseFilter = false;
    this.currentCompulsoryFilter = this.compulsoryFilter[0];
    this.filters.clear();
    this.clearApplication();
  }

  private getCurrentApplication(id: string | null = null) {
    const applicationId =
      id || this.applications[this.currentApplicationIndex!]!.id;
    this.$api
      .get(applicationRoutes.application(applicationId))
      .then(({ data: res }: { data: ApplicationApplication }) => {
        this.currentApplication = plainToClass(ApplicationApplication, res);

        this.loadSelectedStudents();
        this.loadSelectedReconciliation();
        this.loadComments();
        this.isAuthor = this.currentApplication.author_id === this.me?.id;
        this.isResponsible =
          this.currentApplication.responsible_id === this.me?.id;

        this.setSeen();
      });
  }

  private showApplicationModal(index: number, id: string | null = null) {
    this.currentApplicationIndex = index;
    this.getCurrentApplication(id);

    this.$modal.show("showApplication");
  }

  private setSeen() {
    this.$api
      .put(ChangeRoutes.seen(this.currentApplication.id), {
        user_id: this.me!.id
      })
      .then(({ data: res }: { data: ApplicationApplication }) => {
        this.$set(this.applications, this.currentApplicationIndex!, res);
      });
  }

  private setCurrentShowTab(index: number) {
    this.currentShowTab = index;
  }

  private endApplication() {
    if (
      this.currentApplication.documentary_evidence &&
      this.selectedStudents.filter(i => !i.certificate_id).length
    ) {
      this.applicationError = "Загрузите документы";
      return;
    }

    this.$api
      .put(applicationRoutes.end_application(this.currentApplication.id), {
        skill_id: this.currentApplication.skill_id
      })
      .then(({ data: res }: { data: ApplicationApplication }) => {
        this.$set(this.applications, this.currentApplicationIndex!, res);
        this.closeShowApplication();
      })
      .catch(({ response: res }) => {
        this.applicationError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private showInterval(index: number) {
    const interval = this.intervals[index];

    if (!interval.show_interval) {
      interval.start_education_date = interval.start_education_date
        ? new Date(interval.start_education_date)
        : new Date();
      interval.end_education_date = interval.end_education_date
        ? new Date(interval.end_education_date)
        : new Date();
    }

    this.$set(interval, "show_interval", !interval.show_interval);
  }

  private changeCompulsory() {
    this.currentCompulsoryFilter =
      this.currentCompulsoryFilter ?? this.compulsoryFilter[0];

    if (
      this.currentCompulsoryFilter!.value !== this.compulsoryFilter[0].value
    ) {
      this.filters.set("compulsory", true);
    } else {
      this.filters.delete("compulsory");
    }
    this.clearApplication();
  }

  private changeSkill() {
    this.currentApplicationType = this.currentSkill?.application_type
      ? this.currentSkill.application_type
      : this.currentApplicationType;
    this.currentResponsible = this.currentSkill?.responsible_id
      ? this.responsible.find(i => i.id === this.currentSkill?.responsible_id)!
      : null;
    this.currentDocumentType = this.currentSkill?.document_type
      ? this.currentSkill.document_type
      : this.currentDocumentType;
    this.currentEducationType = this.currentSkill?.education_type
      ? this.currentSkill.education_type
      : this.currentEducationType;
  }

  private showNewApplication() {
    if (this.currentApplication.id) {
      this.currentApplicationType = this.currentApplication.application_type!;
      this.currentResponsible = this.responsible.find(
        u => u.id === this.currentApplication.responsible_id
      )!;
      this.loadSelectedReconciliation();
      this.loadSelectedStudents();
    } else {
      this.selectedReconciliations = [];
      // if (this.me?.leader?.id) {
      //   this.selectedReconciliations.push(
      //     this.reconciliations.find(r => r.id! === this.me?.leader?.id!)!
      //   );
      // }
      this.selectedStudents = [];
    }

    this.showNewApplicationModal = true;
    this.$modal.show("newApplication");
  }

  private updateApplication() {
    if (!this.currentResponsible) {
      this.applicationError = "Не выбран ответсвенный";
      return;
    }

    if (this.selectedReconciliations.length < 1) {
      this.applicationError = "Минимум один согласующий";
      return;
    }

    if (this.selectedStudents.length < 1) {
      this.applicationError = "Минимум один обучающийся";
      return;
    }

    this.allUsersId = [];
    this.allUsersId.push(...this.selectedStudents.map(i => i.id!));
    this.allUsersId.push(...this.selectedReconciliations.map(i => i.id!));
    this.allUsersId.push(this.me!.id!);
    this.allUsersId.push(this.currentResponsible!.id!);

    this.$api
      .put(applicationRoutes.application(this.currentApplication.id), {
        responsible_id: this.currentResponsible!.id,
        reconciliations: this.selectedReconciliations,
        students: this.selectedStudents,
        all_users: this.allUsersId
      })
      .then(({ data: res }: { data: ApplicationApplication }) => {
        this.setChanges();
        this.$set(this.applications, this.currentApplicationIndex!, res);
        this.closeAddModal();
        this.closeShowApplication();
      })
      .catch(({ response: res }) => {
        this.applicationError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private createNewApplication() {
    if (!this.me?.workplaces?.length) {
      this.applicationError = "Вам не назначено рабочее место";
      return;
    }

    if (!this.$store.state.currentSkill) {
      this.applicationError = "Не выбран навык";
      return;
    }

    if (!this.currentResponsible) {
      this.applicationError = "Не выбран ответсвенный";
      return;
    }

    if (this.selectedReconciliations.length < 1) {
      this.applicationError = "Минимум один согласующий";
      return;
    }

    if (this.selectedStudents.length < 1) {
      this.applicationError = "Минимум один обучающийся";
      return;
    }

    this.allUsersId = [];
    this.allUsersId.push(...this.selectedStudents.map(i => i.id!));
    this.allUsersId.push(...this.selectedReconciliations.map(i => i.id!));
    this.allUsersId.push(this.me!.id!);
    this.allUsersId.push(this.currentResponsible!.id!);

    this.$api
      .post(applicationRoutes.applications, {
        skill_id: this.$store.state.currentSkill.id,
        workplace_id: this.me!.workplaces?.[0].id,
        author_id: this.me!.id,
        application_type: this.currentApplicationType,
        education_type: this.currentEducationType,
        documentary_evidence: this.$store.state.currentSkill
          .documentary_evidence,
        document_type: this.currentDocumentType,
        responsible_id: this.currentResponsible!.id,
        reconciliations: this.selectedReconciliations,
        students: this.selectedStudents,
        comments: this.sendComments,
        all_users: this.allUsersId,
        status: EApplicationStatuses.APPROVAL,
        provider: this.provider,
        cost_with_nds: this.costWithNDS,
        cost_without_nds: this.costWithoutNDS
      })
      .then(({ data: res }: { data: ApplicationApplication }) => {
        this.applications.unshift(res);
        this.clearFilters();
        this.closeAddModal();
      })
      .catch(({ response: res }) => {
        this.applicationError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private addMessage() {
    if (this.newAppText instanceof ApplicationComment) {
      if (this.newAppText.text.length < 1 && !this.commentFiles.length) {
        this.commentError = "Нельзя отправить пустой комментарий";
        return;
      }

      this.commentFiles.map(f =>
        this.sendFile(f)
          .then(({ data: res }: { data: { url: string } }) => {
            this.commentFileUrls.push(
              new ApplicationCommentFile({ url: res.url })
            );
            if (this.commentFileUrls.length === this.commentFiles.length) {
              this.newAppText.sender_name = this.me!.full_name;
              this.newAppText.created_at = new Date();
              this.newAppText.comment_files = this.commentFileUrls;
              this.sendComments.unshift(this.newAppText);
              this.newAppText = new ApplicationComment();

              this.commentFileUrls = [];
              this.commentFiles = [];
              this.commentError = "";
            }
          })
          .catch(({ response: res }) => {
            this.commentError = res.data.error;

            this.$notify({
              group: "notifications",
              type: "error",
              text: res.data.error,
              speed: 500
            });
          })
      );
      if (!this.commentFiles.length) {
        this.newAppText.sender_name = this.me!.full_name;
        this.newAppText.created_at = new Date();
        this.newAppText.comment_files = this.commentFileUrls;
        this.sendComments.unshift(this.newAppText);
        this.newAppText = new ApplicationComment();

        this.commentFileUrls = [];
        this.commentFiles = [];
      }
    }
  }

  private sendFile(file: File) {
    const formData = new FormData();
    formData.append("file", file!);

    return this.$api.upload(formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  private addCommentFile() {
    this.commentFiles.push(...this.$refs.comment_files.files);
    this.$refs.comment_files_form.reset();
  }

  removeCommentFile(index: number) {
    this.commentFiles.splice(index, 1);
  }

  private sendComment() {
    this.$api
      .post(applicationRoutes.comment, {
        id: this.currentApplication.id,
        text: this.showText.text,
        sender_id: this.me?.id,
        comment_files: this.commentFileUrls
      })
      .then(({ data: res }: { data: ApplicationComment }) => {
        this.commentFileUrls = [];
        this.commentFiles = [];
        this.comments.unshift(res);
        this.showText = new ApplicationComment();
        this.setChanges();
        this.oneCommentSend = true;

        this.commentError = "";
      })
      .catch(({ response: res }) => {
        this.commentShowError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private sendMessage() {
    if (this.isClose()) {
      return;
    }

    if (this.showText.text.length < 1 && !this.commentFiles.length) {
      this.commentShowError = "Нельзя отправить пустой комментарий";
      return;
    }

    this.commentFiles.map(f =>
      this.sendFile(f)
        .then(({ data: res }: { data: { url: string } }) => {
          this.commentFileUrls.push(
            new ApplicationCommentFile({ url: res.url })
          );
          if (this.commentFileUrls.length === this.commentFiles.length) {
            this.sendComment();
          }
        })
        .catch(({ response: res }) => {
          this.commentShowError = res.data.error;

          this.$notify({
            group: "notifications",
            type: "error",
            text: res.data.error,
            speed: 500
          });
        })
    );

    if (!this.commentFiles.length) {
      this.sendComment();
    }
  }

  private cancelApplication() {
    this.sendComment();
    if (!this.oneCommentSend) {
      this.currentShowTab = 1;
      this.commentShowError = "Необходимо оставить комментарий";
      return;
    }

    this.$api
      .put(
        applicationRoutes.cancel(this.currentApplication.id, this.me!.id),
        {}
      )
      .then(({ data: res }: { data: ApplicationApplication }) => {
        this.$set(this.applications, this.currentApplicationIndex!, res);
        this.setChanges();
        this.closeShowApplication();
      })
      .catch(({ response: res }) => {
        this.applicationError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private submitApplication() {
    this.$api
      .put(
        applicationRoutes.confirm(this.currentApplication.id, this.me!.id),
        {}
      )
      .then(({ data: res }: { data: ApplicationApplication }) => {
        this.$set(this.applications, this.currentApplicationIndex!, res);
        this.setChanges();
        this.closeShowApplication();
      })
      .catch(({ response: res }) => {
        this.applicationError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private sendApplication() {
    this.$api
      .put(applicationRoutes.approval(this.currentApplication.id), {})
      .then(({ data: res }: { data: ApplicationApplication }) => {
        this.setChanges();
        this.$set(this.applications, this.currentApplicationIndex!, res!);
        this.closeShowApplication();
      })
      .catch(({ response: res }) => {
        this.applicationError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private setWork() {
    this.$api
      .put(applicationRoutes.to_work(this.currentApplication.id), {})
      .then(({ data: res }: { data: ApplicationApplication }) => {
        this.$set(this.applications, this.currentApplicationIndex!, res);

        this.setChanges();
        this.closeShowApplication();
      })
      .catch(({ response: res }) => {
        this.applicationError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private withdrawApplication() {
    this.$api
      .put(applicationRoutes.withdraw(this.currentApplication.id), {})
      .then(({ data: res }: { data: ApplicationApplication }) => {
        this.$set(this.applications, this.currentApplicationIndex!, res);
        this.setChanges();
        this.closeShowApplication();
      })
      .catch(({ response: res }) => {
        this.applicationError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private plannedApplication() {
    this.$api
      .put(applicationRoutes.planned(this.currentApplication.id), {})
      .then(({ data: res }: { data: ApplicationApplication }) => {
        if (res.not_planned_count) {
          this.timeError = "Заполните информацию об обучении";
          return;
        }
        this.$set(this.applications, this.currentApplicationIndex!, res);

        this.setChanges();
        this.closeTimeManagement();
        this.closeShowApplication();
      })
      .catch(({ response: res }) => {
        this.timeError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private addNewInterval() {
    this.$api
      .post(applicationRoutes.intervals, {
        full_name: "Окно " + (this.intervals.length + 1),
        application_id: this.currentApplication.id
      })
      .then(({ data: res }: { data: ApplicationInterval }) => {
        this.intervals.push(res);
      })
      .catch(({ response: res }) => {
        this.timeError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async destroyInterval(id: string, index: string) {
    await this.$api
      .destroy(applicationRoutes.interval(id))
      .then(() => {
        this.$delete(this.intervals, index);
      })
      .catch(({ response: res }) => {
        this.timeError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private setChanges() {
    this.$api
      .put(ChangeRoutes.set_all(this.currentApplication.id), {})
      .catch(({ response: res }) => {
        this.applicationError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private closeShowApplication() {
    this.clearShowApplication();
    this.$modal.hide("showApplication");
  }

  private clearShowApplication() {
    this.currentShowTab = 0;
    this.oneCommentSend = false;
    this.commentShowError = "";
    this.applicationError = "";
    this.docsError = "";
    this.selectedReconciliations = [];
    this.selectedStudents = [];
    this.commentFileUrls = [];
    this.commentFiles = [];
    this.currentApplication = new ApplicationApplication();
  }

  private closeAddModal() {
    this.currentApplication = new ApplicationApplication();
    this.currentNewTab = 0;
    this.currentResponsible = null;
    this.$store.state.currentSkill = null;
    this.currentSkill = null;
    this.commentFileUrls = [];
    this.applicationError = "";
    this.commentFiles = [];
    this.currentApplicationType = EApplicationTypes.COLLECTIVE;
    this.sendComments = [];
    this.showNewApplicationModal = false;
    this.$modal.hide("newApplication");
  }

  protected allowWrite() {
    return this.$api.allowWrite();
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }
}
