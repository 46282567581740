
import { Component, Vue } from "vue-property-decorator";

import Search from "@/components/Search.vue";
import Preloader from "@/components/Preloader.vue";

import structureRoutes from "@/api/routes/structures";
import skillRoutes from "@/api/routes/skills";
import blockRoutes from "@/api/routes/blocks";
import userRoutes from "@/api/routes/users";

import BlockBlock from "@/models/block/block";
import SkillCategory, { ESkillCategory } from "@/models/skill/category";
import StructureFactory from "@/models/structure/factory";
import UserUser from "@/models/user/user";
import SkillLocal from "@/models/skill/local";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
}

interface IDataSkill extends IData {
  readonly skills: SkillLocal[];
}

interface IDataBlock extends IData {
  readonly blocks: BlockBlock[];
}

interface IUserData extends IData {
  readonly users: UserUser[];
}

@Component({
  components: {
    Search,
    Preloader
  }
})
export default class AppointResponsible extends Vue {
  private preload: boolean = false;

  private filters: Map<string, boolean> = new Map();
  private showFilter: boolean = false;

  private appointError: string = "";

  private blocksFilter: BlockBlock[] = [{ id: "", full_name: "Все" }];
  private currentBlockFilter?: BlockBlock = this.blocksFilter[0];

  private per_page: number = 100;
  private current_page: number = 0;
  private next_page: boolean = false;

  private factoriesFilter: StructureFactory[] = [
    { id: "", full_name: "Все", skills: [] }
  ];
  private currentFactoryFilter: StructureFactory = this.factoriesFilter[0];

  private skillCategoriesFilter: SkillCategory[] = [{ id: 0, value: "Все" }];
  private currentSkillCategoryFilter: SkillCategory = this
    .skillCategoriesFilter[0];

  private skills: SkillLocal[] = [];

  private search: string = this.$store.state.search;

  private users: UserUser[] = [];

  $refs!: {
    tabs: HTMLFormElement;
  };

  private watchers: Function[] = [];

  private created() {
    this.preload = true;

    this.watchers.push(
      this.$store.watch(
        state => state.search,
        search => {
          this.search = search;
          this.clearSkills();
        }
      )
    );

    Promise.all([
      this.loadBlocks(),
      this.loadFactories(),
      this.loadStructures(),
      this.loadUsers(),
      this.loadSkills()
    ]).then(() => {
      this.preload = false;
    });
  }

  private beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  private infiniteHandler() {
    if (this.next_page) {
      this.loadSkills(this.current_page + 1);
    }
  }

  private clearFilters() {
    this.currentSkillCategoryFilter = this.skillCategoriesFilter[0];
    this.currentFactoryFilter = this.factoriesFilter[0];
    this.filters.clear();
    this.clearSkills();
  }

  private changeCategory() {
    if (this.skillCategoriesFilter[0] !== this.currentSkillCategoryFilter) {
      this.filters.set("category", true);
    } else {
      this.filters.delete("category");
    }

    if (this.isMethodical()) {
      this.currentFactoryFilter = this.factoriesFilter[0];
    }

    this.clearSkills();
  }

  private changeFactory() {
    if (this.factoriesFilter[0] !== this.currentFactoryFilter) {
      this.filters.set("factory", true);
    } else {
      this.filters.delete("factory");
    }
    this.clearSkills();
  }

  private loadSkills(page: number = 0) {
    this.$api
      .get(skillRoutes.skills, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true,
          category_id: this.currentSkillCategoryFilter?.id,
          factory_id: this.currentFactoryFilter?.id,
          block_id: this.currentBlockFilter?.id,
          search: this.search,
          only_is_local: false,
          is_compulsory_education: true,
          responsible: true
        }
      })
      .then(({ data: res }: { data: IDataSkill }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;
        this.skills.push(...res.skills);
      })
      .catch(({ response: res }) => {
        this.appointError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private clearSkills() {
    this.skills = [];
    this.loadSkills();
  }

  private loadFactories() {
    this.$api
      .get(structureRoutes.factories)
      .then(({ data: res }: { data: StructureFactory[] }) => {
        this.factoriesFilter.push(...res);
      })
      .catch(({ response: res }) => {
        this.appointError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private loadStructures() {
    this.$api
      .get(skillRoutes.categories)
      .then(({ data: res }: { data: SkillCategory[] }) => {
        this.skillCategoriesFilter.push(...res);
      })
      .catch(({ response: res }) => {
        this.appointError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private loadUsers() {
    this.$api
      .get(userRoutes.users)
      .then(({ data: res }: { data: IUserData }) => {
        this.users = res.users;
      })
      .catch(({ response: res }) => {
        this.appointError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private selectUser(user: UserUser, index: number) {
    if (!user) {
      return;
    }

    const skill = this.skills[index];
    this.$api
      .put(skillRoutes.change_responsible(skill.id), {
        responsible_id: user.id
      })
      .then(({ data: res }: { data: SkillLocal }) => {
        this.$set(skill, index, res);
      })
      .catch(({ response: res }) => {
        this.appointError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private loadBlocks() {
    this.$api
      .get(blockRoutes.blocks, {
        params: {
          is_compulsory_education: true
        }
      })
      .then(({ data: res }: { data: IDataBlock }) => {
        this.blocksFilter.push(...res.blocks);
      })
      .catch(({ response: res }) => {
        this.appointError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private isMethodical() {
    return this.currentSkillCategoryFilter?.id === ESkillCategory.METHODICAL_ID;
  }

  private getPositionName(user: UserUser) {
    return user.workplaces?.[0].position_name;
  }

  private showSkill(block: BlockBlock) {
    this.currentBlockFilter = block;
    this.clearSkills();
  }

  private tabScrollRight() {
    this.$refs.tabs.scrollLeft += 100;
  }

  private tabScrollLeft() {
    this.$refs.tabs.scrollLeft -= 100;
  }
}
