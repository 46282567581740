
import { Component, Vue } from "vue-property-decorator";

import task_routes from "@/api/routes/tasks";

import Preloader from "@/components/Preloader.vue";
import Search from "@/components/Search.vue";
import CInfiniteScroll from "@/components/InfiniteScroll.vue";

import TaskTask from "@/models/task/task";

import CTaskCreateGroup from "./components/TaskCreateGroup.vue";
import CTaskUpdateIndividual, {
  IUpdatedIndividualTask
} from "./components/TaskUpdateIndividual.vue";
import CTaskDeleteIndividual, {
  ITaskDelete
} from "./components/TaskDeleteIndividual.vue";

interface ITaskData {
  readonly current_page: number;
  readonly next_page: boolean;
  readonly tasks: TaskTask[];
}

@Component({
  name: "VTasksAll",
  components: {
    Preloader,
    Search,
    CInfiniteScroll,
    CTaskCreateGroup,
    CTaskUpdateIndividual,
    CTaskDeleteIndividual
  }
})
export default class VTasksAll extends Vue {
  private search: string = this.$store.state.search;

  public preload: boolean = false;
  private preload_infinite: boolean = false;

  private per_page: number = 100;
  private current_page: number = 0;
  private next_page: boolean = false;

  public tasks: TaskTask[] = [];

  private watchers: Function[] = [];

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async created() {
    this.watchers.push(
      this.$store.watch(
        state => state.search,
        async search => {
          this.search = search;

          await this.clearUserTasks();
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.deletedIndividualTask,
        (deletedIndividualTask: ITaskDelete) => {
          if (deletedIndividualTask) {
            this.$delete(this.tasks, deletedIndividualTask.task_idx);
          }
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.createdGroupTask,
        (createdGroupTask: TaskTask[]) => {
          createdGroupTask.forEach(task => {
            this.tasks.unshift(task);
          });
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.updatedIndividualTask,
        (updatedIndividualTask: IUpdatedIndividualTask) => {
          this.$set(
            this.tasks,
            updatedIndividualTask.info.task_idx,
            updatedIndividualTask.task
          );
        }
      )
    );

    await this.clearUserTasks();
  }

  public async infiniteHandler() {
    if (this.next_page && !this.preload_infinite) {
      await this.getUserTasks(this.current_page + 1);
    }
  }

  private async clearUserTasks() {
    this.preload = true;

    this.tasks = [];

    await this.getUserTasks();

    this.preload = false;
  }

  private async getUserTasks(page: number = 0) {
    this.preload_infinite = true;

    return this.$api
      .get(task_routes.index_responsibles, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true,
          search: this.search
        }
      })
      .then(({ data: res }: { data: ITaskData }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;

        this.tasks.push(...res.tasks);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      })
      .finally(() => {
        this.preload_infinite = false;
      });
  }

  public createTask() {
    this.$store.commit("creatingGroupTask", {
      task_id: null
    });
  }

  public updateTask(index: number) {
    const task = this.tasks[index];

    this.$store.commit("updatingIndividualTask", {
      user_id: task.user_id,
      task_id: task.id,
      task_idx: index
    });
  }

  public deleteTask(index: number) {
    const task = this.tasks[index];

    this.$store.commit("deletingIndividualTask", {
      user_id: task.user_id,
      task_id: task.id,
      task_idx: index
    });
  }

  public allowWrite() {
    return this.$api.allowWrite();
  }
}
